import { ApiProperty } from "@nestjs/swagger"
import { IsNumber } from "class-validator"

// TODO: Better name
export class PublicTransportTrip {
    @ApiProperty()
    @IsNumber()
    entrance: number

    @ApiProperty()
    @IsNumber()
    exit: number
}
