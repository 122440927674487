import { ApiProperty } from '@nestjs/swagger'
import { IsBoolean, IsNumber } from 'class-validator'

export class SchoolOneTimePinRequestDTO {
    @ApiProperty({ example: 2 })
    @IsNumber()
    schoolId: number

    @ApiProperty()
    @IsBoolean()
    resend: boolean
}
