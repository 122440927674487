import {Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn} from 'typeorm'
import {ImportPresets} from "./ImportPresets.entity";

@Entity('import_presets_values')
export class ImportPresetsValues {
    @PrimaryGeneratedColumn({type: 'int', name: 'id', unsigned: true})
    id: number

    @ManyToOne(type => ImportPresets, imagePreset => imagePreset.values, { nullable: false })
    @JoinColumn({ name: 'preset_id' })
    preset: ImportPresets

    @Column({ name: 'apiColumn', type: 'mediumtext', nullable: true })
    apiColumn: string | null

    @Column({ name: 'apiColumnName', type: 'mediumtext', nullable: true })
    apiColumnName: string | null

    @Column({ name: 'headerColumn', type: 'mediumtext', nullable: true })
    headerColumn: string | null

    @Column({ name: 'headerColumnName', type: 'mediumtext', nullable: true })
    headerColumnName: string | null

    constructor(init: Partial<ImportPresetsValues>) {
        Object.assign(this, init)
    }
}