import { FormGroupStatus } from "@stadtlandnetz/forms"

/**
 Some attributes have a yes/no radio box and a preselect in front of them
 There a couple of states that we have to represent here
 - the user has not made any choice => null
 - the user has clicked on "no" => false
 - the user has clicked on "yes" but entered no data => empty array, show preselect
 - the user has clicked on "yes" and entered data => full array, hide preselect
 */
export type YesNoPreselect<T> = T[] | false | null

export function isYesNoPreselectChecked(input: YesNoPreselect<unknown>): boolean | null {
  switch (input) {
    case false:
      return false
    case null:
      return null
    default:
      return true
  }
}

export function yesNoPreselectToStatus(input: YesNoPreselect<unknown>): FormGroupStatus {
  switch (input) {
    case false:
      return FormGroupStatus.Success
    case null:
      return FormGroupStatus.Empty
    default:
      return FormGroupStatus.Success
  } 
}

export function hasPreselected(input: YesNoPreselect<unknown>): boolean {
  return Array.isArray(input) ? input.length > 0 : false
}

export function getSelected<T>(input: YesNoPreselect<T>): T[] {
  return Array.isArray(input) ? input : []
}