import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { TenderBid } from '.'

@Entity('angebote_meta')
export class TenderBidMeta {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => TenderBid, offer => offer.information, {
    nullable: false,
   onUpdate: 'CASCADE',
onDelete: 'CASCADE' 
  })
  @JoinColumn({ name: 'angebotid' })
  offer: TenderBid

  @Column('varchar', { name: 'feldname', length: 255})
  key: string

  @Column('text', { name: 'value1' })
  value1: string

  @Column('text', { name: 'value2' })
  value2: string

  constructor (init?: Partial<TenderBidMeta>) {
    Object.assign(this, init)
  }
}
