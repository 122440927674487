import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { School } from './School.entity'
import {IsNotEmpty} from "class-validator";

export type SchoolClassID = number

@Entity('schulen_klassen')
export class SchoolClass {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: SchoolClassID

  @IsNotEmpty()
  @Column('varchar', { name: 'class', length: 100})
  name: string

  @ManyToOne(type => School, school => school.classes, { 
    nullable: false, 
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'schulid'})
  school: School

  constructor (init?: Partial<SchoolClass>) {
    Object.assign(this, init)
  }
}
