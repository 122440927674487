import {

  Column,
  Entity, JoinColumn,
  OneToOne,
  PrimaryGeneratedColumn
} from 'typeorm'
import { School } from './School.entity'

// Improve: Refactor as a user?
@Entity('schulen_login')
export class SchoolLogin {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @OneToOne(() => School, school => school.login, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'schuleid'})
  school: School

  @Column('text', { name: 'passwort' })
  password: string

  @Column('tinyint', { name: 'locked', nullable: true, width: 1, default: false})
  locked: boolean

  constructor (init?: Partial<SchoolLogin>) {
    Object.assign(this, init)
  }
}
