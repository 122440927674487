import {

  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn
} from 'typeorm'
import { ValidTimespanGerman } from '../../utils'
import {TicketType} from "./TicketType.entity";

@Index('id', ['id'], { unique: true })
@Entity('ticket_prices')
export class TicketPrice {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @ManyToOne(type => TicketType, { nullable: false })
  @JoinColumn({ name: 'ticket_type' })
  ticketType: number

  @Column('decimal', {
    name: 'default_price',
    precision: 15,
    scale: 2,
    nullable: false,
    default: '0.00'
  })
  defaultPrice: string

  @Column('decimal', {
    name: 'bigzone_price',
    precision: 15,
    scale: 2,
    nullable: false,
    default: '0.00'
  })
  bigZonePrice: string

  @Column('tinyint', { name: 'limited_sale', width: 1, nullable: true })
  limitedSale: boolean
  
  @Column('mediumtext', { name: 'comments', nullable: true })
  comments: string | null

  @Column(type => ValidTimespanGerman)
  valid: ValidTimespanGerman

  constructor (init?: Partial<TicketPrice>) {
    Object.assign(this, init)
  }
}
