import { GenericRoute, GenericRouteRequest } from '@stadtlandnetz/core'
import { request } from './api-resource.base'
import { getHttpOptions } from '../stores/api.stores'

export class RoutingResource {
    constructor(private endpoint: string = 'transport/routing') { }

    // TODO: remove this endpoint ?
    async route(genericRouteRequest: GenericRouteRequest): Promise<GenericRoute[]> {
        return request(`${this.endpoint}`, {
            ...getHttpOptions('POST'),
            body: JSON.stringify(genericRouteRequest)
        })
    }
}