import { Type } from "class-transformer";
import { IsDateString, IsNumber } from "class-validator";
import { TourTrips } from "../entities";

export class TourTripOverviewDto {
    @Type(() => TourTrips)
    tourTrip: Partial<TourTrips>

    @IsNumber()
    studentsCount: number
}

export class TourTripOverviewParams {
    @IsDateString()
    day: string
}