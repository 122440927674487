import type { Address } from './address.entity'

export function formatAddress(address: Address): string {
    if (!address) return ''
    const street = address.street?.replace(/  +/g, ' ')?.replace(' - ', '-')
    const houseNumber = address.houseNumber?.replace(/  +/g, '')
    return `
        ${street?.trim() ?? ''} ${houseNumber?.trim() ?? ''}, \
        ${address.postalCode?.trim() ?? ''} ${address.town?.trim() ?? ''}
    `
}

export function addressesAreEqual(firstAddress: Address, secondAddress: Address): boolean {
    return formatAddress(firstAddress)?.toUpperCase() === formatAddress(secondAddress)?.toUpperCase()
}

export function getUniqueAddresses(addresses: Address[]): Address[] {
    const uniqueAddresses = []
    const seenAddresses = new Set()
    
    addresses.forEach(address => {
        const addressString = formatAddress(address).toUpperCase()
        if (!seenAddresses.has(addressString)) {
            seenAddresses.add(addressString)
            uniqueAddresses.push(address)
        }
    })
    
    return uniqueAddresses
}
