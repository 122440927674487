import { Student } from '@stadtlandnetz/core'
import { ApiResource, DefaultOptions, request } from './api-resource.base'
import { getHttpOptions } from '../stores/api.stores'

export class StudentsResource extends ApiResource<Student, DefaultOptions> {
    constructor() {
        super('students')
    }

    async getPassportPhoto(entityId: number, draft: boolean): Promise<Blob> {
        return request(`${this.endpoint}/passport/${draft ? 'draft' : 'student'}/${entityId}`, getHttpOptions('GET'))
    }

    async deletePassportPhoto(draftId: number): Promise<void> {
        return request(`${this.endpoint}/passport/delete/${draftId}`, getHttpOptions('POST'))
    }
}
