import {  Column, Entity, Index, JoinColumn, OneToOne, PrimaryGeneratedColumn } from 'typeorm'
import { Ticket } from '.'

@Index('id', ['id'], { unique: true })
@Entity('tbl_oepnv_anforderung_tickets_dates')
export class TicketDate {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @OneToOne(type => Ticket, ticket => ticket.dates, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'tbl_oepnv_anforderung_tickets_id' })
  ticket: Ticket

  @Column('timestamp', {
    name: 'created_date',
    nullable: true,
    default: () => 'CURRENT_TIMESTAMP'
  })
  createdDate: Date | null

  @Column('timestamp', { name: 'print_date', nullable: true })
  printDate: Date | null

  @Column('timestamp', { name: 'aushaendigungs_date', nullable: true })
  handedOverAt: Date | null

  @Column('timestamp', { name: 'order_date', nullable: true })
  orderedAt: Date | null

  @Column('timestamp', { name: 'vorl_ticket_date', nullable: true })
  temporaryTicketCreatedAt: Date | null

  @Column('timestamp', { name: 'return_date', nullable: true })
  returnedAt: Date | null

  constructor (init?: Partial<TicketDate>) {
    Object.assign(this, init)
  }
}
