import {  Column, Entity, PrimaryGeneratedColumn } from 'typeorm'

@Entity('custom_var_fields_bereiche')
export class CustomVarFieldsBereiche {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @Column('text', { name: 'name', nullable: true })
  name: string | null

  @Column('text', { name: 'caption', nullable: true })
  caption: string | null

  @Column('text', { name: 'description', nullable: true })
  description: string | null

  @Column('text', { name: 'var_field_name', nullable: true })
  varFieldName: string | null

  @Column('int', { name: 'dimension', nullable: true })
  dimension: number | null

  @Column('text', { name: 'indexe', nullable: true })
  indexe: string | null

  @Column('text', { name: 'ort', nullable: true })
  ort: string | null

  @Column('tinyint', { name: 'readonly', width: 1, default: false })
  readonly: boolean

  @Column({ type: 'json', name: 'permission_read', nullable: true })
  permissionRead: object

  @Column({ type: 'json', name: 'permission_edit', nullable: true })
  permissionEdit: object

  constructor (init?: Partial<CustomVarFieldsBereiche>) {
    Object.assign(this, init)
  }
}
