import {
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryGeneratedColumn
} from 'typeorm'
import {ApiProperty} from "@nestjs/swagger";
import {System} from "../../system/entities";
import {TransportRequest} from "./TransportRequest.entity";

@Entity('tbl_antraege_attribute')
export class TransportRequestAttribute {
    @ApiProperty({type: Number})
    @PrimaryGeneratedColumn({type: 'int', name: 'id', unsigned: true})
    id: number

    @ApiProperty()
    @JoinColumn({name: 'antragId'})
    @ManyToOne(type => TransportRequest, transportRequest => transportRequest.attributes, {
        onUpdate: "CASCADE",
        onDelete: "CASCADE"
    }) 
    transportRequest: TransportRequest

    @ApiProperty()
    @JoinColumn({name: 'systemId'})
    @ManyToOne(type => System, {eager: true})
    system: System

    constructor(init?: Partial<TransportRequestAttribute>) {
        Object.assign(this, init)
    }
}
