import { Column, Entity, Index, JoinColumn, ManyToOne, OneToMany, OneToOne, PrimaryGeneratedColumn } from 'typeorm'
import { AddressPrefixed, CoordinatesPrefixed, VariableFields } from '../../utils'
import { TransportCompany, TransportCompanyDepot, TransportCompanyVehicleDriver } from '.'
import { BaseUser } from '../../users'
import { TourTripsEndTracking } from '../../tours'

@Index('id', ['id'], { unique: true })
@Entity('unternehmen_personal', { schema: 'via' })
export class TransportCompanyMember {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @ManyToOne(type => TransportCompany, transportCompany => transportCompany.members, {
        nullable: false,
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE'
    })
    @JoinColumn({ name: 'unternehmenid' })
    company: TransportCompany

    @Column({ type: 'boolean', name: 'istFahrer', nullable: false, default: false })
    isDriver: boolean

    @Column({ type: 'boolean', name: 'istBegleitperson', nullable: false, default: false })
    isCompanion: boolean

    @Column('varchar', { length: 255, nullable: false })
    lastName: string

    @Column('varchar', { length: 255, nullable: false })
    firstName: string

    @Column('varchar', { length: 255, nullable: true })
    phone: string

    @Column('varchar', { length: 255, nullable: true })
    @Index({ unique: true })
    email: string

    @ManyToOne(type => TransportCompanyDepot, companyDepot => companyDepot.members, {
        nullable: true,
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE'
    })
    @JoinColumn({ name: 'betriebshofID' })
    companyDepot: TransportCompanyDepot

    @Column(type => AddressPrefixed)
    address: AddressPrefixed

    @Column(type => CoordinatesPrefixed)
    coordinates: CoordinatesPrefixed

    @Column('varchar', { name: 'bemerkungen', length: 1000, nullable: true })
    notes: string | null

    @Column(type => VariableFields)
    variableFields: VariableFields

    @OneToMany(type => TransportCompanyVehicleDriver, vehicleDriver => vehicleDriver.driver)
    vehicles: TransportCompanyVehicleDriver[]

    @OneToOne(type => BaseUser,
        baseUser => baseUser.companyMember, { cascade: true })
    loginCredentials: BaseUser

    @OneToMany(type => TourTripsEndTracking, endTracking => endTracking.driver)
    endTrackings: TourTripsEndTracking[]

    constructor(init?: Partial<TransportCompanyMember>) {
        Object.assign(this, init)
    }
}
