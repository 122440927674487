import {  Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { ValidTimespanGerman } from '../../utils'
import {Tour} from "./Tour.entity";
import {TransportCompanyMember} from "../../companies";

@Index('id', ['id'], { unique: true })
@Entity('touren_personen_kfz')
export class TourenPersonenKfz {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @ManyToOne(type => Tour, tour => tour.people, { nullable: true, onUpdate: 'CASCADE', onDelete: 'CASCADE' })
  @JoinColumn({ name: 'tour_id' })
  tour: Tour | null

  @ManyToOne(type => TransportCompanyMember, { nullable: true, onUpdate: 'CASCADE', onDelete: 'RESTRICT' })
  @JoinColumn({ name: 'unternehmen_personal_kfz_id' })
  unternehmen_personal_kfz_id: TransportCompanyMember | null

  // Refactor as enum (0=person, 1=companion, 2=vehicle)
  @Column('text', { name: 'type', nullable: true })
  type: string | null

  @Column({ name: 'name', type: 'varchar', length: 100, nullable: true })
  name: string | null

  @Column(type => ValidTimespanGerman)
  valid: ValidTimespanGerman

  @Column('varchar', { name: 'bemerkungen', nullable: true, length: 255 })
  bemerkungen: string | null

  constructor (init?: Partial<TourenPersonenKfz>) {
    Object.assign(this, init)
  }
}
