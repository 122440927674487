import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { School } from '.'
import { SchoolYear } from '../../calendar/entities'

/**
 * Movable free days which are different for each school
 *
 * Gymnasium has three, others have two
 */
// Improve: Replace `id` by composite key from all other attributes
@Entity('schulen_freitage')
export class MovableFreeDay {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => School, school => school.movableFreeDays, { 
    nullable: true,
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE' 
  })
  @JoinColumn({name:'schulid'})
  school: School

  @ManyToOne(type => SchoolYear, schoolYear => schoolYear.movableFreeDays, {
    nullable: true,
    onDelete: 'RESTRICT',
    onUpdate: 'CASCADE'
  })
  @JoinColumn({name: 'schuljahr', referencedColumnName: 'name'})
  schoolYear: SchoolYear

  @Column('date', { name: 'datum', nullable: false })
  datum: string

  constructor (init?: Partial<MovableFreeDay>) {
    Object.assign(this, init)
  }
}