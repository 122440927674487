import { ApiProperty } from "@nestjs/swagger";
import { File } from "../../files/entities";
import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn
} from 'typeorm';
import type { TransportRequestDraftData } from "..";
import { BaseUser } from "../../users/entities";


export type TransportRequestDraftID = number

@Index('id', ['id'], { unique: true })
@Entity('tbl_antraege_entwuerfe')
export class TransportRequestDraft {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ApiProperty({type: () => BaseUser})
  @ManyToOne(type => BaseUser, user => user.drafts)
  @JoinColumn()
  user: BaseUser | number

  @Column('json', {
    name: 'antrag',
  })
  @ApiProperty({ type: Object })
  request: Partial<TransportRequestDraftData>

  @ApiProperty()
  @OneToMany(type => File, file => file.draft, {cascade: true, eager: true})
  files: File[]

  constructor (init?: Partial<TransportRequestDraft>) {
    Object.assign(this, init)
  }
}
