import type { Fields, FormOptions, FormGroup } from './types'

interface FormConstructor {
    fields: Fields
    options: FormOptions
}

export class Form {
    fields: Fields
    groups: FormGroup[]
    subForms?: Form[]
    options: FormOptions

    constructor(init?: FormConstructor) {
        this.fields = init.fields
        this.options = init.options
        this.subForms = []
        this.groups = this.buildGroups()
    }

    /**
     * Groups the fields by their group name.
     *
     * All fields without an explicit group are assigned to a default group.
     */
    buildGroups(): FormGroup[] {
        const fieldsWithoutGroup = this.fields.filter(field => !field.group)
        const fieldsWithGroup = this.fields.filter(field => field.group)

        const defaultGroup = {
            name: this.options.defaultGroupName || '',
            fields: fieldsWithoutGroup,
            nested: ''
        }

        const otherGroups: FormGroup[] = Object.values(fieldsWithGroup.reduce((groups, field) => {
            if (!groups[field.group])
                groups[field.group] = {
                    name: field.group,
                    fields: [],
                    nested: field.nested
                }
            groups[field.group].fields.push(field)
            return groups
        }, {}))

        return [defaultGroup, ...otherGroups]
    }
}