import { ApiProperty } from "@nestjs/swagger"
import { IsBoolean, IsString } from "class-validator"

export class DeleteAccountPayloadDto {
    @ApiProperty({example: '*****'})
    @IsString()
    password: string
}

export class DeleteAccountResultDto {
    @IsBoolean()
    deletedOrDeactivated: boolean
}