import {  Column, Entity, JoinColumn, OneToOne, PrimaryGeneratedColumn } from 'typeorm'
import { TransportRequest } from '.'

@Entity('tbl_antraege_distances')
export class TransportRequestDistances {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @OneToOne(type => TransportRequest, request => request.distances, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'antragid' })
  request: TransportRequest

  @Column('double', {
    name: 'dist_addr_schule_bes_foot',
    nullable: false,
    default: 0
  })
  toVisitedSchoolByFoot: number | null

  @Column('double', {
    name: 'dist_addr_schule_ng_foot',
    nullable: false,
    default: 0
  })
  toNearestSchoolByFoot: number

  @Column('double', {
    name: 'dist_addr_schule_bes_car',
    nullable: false,
    default: 0
  })
  toVisitedSchoolByCar: number | null

  @Column('double', {
    name: 'dist_addr_schule_ng_car',
    nullable: false,
    default: 0
  })
  toNearestSchoolByCar: number | null

  constructor (init?: Partial<TransportRequestDistances>) {
    Object.assign(this, init)
  }
}
