import { literalFloatTransformer } from '../../utils'
import {Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn} from 'typeorm'
import {TourTrips, WaypointStudent} from '.'
import type {ShipmentType} from '../types'

@Entity('touren_fahrten_wegpunkte')
export class Shipment {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => TourTrips, tourtrips => tourtrips.waypoints, { 
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'fahrtid'})
  tourTrip: TourTrips

  // TODO: Refactor using `Coordinates` type if possible
  @Column('text', { name: 'lat_einstieg', nullable: false, transformer: literalFloatTransformer })
  latitudeEntry: number

  @Column('text', { name: 'lng_einstieg', nullable: false, transformer: literalFloatTransformer })
  longitudeEntry: number

  // @Column({ name: 'refid_einstieg', type: 'enum', enum: WaypointType })
  @Column({ name: 'refid_einstieg', type: 'int', unsigned: true, nullable: false })
  entryType: number

  @Column('text', { name: 'lat_ausstieg', nullable: false, transformer: literalFloatTransformer })
  latitudeExit: number

  @Column('text', { name: 'lng_ausstieg', nullable: false, transformer: literalFloatTransformer })
  longitudeExit: number

  // @Column({ name: 'refid_ausstieg', type: 'enum', enum: WaypointType })
  @Column({ name: 'refid_ausstieg', type: 'int', unsigned: true, nullable: false })
  exitType: number

  @Column('text', { name: 'name_einstieg', nullable: false })
  nameEntry: string

  @Column('text', { name: 'name_ausstieg', nullable: false })
  nameExit: string

  // @Column({ name: 'art', type: 'enum', enum: ShipmentType })
  @Column({ name: 'art', type: 'tinyint', nullable: false, width: 1 })
  type: ShipmentType

  @Column('text', { name: 'bemerkungen', nullable: false })
  notes: string

  @Column({ type: 'boolean', name: 'needs_wheelchair', nullable: false, default: false, width: 1 })
  needsWheelchair: boolean

  @Column('time', { name: 'zeit', nullable: false })
  departureTime: string

  @Column('time', { name: 'zeit_ausstieg', nullable: true })
  arrivalTime: string | null

  /** minutes that are needed to board the passenger, both on entry and exit */
  @Column('int', { name: 'ladezeit', nullable: false })
  boardingTime: number

  @Column('time', { name: 'zeit_wege', nullable: true })
  totalTime: string | null

  @Column('int', { name: 'order_einstieg', unsigned: true, nullable: true })
  entryOrder: number | null

  @Column('int', { name: 'order_ausstieg', unsigned: true, nullable: true })
  exitOrder: number | null

  @Column('int', { name: 'manual_waypoint_order', unsigned: true, nullable: false })
  manualWaypointOrder: number

  @Column('int', { name: 'manual_waypoint_order_exit', unsigned: true, nullable: false })
  manualWaypointOrderExit: number

  // should persist data how many kilometers the passenger is in the car and how many he adds to the route with his waypoint
  @Column('double', { name: 'einstieg_km_leg',  nullable: false })
  entryAtKilometer: number

  @Column('double', { name: 'einstieg_km_leg_difference',  nullable: false })
  entryCausesLoadKilometer: number

  @Column('double', { name: 'einstieg_km_leg_last',  nullable: false })
  entryAtLoadKilometer: number

  @Column('double', { name: 'ausstieg_km_leg', nullable: false })
  exitAtKilometer: number

  @Column('double', { name: 'ausstieg_km_leg_difference', nullable: false })
  exitCausesLoadKilo: number

  @Column('double', { name: 'ausstieg_km_leg_last',  nullable: false })
  exitAtLoadKilometer: number

  @OneToMany(
    type => WaypointStudent,
    waypointstudent => waypointstudent.waypoint
  )
  students: WaypointStudent[] 

  constructor (init?: Partial<Shipment>) {
    Object.assign(this, init)
  }

}