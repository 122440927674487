import { IsNotEmpty, IsOptional, IsString } from 'class-validator'
import type { Language } from '../../system'
import { Gender } from '../../utils'
import type { Guardian, Student } from '../../students'

export class BundIDProfile {
    bPK2: string
    email: string
    givenName: string
    surname: string
    postalAddress: string
    postalCode: string
    localityName: string
    gender: Gender
    birthdate: string // TODO: Type Date?
    assertionProvedBy: string // eIDAS / eID / Smart-eID / Elster / Benutzername / FINK / Diia
    'EID-CITIZEN-QAA-LEVEL': string
    language: Language

    constructor(init: Record<string, string>) {
        Object.assign(this, init)
        const normalize = (str: string) => str.toLowerCase().replace(/(^|\s)(\p{L})/gu, (c) => c.toUpperCase())
        this.givenName = normalize(this.givenName)
        this.surname = normalize(this.surname)
        this.postalAddress = normalize(this.postalAddress)
        this.localityName = normalize(this.localityName)
        this.gender = init.gender === '1' ? Gender.MALE : init.gender === '2' ? Gender.FEMALE : Gender.DIVERS
    }
}

export class BundIDSAMLResponseDTO {
    @IsString()
    @IsNotEmpty()
    SAMLResponse: string

    @IsOptional()
    @IsString()
    RelayState: string
}

export class BundIDValidationResponseDTO {
    profile?: Record<string, string>
    loggedOut?: boolean
    error?: string
}

export enum BundIDStatus {
    NOT_CONFIRMED = 'not_confirmed',
    CONFIRMED = 'confirmed',
}
