import { SectionName } from '@stadtlandnetz/forms'
import type { TransportRequest } from '../entities'
import { TransportRequestPageConfiguration, TransportRequestType } from '../types'
import type { Student } from '../../students'

export function getTransportRequestType(transportRequest: TransportRequest): TransportRequestType {
    if (transportRequest.hasPublicTransport) return TransportRequestType.PublicTransport
    else if (transportRequest.hasIndividualStudentTransport) return TransportRequestType.IndividualTransport
    else if (transportRequest.hasRefund) return TransportRequestType.Refund
}

export function getRequiredEmailTypes(config: TransportRequestPageConfiguration): TransportRequestType[] {
    return Object.entries(config)
        .filter(([_, config]) =>
            config.some((section) => section.name === SectionName.STUDENT && (section.options as any)?.emailRequired)
        )
        .map(([type]) => type as TransportRequestType)
}

export function allTypesRequireEmail(config: TransportRequestPageConfiguration): boolean {
    return getRequiredEmailTypes(config).length === Object.keys(config).length
}

export function isEmailRequired(
    student: Student,
    sentRequests: TransportRequest[],
    config: TransportRequestPageConfiguration
): boolean {
    const requiredEmailTypes = getRequiredEmailTypes(config)
    const allTypesRequired = requiredEmailTypes.length === Object.keys(config).length
    return (
        allTypesRequired ||
        sentRequests?.some(
            (request) =>
                request.studentId === student.id && requiredEmailTypes.includes(getTransportRequestType(request))
        )
    )
}
