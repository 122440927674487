import {
  Column,
  Entity,
  Index, JoinColumn, ManyToOne,
  PrimaryGeneratedColumn
} from 'typeorm'
import type { SchoolTypeRelation } from '../types/SchoolTypeRelation'
import { School } from './School.entity'
import { System } from '../../system'

/**
 * Type of school (e.g. Gymnasium, Oberschule, Grundschule, Förderschule, Berufsschule)
 * Many-to-Many between School and System
 */
@Index('id', ['id'], { unique: true })
@Entity('schulen_arten')
export class SchoolType {
  // Improve: Remove and use all three attributes below as composite key
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @ManyToOne(type => School, school => school.types, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({ name: 'schul_id' })
  school: School

  @ManyToOne(type => System, {
    // eager: true,
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({ name: 'system_id' })
  system: System | null

  @Column({ name: 'system_id', type: 'int', width: 10, unsigned: true, nullable: true})
  systemId: number | null

  @Column({
    name: 'type',
    type: 'varchar',
    length: 20,
    nullable: true
  })
  type: SchoolTypeRelation

  constructor (init?: Partial<SchoolType>) {
    Object.assign(this, init)
  }
}
