import { SchoolYear, Stop } from '@stadtlandnetz/core'
import { getHttpOptions } from '../stores/api.stores'
import { request } from './api-resource.base'

export class AccordingEntitiesResource {
    /* async getFittingSchoolTypes(type: SystemID, form: SystemID, profile: SystemID, wanted: SchoolTypeRelation) {
        const url = getEndpointUrl('/schooltypes/fitting', {type, form, profile, wanted})
        return request(url, getHttpOptions('GET'))
    } */

    async getFittingSchoolYears(): Promise<SchoolYear[]> {
        return request('/schoolYears/possibleForTransportRequest', getHttpOptions('GET'))
    }

    async getAvailableStops(): Promise<Stop[]> {
        return request('/transport/stops', getHttpOptions('GET'))
    }
}
