import {  Column, Entity, PrimaryGeneratedColumn } from 'typeorm'
import {ValidTimespanGerman} from "../../utils";

@Entity('fare_zone_connections_version')
export class FareZoneConnectionsVersion {
    @PrimaryGeneratedColumn({type: 'int', name: 'id', unsigned: true})
    id: number

    @Column({ type: 'varchar', name: 'bezeichnung', length: 255, nullable: false })
    description: string

    @Column(type => ValidTimespanGerman)
    valid: ValidTimespanGerman
}
