export type BankAccountDetails = {
    valid: boolean
    messages: string[]
    iban: string
    bankData: {
        bankCode: string
        name: string
        zip: string
        city: string
        bic: string
    }
    checkResults: Record<string, unknown>
} 

export class OpenIbanServiceResource {
    serviceUrl = 'https://openiban.via-cloud.de'

    async getBankAndBicOutOfIban(Iban: string): Promise<Response> {
        return await fetch(`${this.serviceUrl}/validate/${Iban}?getBIC=true`, {
            method: 'Get'
        })
    }
}
