import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { Document, DocumentTemplate } from '.'

@Entity('dok_content')
export class DocumentContent {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => DocumentTemplate, document => document.content, {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
    nullable: false
  })
  @JoinColumn({ name: 'dokid' })
  document: Document | null

  @Column('mediumtext', { name: 'content'})
  content: string 

  @Column('date',
    { name: 'revision', nullable: true })
  revision: Date 

  constructor (init?: Partial<DocumentContent>) {
    Object.assign(this, init)
  }
}
