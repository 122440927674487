import { Column, Entity, Index, PrimaryGeneratedColumn } from 'typeorm'

@Entity()
export class FailedLoginAttempts {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @Column('varchar', { nullable: false, length: 255 })
    @Index({ unique: true })
    email: string

    @Column({ type: 'datetime', nullable: true })
    one: Date

    @Column({ type: 'datetime', nullable: true })
    two: Date

    @Column({ type: 'datetime', nullable: true })
    three: Date

    @Column({ type: 'datetime', nullable: true })
    four: Date

    @Column({ type: 'datetime', nullable: true })
    blockedAt: Date

    constructor(init?: Partial<FailedLoginAttempts>) {
        Object.assign(this, init)
    }
}