import { IsArray, IsNotEmpty, IsOptional, IsString } from "class-validator";

export class MergePdfsJobDto {
    @IsString()
    @IsNotEmpty()
    filename: string

    @IsString()
    @IsNotEmpty()
    instance: string

    @IsArray()
    inputFiles: string[]

    @IsOptional()
    @IsString()
    @IsNotEmpty()
    jobId?: string
}