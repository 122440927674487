import {  Column, Entity, PrimaryGeneratedColumn } from 'typeorm'

@Entity('custom_var_fields_contens')
export class CustomVarFieldsContents {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @Column({ type: 'varchar', length: 255, name: 'var_fields_name', nullable: true })
  varFieldsName: string | null

  @Column({ type: 'varchar', length: 255, name: 'content_title', nullable: true })
  contentTitle: string | null

  @Column({ type: 'varchar', length: 255, name: 'content_id', nullable: true })
  contentId: string | null

  @Column('text', { name: 'var_fields_contents', nullable: true })
  varFieldsContents: string | null

  @Column('text', { name: 'filter', nullable: true })
  filter: string | null

  constructor (init?: Partial<CustomVarFieldsContents>) {
    Object.assign(this, init)
  }
}
