import { IsEmail, IsNotEmpty, IsString } from "class-validator";

export class TransportCompanyMemberLoginDto {
    @IsNotEmpty() 
    @IsEmail()
    username: string

    @IsString()
    @IsNotEmpty()
    password: string
 
    constructor(init?: Partial<TransportCompanyMemberLoginDto>) {
        Object.assign(this, init)
    }
}