import { Column, Entity, JoinTable, ManyToMany, ManyToOne, OneToMany, PrimaryGeneratedColumn } from "typeorm";
import type { Optional } from 'utility-types';
import { System } from "../../system";
import type { TransportRequestType } from "../../transport-request";
import { type meters, ValidTimespan } from "../../utils";
import { EvaluationSchemaAssignment } from "./EvaluationSchemaAssignment.entity";

@Entity()
export class EvaluationSchema {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
    id: number

    @Column({ type: 'varchar', length: 255, nullable: false })
    name: string

    @Column({ type: 'varchar', length: 255, nullable: true})
    description: string

    @Column({ type: 'boolean', default: true, nullable: false })
    checkClosestSchool: boolean

    @Column({ type: 'boolean', default: false, nullable: false })
    checkSchoolDistrict: boolean

    @Column({ type: 'boolean', default: true, nullable: false })
    checkMinimumDistance: boolean

    // Meter
    @Column({ type: 'integer', nullable: true })
    minimumDistance: meters | null

    @Column({ type: 'boolean', nullable: false })
    hasCoPayment: boolean

    @Column({ type: 'json', nullable: false })
    considerForRequestType: Record<TransportRequestType, boolean>

    // @Column({ type: 'json', nullable: true })
    // studentFilter: object | null

    @Column({ type: 'boolean', default: false, nullable: false })
    evaluatedManual: boolean = false

    @ManyToOne(type => System, { nullable: true })
    statusOverwrite: System | null

    @Column(type => ValidTimespan)
    validTimespan: ValidTimespan

    @OneToMany(type => EvaluationSchemaAssignment, evaluationSchemaAssignment => evaluationSchemaAssignment.schema)
    assignments: EvaluationSchemaAssignment[]

    @Column({ name: 'holder', type: 'json', nullable: true })
    allowedHolder: Object

    @ManyToMany(type => System, { nullable: false })
    @JoinTable({ name: 'evaluation_schema_allowed_school_types' })
    allowedSchoolTypes: System[]

    @ManyToMany(type => System, { nullable: false })
    @JoinTable({ name: 'evaluation_schema_allowed_classes' })
    allowedClasses: System[]

    // constructor(init: Optional<EvaluationSchema, 'evaluatedManual' | 'statusOverwrite' | 'studentFilter' | 'allowedClasses' | 'allowedSchoolTypes' | 'assignments'>) { Object.assign(this, init) }
    constructor(init: Optional<EvaluationSchema, 'id' | 'evaluatedManual' | 'statusOverwrite' | 'allowedClasses' | 'allowedSchoolTypes' | 'assignments' | 'description' | 'allowedHolder'>) { Object.assign(this, init) }
}