import { ApiProperty } from "@nestjs/swagger"
import type { ValidTimespanGerman } from "../../utils"

// TODO: Better name
export class StopConfiguration {
    @ApiProperty()
    valid: ValidTimespanGerman

    @ApiProperty()
    zone: string | null
}
