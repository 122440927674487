import {

  Column,
  Entity, JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn
} from 'typeorm'
import { User } from './User.entity'

@Entity('user_windows')
export class UserWindow {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => User, user => user.windows, { 
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'user_id' })
  user: User

  @Column('int', { name: 'window_id' })
  windowId: number

  // not useful to change it to enum
  @Column('varchar', { name: 'window_type', length: 255 })
  windowType: string

  constructor (init?: Partial<UserWindow>) {
    Object.assign(this, init)
  }
}
