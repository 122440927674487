import {
    EditLessonTimesDTO,
    StudentID,
    Timetable,
    TimetableRequestDTO,
    TransportRequest
} from '@stadtlandnetz/core'
import { getHttpOptions } from '../stores/api.stores'
import { request } from './api-resource.base'
import { plainToClass } from 'class-transformer'

export class TimetableResource {
    async getStudentsWithTimetable(): Promise<Record<StudentID, TransportRequest[]>> {
        const result: Record<StudentID, TransportRequest[]> =
            await request('/students/timetable/studentsWithTimetable', getHttpOptions('GET'))
        for (const studentId in result)
            result[studentId] = plainToClass(TransportRequest, result[studentId])
        return result
    }
    
    async getTimetable(data: TimetableRequestDTO): Promise<Timetable> {
        const timetable: Timetable = await request('/students/timetable', {
            ...getHttpOptions('POST'),
            body: JSON.stringify(data)
        })
    
        return plainToClass(Timetable, timetable)
    }
    
    async saveLessonTimes(data: EditLessonTimesDTO): Promise<void> {
        await request('/students/timetable/lessonTimes', {
            ...getHttpOptions('POST'),
            body: JSON.stringify(data)
        })
    }
}