import {  Column, Entity, JoinColumn, OneToOne, PrimaryGeneratedColumn } from 'typeorm'
import { TransportCompany } from '.'
import { literalBoolean } from '../../utils'

// Improve: Refactor as normal user
@Entity('unternehmen_login')
export class TransportCompanyLogin {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @OneToOne(type => TransportCompany, company => company.login, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'vuid' })
  company: TransportCompany

  @Column('varchar', { name: 'passwort', nullable: false, length: 255 })
  password: string

  // Improve: Make it a real boolean in the database
  @Column('varchar', { name: 'locked', nullable: false, length: 10, transformer: literalBoolean})
  locked: boolean

  constructor (init?: Partial<TransportCompanyLogin>) {
    Object.assign(this, init)
  }
}
