import {  Column, Entity, JoinColumn, ManyToOne, OneToOne, PrimaryGeneratedColumn } from 'typeorm'
import { PublicTransportRelation, TicketDate, TicketType } from '.'
import { PublicTransportRequirement } from "./PublicTransportRequirement.entity";
import type { PublicTransportType } from '../types/PublicTransportType'
import { Stop } from '../../transport'

@Entity('tbl_oepnv_anforderung_tickets')
export class Ticket {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @ManyToOne(type => PublicTransportRequirement, requirement => requirement.tickets, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({name: "anforderung_id"})
  requirement: PublicTransportRequirement

  @Column('varchar', { name: 'refreshToken', nullable: true, length: 255 })
  refreshToken: string | null

  // @Column({ name: 'type', type: 'enum', enum: TransportType })
  @Column({ name: 'type', type: 'varchar', length: 10, nullable: false })
  type: PublicTransportType

  @Column('varchar', { name: 'linie', length: 255, nullable: true })
  line: string | null

  @ManyToOne(type => TicketType, { 
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({name: 'ticket_id'})
  ticketType: TicketType

  @Column('json', { name: 'api_json', nullable: true })
  apiJson: object | null

  // Improve: Make primary key?
  @Column('varchar', { name: 'ticketnr', nullable: false, length: 255 })
  ticketNumber: string | null

  @ManyToOne(type => Stop, { 
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({name: 'einstieg'})
  entry: Stop

  @ManyToOne(type => Stop, { 
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({name: 'ausstieg'})
  exit: Stop

  @ManyToOne(type => PublicTransportRelation, { 
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({name: 'relationsid'})
  relation: PublicTransportRelation

  @Column('tinyint', { name: 'ersatz', nullable: false, width: 1, default: false })
  replacement: boolean | null

  @OneToOne(type => TicketDate, ticketdate => ticketdate.ticket)
  dates: TicketDate;

  // Attention: Three possible types of values:
  // - null
  // - "direkt"
  // - comma-separated values
  @Column('varchar', {name: 'via', nullable: true, length: 255})
  via: string

  constructor (init?: Partial<Ticket>) {
    Object.assign(this, init)
  }
}
