import { IsNotEmpty, IsOptional, IsString } from 'class-validator'

export class LocalFileSystemQueryParams {
    @IsString()
    @IsNotEmpty()
    filename: string

    @IsString()
    @IsNotEmpty()
    instance: string

    @IsString()
    @IsOptional()
    jobId: string
}
