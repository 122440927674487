import { IsMilitaryTime, ValidateIf } from 'class-validator'
import { IsAfter } from '../../utils'

export class LessonTime {
    @ValidateIf((context) => context.start)
    @IsMilitaryTime()
    start: string = null

    @ValidateIf((context) => context.end)
    @IsMilitaryTime()
    @IsAfter('start', { message: 'start must be before end' })
    end: string = null

    constructor(init?: Partial<LessonTime>) {
        Object.assign(this, init)
    }
}