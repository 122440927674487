import { IsBoolean, IsEnum } from "class-validator"
import type { SingleInfoBox } from "../system"
import { VariableFieldSize } from "."

export class MyViaOptions {
    @IsBoolean()
    showInPublicTransportRequest?: boolean

    @IsBoolean()
    showInPrivateTransportRequest?: boolean

    @IsBoolean()
    showInRefundRequest?: boolean

    @IsBoolean()
    showInSchoolAffiliationForPublicTransportRequest?: boolean

    @IsBoolean()
    showInSchoolAffiliationForPrivateTransportRequest?: boolean

    @IsBoolean()
    showInSchoolAffiliationForRefundRequest?: boolean

    label: SingleInfoBox
    
    @IsEnum(VariableFieldSize)
    size: VariableFieldSize
}