import { ReportPlaceholder } from './types/ReportPlaceholder'

export const placeholderTransformer = {
  from: (raw: string) => {
    const placeholderList: ReportPlaceholder[] = []
    if (raw === null) return null
    let splitted_serialized: string[] = raw.split('}{')
    for (let i = 0; i < splitted_serialized.length; i++) {
      if (splitted_serialized[i] !== '')
        placeholderList.push(new ReportPlaceholder(splitted_serialized[i]))
    }
    return placeholderList
  },
  to: (list: ReportPlaceholder[]) => {
    let serialized: string = ''
    for (let i = 0; i < list.length; i++) {
      let serialize_single: string =
        '{' + list[i].name + '/' + list[i].type + '/' + list[i].valuesRaw + '}'
      serialized += serialize_single
    }
    return serialized
  }
}

