import {  Column, Entity, PrimaryGeneratedColumn, ManyToOne, JoinColumn } from 'typeorm'
import { School } from '.'
import type { SchoolTimeType } from '../types/SchoolEnums'

// multiple entries per school possible, e.g. start: 07:30,09:00,11:00 end: 13:00,14:15,15:30
@Entity('schulen_zeiten')
export class SchoolTimes {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(() => School, {
    onDelete: "CASCADE",
    onUpdate: "CASCADE",
    nullable: false
  })
  @JoinColumn([{ name: "schulid", referencedColumnName: "id" }])
  school: School

  // Improve: Better Javascript representation
  @Column('time', { name: 'zeit' })
  time: string

  @Column('tinyint',{ name: 'beginn_ende' })
  type: SchoolTimeType

  constructor (init?: Partial<SchoolTimes>) {
    Object.assign(this, init)
  }
}
