import { User } from '../../users'
import { ValidTimespanOptional } from '../../utils'
import { Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import { NewsRights } from './newsRights.entity'

@Entity('news')
export class News {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @Column('varchar', { name: 'title', nullable: false, length: 100 })
    title: string

    @Column('varchar', { name: 'description', nullable: false, length: 255 })
    description: string

    @Column('text', { name: 'html', nullable: false })
    htmlContent: string

    @Column(type => ValidTimespanOptional)
    valid: ValidTimespanOptional

    @Column('boolean', { name: 'important', nullable: false, default: false })
    important: boolean

    @Column('boolean', { name: 'ug_vu', nullable: false, default: false })
    visibleForCompanies: boolean

    @Column('boolean', { name: 'ug_school', nullable: false, default: false })
    visibleForSchools: boolean

    @Column('boolean', { name: 'ug_driver', nullable: false, default: false })
    visibleForDrivers: boolean

    @Column('boolean', { name: 'ug_bg', nullable: false, default: false })
    visibleForCompanions: boolean

    @Column('boolean', { name: 'ug_via_user', nullable: false, default: false })
    visibleForClerks: boolean

    @Column('json', { name: 'categories', nullable: false  })
    categories: string[]

    @ManyToOne(
        type => User,
        user => user.news,
        { nullable: false })
    @JoinColumn({ name: 'author' })
    author: User

    @OneToMany(
        type => NewsRights,
        newsRights => newsRights.news,
        { nullable: false })
    newsRights: NewsRights[]

        constructor(init?: Partial<News>) {
            Object.assign(this, init)
        }
}
