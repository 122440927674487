import {Column, Entity, Index, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn} from 'typeorm'
import {AddressPrefixed, RequiredCoordinates, VariableFields} from '../../utils'
import {TransportCompany, TransportCompanyMember, TransportCompanyVehicle} from '.'

@Index('id', ['id'], {unique: true})
@Entity('unternehmen_betriebshof', {schema: 'via'})
export class TransportCompanyDepot {
    @PrimaryGeneratedColumn({type: 'int', name: 'id', unsigned: true})
    id: number

    @ManyToOne(type => TransportCompany, transportCompany => transportCompany.depots, {
        nullable: false,
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE'
    })
    @JoinColumn({name: 'vuid'})
    company: TransportCompany

    @Column('varchar', {name: 'name', length: 255, nullable: false})
    name: string

    @Column(type => AddressPrefixed)
    address: AddressPrefixed

    @Column(type => RequiredCoordinates)
    coordinates: RequiredCoordinates

    @Column(type => VariableFields)
    variableFields: VariableFields

    @OneToMany(type => TransportCompanyMember, member => member.companyDepot)
    members: TransportCompanyMember[]

    @OneToMany(type => TransportCompanyVehicle, vehicle => vehicle.companyDepot)
    vehicles: TransportCompanyVehicle[]

    constructor(init?: Partial<TransportCompanyDepot>) {
        Object.assign(this, init)
    }
}