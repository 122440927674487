import { Column, Entity, Index, PrimaryGeneratedColumn, ManyToOne, JoinColumn, OneToMany } from 'typeorm'
import {CoordinatesShort, ValidTimespanRequired, VariableFields} from '../../utils'

export type StopID = number
export type StopName = string
export type StopAlias = string

@Entity('haltestellen')
export class Stop {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @Index()
  @Column('varchar', { name: 'bezeichnung', nullable: false, length: 255 })
  name: StopName

  @Column(type => CoordinatesShort)
  coordinates: CoordinatesShort

  @Column('mediumtext', { name: 'nummer', nullable: true })
  number: string | null

  @Column('varchar', { name: 'zone', nullable: true, length: 255 })
  zone: string | null

  @Column('tinyint', { name: 'hidden', default: false })
  hidden: number

  @Column('varchar', { name: 'id_extern', length: "100", nullable: true })
  externalId: string

  // Hafas-Alias
  @Column('varchar', { name: 'alias', length: 255 })
  alias: StopAlias

  @Column('tinyint', { name: 'fsv', width: 1 })
  individualTransport: boolean

  @Column('tinyint', { name: 'oepnv', width: 1 })
  publicTransport: boolean

  @Column('tinyint', { name: 'notinlk', width: 1 })
  outsideCounty: boolean

  @Column(type => ValidTimespanRequired)
  valid: ValidTimespanRequired

  @ManyToOne(type => Stop, stop => stop.children, { nullable: true })
  @JoinColumn({ name: 'parent_id'})
  parent: Stop | null

  @OneToMany(type => Stop, stop => stop.parent, { nullable: true })
  children: Stop[] | null

  @Column(type => VariableFields)
  variableFields: VariableFields

  constructor (init?: Partial<Stop>) {
    Object.assign(this, init)
  }
}
