import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { System } from '../../system/entities'
import { TransportRequestState } from '../types'

@Entity('tbl_antraege_status')
export class TransportRequestStatus {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @Column({ type: 'enum', enum: TransportRequestState, nullable: false })
    status: TransportRequestState

    @ManyToOne((type) => System, {
        onUpdate: 'CASCADE',
        onDelete: 'RESTRICT'
    })
    @JoinColumn({ name: 'system_id' })
    system: System

    @Column({ name: 'system_id', type: 'int', width: 10, unsigned: true, nullable: false })
    systemId: number

    constructor(init?: Partial<TransportRequestStatus>) {
        Object.assign(this, init)
    }
}
