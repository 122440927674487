// TODO: Model as union from `VehicleType` and `FPTMode`
import { ApiExtraModels, ApiProperty } from "@nestjs/swagger"
import { IsEnum, ValidateNested } from "class-validator"
import { Type } from 'class-transformer'
import type { meters, minutes } from "../../utils"
import { Address, Coordinates } from "../../utils"
import { TransportType } from "./TransportType"

export type GenericPolyline = Coordinates[]

export enum GenericPinType {
    Address = 'address',
    Stop = 'stop',
    Location = 'location',
    School = 'school'
}

export interface GenericPinBase {
    type: string
}

export class GenericAddress implements GenericPinBase {
    @ApiProperty({ readOnly: true, required: true, enum: ['address']})
    readonly type = GenericPinType.Address

    @ApiProperty()
    address: Address
}

export class GenericStop implements GenericPinBase {
    @ApiProperty()
    readonly type = GenericPinType.Stop

    @ApiProperty()
    name: string
}

export class GenericLocation implements GenericPinBase {
    @ApiProperty()
    readonly type = GenericPinType.Location

    @ApiProperty() latitude: number
    @ApiProperty() longitude: number
    @ApiProperty() name?: string

    constructor(init?: Partial<GenericLocation>) { Object.assign(this, init) }
}

export class GenericSchool implements GenericPinBase {
    @ApiProperty()
    readonly type = GenericPinType.School

    @ApiProperty() schoolId: number
}

// These types of pins can be returned by the API
export type GenericPin = GenericAddress | GenericStop | GenericLocation

// These types of pins can be used to request routes
export type GenericInputPin = GenericPin | GenericSchool

// This type is returned by the endpoint
export type GenericRouteType = { [key in TransportType]?: GenericRoute }

export enum GenericRouteLegType {
    Walking = 'walking',
    Train = 'train',
    Bus = 'bus',
    Taxi = 'taxi',
    Watercraft = 'watercraft',
    Car = 'car'
}

export class GenericRouteLeg {
    @ApiProperty({ enum: GenericRouteLegType })
    type: GenericRouteLegType

    @ApiProperty({ type: Coordinates, isArray: true })
    path: Coordinates[]

    // TODO: Make mandatory
    destination?: GenericPin
    origin?: GenericPin

    @Type(() => Date)
    departure?: Date

    @Type(() => Date)
    arrival?: Date

    line?: string
    direction?: string
    originPlatform?: string
    distance?: meters
}

// Needed as an overview in the transport request form
export class GenericRouteOverview {
    @ApiProperty()
    travelTime: minutes

    @ApiProperty()
    drivingTime: minutes

    @ApiProperty()
    totalDistance: meters
}

export class GenericRoute {
    @ApiProperty()
    overview: GenericRouteOverview

    @ApiProperty({ type: GenericRouteLeg, isArray: true })
    @ValidateNested({ each: true })
    @Type(() => GenericRouteLeg)
    legs: GenericRouteLeg[]

    @ApiProperty()
    scheduledDays?: Record<string, boolean>

    @ApiProperty()
    restrictedAreas?: Coordinates[][]
}

@ApiExtraModels(GenericAddress, GenericLocation, GenericSchool, GenericStop)
export class GenericRouteRequest {
    // @ApiProperty({ oneOf: [
    //     { $ref: getSchemaPath(GenericAddress) },
    //     { $ref: getSchemaPath(GenericLocation) },
    //     { $ref: getSchemaPath(GenericSchool) },
    //     { $ref: getSchemaPath(GenericStop) },
    // ]})
    @ApiProperty()
    @ValidateNested()
    entrance: GenericInputPin

    // @ApiProperty({ oneOf: [
    //     { $ref: getSchemaPath(GenericAddress) },
    //     { $ref: getSchemaPath(GenericLocation) },
    //     { $ref: getSchemaPath(GenericSchool) },
    //     { $ref: getSchemaPath(GenericStop) },
    // ]})
    @ApiProperty()
    @ValidateNested()
    exit: GenericInputPin

    @ApiProperty()
    @IsEnum(TransportType)
    type: TransportType = TransportType.All

    @ApiProperty()
    arrival?: Date
    
    @ApiProperty()
    departure?: Date

    @ApiProperty()
    publicTransportOptions?: {
        scheduledDays?: boolean
    }

    @ApiProperty()
    restrictedAreas?: Coordinates[][]

    // individualTransportOptions?: Partial<GraphhopperRoutingParameter> | null
    individualTransportOptions?: Record<string, unknown>
}
