import type { SectionName } from '@stadtlandnetz/forms'
import type { TransportRequestType } from '../../transport-request'
import type { Language } from '../types'

export type SingleInfoBox = {
    [key in Language]?: string
}

export enum GeneralInfoBoxKeys {
    requestsOverviewInfo = 'requestsOverviewInfo',
    userAccountInfo = 'userAccountInfo',
    legalGuardiansInfo = 'legalGuardiansInfo',
    childrenInfo = 'childrenInfo',
    bankAccountInfo = 'bankAccountInfo',
    faqInfo = 'faqInfo',
    homePageInfo = 'homePageInfo',
    schoolAffiliationPageInfo = 'schoolAffiliationPageInfo'
}

export type GeneralInfoBoxes = {
    [key in GeneralInfoBoxKeys]?: SingleInfoBox
}

export enum PermanentRequestSections {
    EVALUATION = 'evaluation',
    DECLARATION_OF_CONSENT = 'declarationOfConsent'
}

export type SectionInfoBox = {
    [key in SectionName | PermanentRequestSections]?: SingleInfoBox
}

export type TransportRequestTypeInfoBoxes = {
    [key in TransportRequestType]?: SectionInfoBox
}

export interface InfoBoxesDto extends TransportRequestTypeInfoBoxes {
    general: GeneralInfoBoxes
}
