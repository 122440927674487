import {  Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import { TransportCompany } from '../../companies'
import { User } from '../../users/entities'
import { PreInfoMode } from '../types/preInfoMode'
import { Lot, TenderBidMeta, TenderRankingOrder } from '.'
import { System } from '../../system'

@Entity('angebote')
export class TenderBid {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @OneToMany(type => TenderBidMeta, offerMeta => offerMeta.offer)
  information: TenderBidMeta[]

  @ManyToOne(type => Lot, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({ name: 'losid' })
  lot: Lot

  @ManyToOne(type => TransportCompany, company => company.bids, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({ name: 'bieterid' })
  company: TransportCompany

  // Posteingangsdatum & Posteingangsnummer
  @Column('date', { name: 'pe_datum' })
  inboxDate: Date
  @Column('text', { name: 'pe_nummer' })
  inboxNumber: string

  @Column('text', { name: 'bemerkungen' })
  notes: string

  @Column('decimal', { name: 'eur_leer', precision: 10, scale: 2 })
  euroEmpty: number

  @Column('decimal', { name: 'eur_last', precision: 10, scale: 2 })
  euroLoad: number

  // companion price per hour
  @Column('decimal', { name: 'eur_bp', precision: 10, scale: 2 })
  euroCompanion: number

  // per kilometer
  @Column('decimal', { name: 'km_leer_hin', precision: 10, scale: 2 })
  kilometerEmptyTo: number
  @Column('decimal', { name: 'km_leer_rück', precision: 10, scale: 2 })
  kilometerEmptyReturn: number
  @Column('decimal', { name: 'km_last_hin', precision: 10, scale: 2 })
  kilometerLastTo: number
  @Column('decimal', { name: 'km_last_rück', precision: 10, scale: 2 })
  kilometerLastReturn: number
  // calculated in total
  @Column('decimal', { name: 'km_gesamt_hin', precision: 10, scale: 2 })
  kilometerTotalTo: string
  @Column('decimal', { name: 'km_gesamt_rück', precision: 10, scale: 2 })
  kilometerTotalReturn: string

  // companion hours
  @Column('decimal', { name: 'h_bp_hin', precision: 10, scale: 2 })
  companionHoursTotalTo: number
  @Column('decimal', { name: 'h_bp_rück', precision: 10, scale: 2 })
  companionHoursTotalReturn: number

  @Column('int', { name: 'anz_fahrten_hin' })
  numberOfTripsTo: string
  @Column('int', { name: 'anz_fahrten_rück' })
  numberOfTripsReturn: string

  @Column('decimal', { name: 'netto_fahrt_hin', precision: 10, scale: 2 })
  netPriceTripTo: string
  @Column('decimal', { name: 'netto_fahrt_rück', precision: 10, scale: 2 })
  netPriceTripReturn: string

  @Column('decimal', { name: 'netto_bp_hin', precision: 10, scale: 2 })
  netPriceCompanionTo: string
  @Column('decimal', { name: 'netto_bp_rück', precision: 10, scale: 2 })
  netPriceCompanionReturn: string

  @Column('decimal', { name: 'netto_gesamt_hin', precision: 10, scale: 2 })
  netTotalTo: string
  @Column('decimal', { name: 'netto_gesamt_rück', precision: 10, scale: 2 })
  netTotalReturn: string

  @Column('decimal', { name: 'eur_last_km_hin', precision: 10, scale: 2 })
  priceLastKilometerTo: string

  @Column('decimal', { name: 'eur_last_km_rück', precision: 10, scale: 2 })
  priceLastKilometerReturn: string

  @Column('decimal', { name: 'ust_satz_hin', precision: 10, scale: 2 })
  vatPercentageTo: string
  @Column('decimal', { name: 'ust_satz_rück', precision: 10, scale: 2 })
  vatPercentageBack: string

  @Column('decimal', { name: 'ust_gesamt_hin', precision: 10, scale: 2 })
  vatTotalTo: string

  @Column('decimal', { name: 'ust_gesamt_rück', precision: 10, scale: 2 })
  vatTotalReturn: string

  @Column('decimal', { name: 'brutto_gesamt_hin', precision: 10, scale: 2 })
  grossTotalTo: string

  @Column('decimal', { name: 'brutto_gesamt_rück', precision: 10, scale: 2 })
  grossTotalBack: string

  @Column('decimal', { name: 'brutto_gesamt_tag', precision: 10, scale: 2 })
  grossTotalPerDay: string

  // logs date, when a user opens the tender bid, has legal background
  @Column('date', { name: 'datum_öffnung' })
  openingDay: Date
  @ManyToOne(
    () => User,
    { onDelete: "SET NULL", onUpdate: "CASCADE",  nullable: true }
    )
  @JoinColumn([{ name: "nutzerid_öffnung", referencedColumnName: "id" }])
  openingUser: User

  // is this tender bid approved
  @Column('tinyint', { name: 'zulassung', width: 1 })
  approving: boolean
  @ManyToOne(
    () => System,
    { onDelete: "RESTRICT", onUpdate: "CASCADE",  nullable: false }
    )
  @JoinColumn([{ name: "zulassung_begruendung", referencedColumnName: "id" }])
  approvingReason: System
  @Column('text', { name: 'zulassung_bemerkungen' })
  approvingNotes: string

  @Column('tinyint', { name: 'zuschlag', width: 1 })
  winning: boolean

  @ManyToOne(
    () => System,
    { onDelete: "RESTRICT", onUpdate: "CASCADE",  nullable: true }
    )
  @JoinColumn([{ name: "zuschlag_begruendung", referencedColumnName: "id" }])
  winningReason: System

  @Column('text', { name: 'zuschlag_bemerkungen' })
  winningNotes: string

  /* @ManyToOne(
    () => Vehicle,
    { onDelete: "SET NULL", onUpdate: "CASCADE",  nullable: true }
    )
  @JoinColumn([{ name: "zuschlag_fahrzeugid", referencedColumnName: "id" }])
  winningVehicle: Vehicle */

  @Column('tinyint', { name: 'abforderung_notwendig', width: 1 })
  inquiriesNeeded: boolean
  @Column('date', { name: 'abforderung_erfolgt_datum' })
  inquiriesSent: Date
  @Column('date', { name: 'abforderung_frist_datum' })
  inquiriesDue: Date
  @Column('date', { name: 'abforderung_abgabe_eingang_datum' })
  inquiriesAnsweredAt: Date
  
  // not in source code
  /*
  @Column('text', { name: 'abforderung_penr' })
  abforderungPenr: string
  */

  @Column('tinyint', { name: 'abforderung_vollstaendig', width: 1 })
  inquiriesCompleted: boolean
  @Column('text', { name: 'abforderung_bemerkungen' })
  inquiriesNotes: string

  @Column('tinyint', { name: 'vorinfo_notwendig', width: 1 })
  preInfoNecessary: boolean

  @Column('tinyint', { name: 'vorinfo_zuschlag_absage', width: 1, nullable: false, default: PreInfoMode.DECLINED  })
  preInfoMode: PreInfoMode

  @ManyToOne(
    () => System,
    { onDelete: "RESTRICT", onUpdate: "CASCADE",  nullable: true }
    )
  @JoinColumn([{ name: "vorinfo_grund", referencedColumnName: "id" }])
  preInfoReason: System


  @Column('date', { name: 'vorinfo_versand_datum' })
  preInfoSentAt: Date

  @Column('date', { name: 'vorinfo_frist_datum' })
  preInfoDueAt: Date

  @Column('tinyint', { name: 'zuschlag_erteilt', width: 1 })
  hasWon: boolean

  @Column('date', { name: 'zuschlag_versand_datum' })
  notifiedAboutWinAt: Date

  // not in source code
  /*
  @Column('text', { name: 'uniqueid', nullable: true })
  uniqueid: string | null
  */
 
  @OneToMany(type => TenderRankingOrder, rankingorder => rankingorder.tenderbid)
  rankingOrders: TenderRankingOrder[]

  constructor (init?: Partial<TenderBid>) {
    Object.assign(this, init)
  }
}
