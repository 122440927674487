import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { School } from './School.entity'
import { CoordinatesShort } from '../../utils'

@Entity('school_entrances')
export class SchoolEntrance {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @Column('varchar', { nullable: false, length: 255 }) 
  name: string

  @Column('double', { name: 'dist_door', nullable: true })
  distanceToDoor: number

  @Column({ type: 'boolean', name: 'by_car', nullable: false, width: 1, default: 0 })
  reachableByCar: boolean

  @Column(type => CoordinatesShort)
  coordinates: CoordinatesShort

  @ManyToOne(type => School, school => school.entrances, { 
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({name: 'schule_id'})
  school: School

  constructor (init?: Partial<SchoolEntrance>) {
    Object.assign(this, init)
  }
}
