import {
    SchoolAffiliationLoginDTO,
    SchoolAffiliationStatusDTO,
    SchoolOneTimePinRequestDTO,
    TransportRequest
} from '@stadtlandnetz/core'
import { apiBaseUrl, getHttpOptions } from '../stores/api.stores'
import { request } from './api-resource.base'
import { ExpressMulterFile } from './upload.resource'

export class SchoolAffiliationResource {
    url = 'verification/schoolAffiliation'

    async getTransportRequests(access_token: string): Promise<TransportRequest[]> {
        return await request(new URL(this.url, apiBaseUrl).toString(), {
            method: 'GET',
            headers: { Authorization: `Bearer ${access_token}` },
            credentials: 'include'
        })
    }

    async login(dto: SchoolAffiliationLoginDTO): Promise<Record<string, unknown>> {
        return request(`${this.url}/login`, {
            ...getHttpOptions('POST'),
            body: JSON.stringify(dto)
        })
    }

    async refresh(access_token: string): Promise<Record<string, unknown>> {
        return request(new URL(`${this.url}/refresh`, apiBaseUrl).toString(), {
            method: 'GET',
            headers: { Authorization: `Bearer ${access_token}` },
            credentials: 'include'
        })
    }

    async sendAffiliation(dtos: SchoolAffiliationStatusDTO[], accessToken: string): Promise<boolean> {
        return request(
            this.url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                },
                credentials: 'include',
                body: JSON.stringify(dtos)
            },
            true
        )
    }

    async sendSchoolOneTimePin(dto: SchoolOneTimePinRequestDTO): Promise<Response> {
        return await fetch(new URL('users/sendSchoolOneTimePin', apiBaseUrl).toString(), {
            ...getHttpOptions('POST'),
            body: JSON.stringify(dto)
        })
    }

    async uploadFilesForRequest(
        files: ExpressMulterFile[],
        transportRequestId: number,
        studentId: number,
        accessToken: string
    ): Promise<Response> {
        const fd = new FormData()
        files.forEach((file) => {
            fd.append('files', file.data, file.fileName)
        })
        return await fetch(new URL(`${this.url}/upload/${studentId}/${transportRequestId}`, apiBaseUrl).toString(), {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            credentials: 'include',
            body: fd
        })
    }

    async deleteUploadedFileForRequest(filePath: string, accessToken: string): Promise<Response> {
        return fetch(new URL(`${this.url}/deleteFile`, apiBaseUrl).toString(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            credentials: 'include',
            body: JSON.stringify({ filePath })
        })
    }
}
