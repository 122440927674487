import {  Column, Entity, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import { TicketType } from '.'

@Entity('ticket_names')
export class TicketName {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @Column('varchar', { name: 'name', nullable: true, length: 255 })
  name: string | null

  @OneToMany(type => TicketType, ticketype => ticketype.name)
  types: TicketType

  constructor (init?: Partial<TicketName>) {
    Object.assign(this, init)
  }
}
