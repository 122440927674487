export enum VariableFieldType {
   TEXT = 0,
   TEXTAREA = 1,
   DATE = 2,
   SELECT = 3,
   HEADLINE = 4,
   MULTISELECT = 5,
   CHECKBOX = 6,
   BLACK_TEXT_INFO = 12,
   RED_TEXT_INFO = 13,
   GREEN_TEXT_INFO = 14,
   YELLOW_TEXT_INFO = 15,
   RADIO_BUTTON = 24
}

export const NON_INPUT_FIELD_TYPES = [
    VariableFieldType.HEADLINE,
    VariableFieldType.BLACK_TEXT_INFO,
    VariableFieldType.RED_TEXT_INFO,
    VariableFieldType.GREEN_TEXT_INFO,
    VariableFieldType.YELLOW_TEXT_INFO,
]