export function getFirstDaysOfMonths(startDate: string, endDate: string): string[] {
    const start = startDate.split('-')
    const end = endDate.split('-')
    const startYear = parseInt(start[0])
    const endYear = parseInt(end[0])
    const dates: string[] = []
    for (let i = startYear; i <= endYear; i++) {
        const endMonth = i != endYear ? 11 : parseInt(end[1]) - 1
        const startMonth = i === startYear ? parseInt(start[1]) - 1 : 0
        for (let j = startMonth; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
            const month = j + 1
            const displayMonth = month < 10 ? '0' + month.toString() : month.toString()
            dates.push([i, displayMonth, '01'].join('-'))
        }
    }
    dates.splice(0, 1)
    return dates
}