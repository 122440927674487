import { ApiProperty } from '@nestjs/swagger'
import { IsNumber, IsOptional, ValidateNested } from 'class-validator'
import { Type } from 'class-transformer'
import { Column, Entity, Index, JoinColumn, OneToOne, PrimaryGeneratedColumn } from 'typeorm'
import { LessonTime } from '../types/LessonTime'
import { TransportRequest } from './TransportRequest.entity'

@Entity('lesson_times')
export class LessonTimes {
    @ApiProperty({ type: Number })
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    @IsOptional()
    @IsNumber()
    id: number

    @OneToOne(type => TransportRequest, request => request.lessonTimes, {
        nullable: false,
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE'
    })
    @JoinColumn({ name: 'request' })
    request: Partial<TransportRequest>

    @ApiProperty({ type: () => LessonTime })
    @Column('json')
    @Type(() => LessonTime)
    @ValidateNested()
    monday: LessonTime = new LessonTime()
    
    @ApiProperty({ type: () => LessonTime })
    @Column('json')
    @Type(() => LessonTime)
    @ValidateNested()
    tuesday: LessonTime = new LessonTime()

    @ApiProperty({ type: () => LessonTime })
    @Column('json')
    @Type(() => LessonTime)
    @ValidateNested()
    wednesday: LessonTime = new LessonTime()

    @ApiProperty({ type: () => LessonTime })
    @Column('json')
    @Type(() => LessonTime)
    @ValidateNested()
    thursday: LessonTime = new LessonTime()

    @ApiProperty({ type: () => LessonTime })
    @Column('json')
    @Type(() => LessonTime)
    @ValidateNested()
    friday: LessonTime = new LessonTime()

    static getEmptyLessonTimes(request: TransportRequest) {
        return new LessonTimes({
            request,
            monday: new LessonTime({ start: null, end: null }),
            tuesday: new LessonTime({ start: null, end: null }),
            wednesday: new LessonTime({ start: null, end: null }),
            thursday: new LessonTime({ start: null, end: null }),
            friday: new LessonTime({ start: null, end: null })
        })
    }

    constructor(init?: Partial<LessonTimes>) {
        Object.assign(this, init)
    }
}
