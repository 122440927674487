import { ApiResource, DefaultOptions } from './api-resource.base'
import { SchoolClass, SchoolID } from '@stadtlandnetz/core'

export class SchoolClassesResource extends ApiResource<SchoolClass, DefaultOptions> {
    constructor() {
        super('schoolclasses');
    }

    async getBySchool(schoolId: SchoolID): Promise<SchoolClass[]> {
        return this.getMany(undefined, [['filter', [`SchoolClass.schulid||$eq||${schoolId}`]]])
    }
}