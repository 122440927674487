import {  Column, Entity, Index, PrimaryGeneratedColumn, ManyToOne, JoinColumn } from 'typeorm'
import {FareZoneConnectionsVersion} from "./FareZoneConnectionsVersion.entity";

@Index('id', ['id'], { unique: true })
@Index('zone', ['zone'], { unique: false})
@Entity('fare_zones')
export class FareZones {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @ManyToOne(type => FareZoneConnectionsVersion, { nullable: false })
  @JoinColumn({ name: 'version_id' })
  versionId: number

  @Column('varchar', { name: 'name', nullable: false, length: 255 })
  name: string 

  @Column('varchar', { name: 'zone', nullable: false, length: 255 })
  zone: string

  @Column('tinyint', { name: 'grosswabe', default: false })
  bigZone: number

  constructor (init?: Partial<FareZones>) {
    Object.assign(this, init)
  }
}
