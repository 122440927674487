import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { User } from '.'

// Document
@Entity('user_todo')
export class UserTodo {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => User, user => user.todos, {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
    nullable: false
  })
  @JoinColumn({ name: 'userid' })
  user: User

  @Column('text', { name: 'content', nullable: false })
  content: string

  @Column('tinyint', { name: 'done', nullable: false, width: 1, default: false})
  done: boolean

  @Column('int', { name: 'prio', nullable: true })
  priority: number | null

  constructor (init?: Partial<UserTodo>) {
    Object.assign(this, init)
  }
}
