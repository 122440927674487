import {  Column, Entity, PrimaryGeneratedColumn } from 'typeorm'

@Entity('dok_stapel')
export class DocumentStack {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @Column('date', { name: 'datum_erstellt' })
  dateCreated: Date

  @Column('date', { name: 'datum_druck' })
  datePrinted: Date

  @Column('date', { name: 'datum_versand' })
  dateSend: Date

  // EA => Eigenanteil (Co-payment)
  @Column('date', { name: 'datum_ea_fällig' })
  dateCoPaymentIsDue: Date

  @Column('date', { name: 'datum_fertig' })
  dateDone: Date

  @Column('tinyint', { name: 'stapel_fertig', width: 1 })
  stackDone: boolean

  constructor (init?: Partial<DocumentStack>) {
    Object.assign(this, init)
  }
}
