import {  Column, Entity, JoinColumn, ManyToOne, OneToOne, PrimaryGeneratedColumn } from 'typeorm'
import { User, UserRole } from '.'

@Entity('user_meta')
export class UserMeta {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @OneToOne(type => User, user => user.meta, {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
    nullable: false
  })
  @JoinColumn({ name: 'userid' })
  user: User

  @Column('varchar', { name: 'vorname', nullable: true, length: 255 })
  firstName: string | null

  @Column('varchar', { name: 'nachname', nullable: true, length: 255})
  lastName: string | null

  // Improve: Add validation
  @Column('varchar', { name: 'email', nullable: true, length: 255})
  email: string | null
  @Column('varchar', { name: 'telefon', nullable: true, length: 255})
  phone: string | null
  @Column('varchar', { name: 'fax', nullable: true, length: 255 })
  fax: string | null

  @Column('varchar', { name: 'zimmer', nullable: true, length: 255 })
  room: string | null
  @Column('varchar', { name: 'position', nullable: true, length: 255})
  position: string | null

  @ManyToOne(type => UserRole, role => role.users, {
    nullable: true,
 onUpdate: 'CASCADE',
   onDelete: 'SET NULL' 
  })
  @JoinColumn({name: 'rolleid'})
  role: UserRole

  constructor (init?: Partial<UserMeta>) {
    Object.assign(this, init)
  }
}
