import { Column, Entity, Index, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import {ApiGet} from "./ApiGet.entity";
import {ImportPresetsValues} from "./ImportPresetsValues.entity";

@Index('id', ['id'], { unique: true })
@Entity('import_presets')
export class ImportPresets {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @ManyToOne(type => ApiGet, { nullable: false })
  @JoinColumn({ name: 'api_id' })
  apiGet: ApiGet

  @Column('timestamp', {
    name: 'timestamp',
    default: () => 'CURRENT_TIMESTAMP',
    nullable: false
  })
  timestamp: Date

  @Column('text', { name: 'preset', nullable: true })
  preset: string | null

  @Column('text', {name: 'bezeichnung', nullable: true})
  description: string | null

  @Column('text', {name: 'sql_before', nullable: true})
  sqlBefore: string | null

  @Column('text', {name: 'sql_after', nullable: true})
  sqlAfter: string | null

  @OneToMany(type => ImportPresetsValues, importPresetValues => importPresetValues.preset)
  values: ImportPresetsValues[]

  constructor (init?: Partial<ImportPresets>) {
    Object.assign(this, init)
  }
}