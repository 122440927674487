import {
    GenericRouteLeg,
    LessonTime,
    LessonTimetableEntry,
    meters,
    Student,
    Timetable,
    TimetableEntry,
    TimetableRecord,
    TransportRequest
} from "@stadtlandnetz/core"
import { addDays, eachDayOfInterval, format, startOfWeek } from "date-fns"

const dayInMs: number = 86400000

export const getStudentName = (student: Student) => `${student?.firstName} ${student?.lastName}`

export const getActiveRequests = (requests: TransportRequest[], range: Date[]) => requests.filter(request =>
    request.valid.contains(range[0]) || request.valid.contains(range.slice(-1)[0])
)

export function insertLessonTimes(timetable: Timetable, range: Date[], requests?: TransportRequest[]): Timetable {
    for (const date of range.sort((a, b) => +a - +b).slice(0, -2)) {
        const day = format(date, 'EEEE').toLowerCase()
        let lessonTimes: LessonTimetableEntry[]
        // if (!requests?.some(request => request.lessonTimes)) {
            lessonTimes = timetable[day]
                .sort((a, b) => +a.begin - +b.begin)
                .slice(0, -1)
                .map((entry, i) => ({
                    type: null,
                    begin: entry.end,
                    end: timetable[day][i + 1].begin
                }))
        // } else {
        //     const request = requests.find(request => request.valid.contains(date))
        //     if (!request) continue
        //     const lessons: LessonTime = request.lessonTimes[day]
        //     if (!lessons.start || !lessons.end) continue
        //     const [beginHours, beginMinutes] = lessons.start.split(':').map(Number)
        //     const [endHours, endMinutes] = lessons.end.split(':').map(Number)
        //     lessonTimes = [{
        //         type: null,
        //         begin: new Date(date.setHours(beginHours, beginMinutes)),
        //         end: new Date(date.setHours(endHours, endMinutes))
        //     }]
        // }
        timetable[day].push(...lessonTimes)
    }
    return timetable
}

export function getStartEndTimes(timetable: Timetable, min: number, max: number): [number, number] {
    const allEntries: TimetableRecord[] = Object.values(timetable).flat()
    const start = Math.min(min, ...allEntries.map(entry => entry.begin.getHours()))
    const end = Math.max(max, ...allEntries.map(entry => entry.end.getHours()))
    return [start, end]
}

export function filterInvalidTimetables(
    timetables: Record<string, Timetable>,
    studentId: number,
    timesUpdated: TransportRequest[]
): Record<string, Timetable> {
    return Object.entries(timetables)
        .reduce((obj, [key, timetable]) => {
            const [id, week] = key.split(':')
            const weekStart = new Date(week)
            const weekEnd = new Date(+new Date(week) + dayInMs * 4)
            return +id === studentId && timesUpdated.some(request =>
                request.valid.contains(weekStart) || request.valid.contains(weekEnd)
            ) ? obj : { ...obj, [key]: timetable}
        }, {})
}

export function calculateDateRange(date: Date): Date[] {
    const start = startOfWeek(date, { weekStartsOn: 1 })
    return eachDayOfInterval({ start, end: addDays(start, 6) })
}

export function getSortedJourneys(entries: TimetableEntry[]): TimetableRecord[] {
    return entries
        .filter(entry => entry.type !== null)
        .sort((a, b) => +a.begin - +b.begin) as TimetableRecord[]
}

export function getLegDuration(leg: GenericRouteLeg | TimetableRecord): string {
    const total = leg instanceof GenericRouteLeg
        ? (+leg.arrival - +leg.departure) / 1000 / 60
        : (+leg.end - +leg.begin) / 1000 / 60
    const hours = total / 60 | 0
    const minutes = total % 60 | 0
    return (hours ? `${hours} h` : '') + (minutes ? ` ${minutes} min` : '')
}

export function formatDistance(distance: meters): string {
    return distance < 1000 ? `${distance} m` : `${(distance / 1000).toFixed(2)} km`
}