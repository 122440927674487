import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { System } from '../../system'

@Entity('schulklassen_mindestentfernung')
export class ClassMinimumDistance {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(
    () => System,
    { onDelete: 'RESTRICT', onUpdate: "CASCADE",  nullable: false }
    )
  @JoinColumn([{ name: "schulform_id", referencedColumnName: "id" }])
  formOfSchool: System

  // no reference
  @Column('varchar', { name: 'klasse', length: 255 })
  class: string

  @Column('double', { name: 'mindestentfernung' })
  minimumDistance: number

  constructor (init?: Partial<ClassMinimumDistance>) {
    Object.assign(this, init)
  }
}
