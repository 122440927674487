import { IsBoolean, IsEnum, IsNumber, IsOptional } from 'class-validator'
import type { OrderDateOptions, TransportRequestType } from '../types'
import { SectionName } from '@stadtlandnetz/forms'

export class TransportRequestSectionDto {
    @IsEnum(SectionName)
    name: SectionName

    @IsBoolean()
    enabled: boolean

    @IsNumber()
    @IsOptional()
    order?: number

    @IsOptional()
    options?: OrderDateOptions | StudentOptions | object
}

export type StudentOptions = {
    emailRequired: boolean
}

/**
 * A high level configuration for the transport request page
 * (which sections should be shown and in which order)
 */
export type TransportRequestPageConfiguration = {
    [key in TransportRequestType]?: TransportRequestSectionDto[]
}
