import { BaseUserID, TransportRequestDraft, TransportRequestDraftID } from '@stadtlandnetz/core'
import { TransportRequestFormDTO, convertFormDTOtoDraft } from '../data/convert'
import { ApiResource, DefaultOptions } from './api-resource.base'

export class DraftsResource extends ApiResource<TransportRequestDraft, DefaultOptions> {
    constructor() {
        super('request-drafts')
    }

    async createFromFormDTO(dto: TransportRequestFormDTO, userID: BaseUserID): Promise<TransportRequestDraft> {
        const draftData = convertFormDTOtoDraft(dto)
        return await this.createOne({
            user: userID,
            request: draftData
        })
    }

    async updateFromFormDTO(
        dto: TransportRequestFormDTO,
        userID: BaseUserID,
        draftID: TransportRequestDraftID
    ): Promise<TransportRequestDraft> {
        const draftData = convertFormDTOtoDraft(dto)
        return await this.updateEntity(
            {
                user: userID,
                request: draftData
            },
            { id: draftID }
        )
    }
}
