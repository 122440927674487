import { ApiProperty } from "@nestjs/swagger"
import { IsNotEmpty, ValidateNested } from "class-validator"
import type { OrderDateDto, NotesDto } from "../types"
import { SchoolWish } from "../../schools"
import { Guardian, Sibling, Student } from "../../students"
import type { SystemID } from "../../system/entities"
import { type GenericRouteType, PublicTransportTrip } from "../../transport"
import { BankAccount } from "../../users"
import { Address } from "../../utils"
import { TransportRequestType } from "./TransportRequestType.enum"
import type { SectionName } from "@stadtlandnetz/forms"
import type { File } from "../../files/entities"
import type { EvaluationSummary } from "../../evaluation-schema"
import type { VariableFieldsValues } from "../../variable-fields"

export class CheckTransportRequestDTO {
    @ApiProperty()
    @ValidateNested()
    address: Address

    @ApiProperty()
    @ValidateNested()
    schoolWish: SchoolWish

    @ApiProperty()
    @IsNotEmpty()
    type: TransportRequestType

    @ApiProperty()
    parentTransportRequestId?: number

    constructor(init: CheckTransportRequestDTO) {
        Object.assign(this, init)
    }
}

export class SaveTransportRequestDTO extends CheckTransportRequestDTO {
    @ApiProperty()
    @ValidateNested()
    student: Student

    @ApiProperty()
    @ValidateNested()
    siblings: Sibling[]

    @ApiProperty()
    @ValidateNested()
    guardians: Guardian[]

    @ApiProperty()
    selfPay?: boolean

    @ApiProperty()
    @ValidateNested()
    publicTransport?: PublicTransportTrip

    @ApiProperty()
    @ValidateNested()
    bankAccount?: BankAccount

    @ApiProperty()
    @ValidateNested()
    additionalAttributes?: SystemID[]

    @ApiProperty()
    @ValidateNested()
    orderDate?: OrderDateDto

    @ApiProperty()
    lastSection?: SectionName

    @ApiProperty()
    @ValidateNested()
    notes?: NotesDto

    @ApiProperty()
    @ValidateNested()
    files?: File[]

    @ApiProperty()
    @ValidateNested()
    acceptedFiles?: File[]

    @ApiProperty()
    passportImage?: string

    @ApiProperty()
    routeToSchool?: GenericRouteType

    @ApiProperty()
    summary?: EvaluationSummary

    @ApiProperty()
    requestVariableFields?: VariableFieldsValues

    @ApiProperty()
    refundVariableFields?: VariableFieldsValues

    constructor(init: SaveTransportRequestDTO) {
        super(init)
        Object.assign(this, init)
    }
}
