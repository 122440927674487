import {  Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import {FareZoneConnectionsVersion} from "./FareZoneConnectionsVersion.entity";

@Index('id', ['id'], { unique: true })
@Entity('fare_zone_connections')
export class FareZoneConnections {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @ManyToOne(type => FareZoneConnectionsVersion, { nullable: false })
  @JoinColumn({ name: 'version_id' })
  versionId: number

  @Column({ type: 'varchar', length: 255, name: 'entry_zone', nullable: false })
  entryZone: number
  @Column({ type: 'varchar', length: 255, name: 'exit_zone', nullable: false })
  exitZone: number

  @Column('varchar', { name: 'fare_zone', nullable: false, length: "100" })
  fareZone: string

  @Column('varchar', { name: 'via', nullable: true, length: "100" })
  viaZone: string

  constructor (init?: Partial<FareZoneConnections>) {
    Object.assign(this, init)
  }
}
