import {  Column, Entity, PrimaryGeneratedColumn } from 'typeorm'

@Entity("system_addons")
export class SystemAddons {
  @PrimaryGeneratedColumn({ type: "int", name: "id", unsigned: true })
  id: number;

  @Column("tinyint", { name: "active", width: 1 })
  active: boolean;

  @Column("varchar", { name: "addonName", length: 255 })
  addonName: string;

  constructor(init?: Partial<SystemAddons>) {
    Object.assign(this, init);
  }
}
