import {
    IsEmail,
    IsEnum,
    IsNotEmpty,
    IsOptional,
    IsPhoneNumber,
    IsString,
    Matches,
    MinLength,
    ValidateIf,
    ValidateNested
} from 'class-validator'
import { FormClass, FormField, InputType } from '@stadtlandnetz/forms'
import { Address, Gender, SameAs, dayjs } from '../../utils'
import { Title } from '../../utils/Title'
import { ApiHideProperty, ApiProperty } from '@nestjs/swagger'
import { Language } from '../../system'

export enum RegistrationType {
    NORMAL = 'normal',
    BUNDID = 'bundid',
    BIRTHDAY_OPTIONAL = 'birthday_optional'
}

const { NORMAL, BUNDID, BIRTHDAY_OPTIONAL } = RegistrationType

@FormClass({ interfereLabels: true, defaultGroupName: 'Persönliche Angaben' })
export class Register {
    @ApiProperty({ example: 'divers' })
    @FormField({
        type: InputType.SELECT,
        options: [
            {
                title: '$mr',
                value: 'männlich'
            },
            {
                title: '$ms',
                value: 'weiblich'
            },
            {
                title: '$mx',
                value: 'divers'
            }
        ],
        title: 'salutation'
    })
    @IsEnum(Gender, { always: true })
    sex: Gender

    @ApiProperty({ example: 'Prof.' })
    @FormField({
        placeholder: 'kein Titel',
        type: InputType.SELECT,
        optional: true,
        options: [
            {
                title: 'Dr.',
                value: 'Dr.'
            },
            {
                title: 'Prof.',
                value: 'Prof.'
            },
            {
                title: 'Prof. Dr.',
                value: 'Prof. Dr.'
            }
        ]
    })
    @IsOptional({ always: true })
    @IsEnum(Title, { always: true })
    title: Title

    @ApiProperty({ example: 'Haft' })
    @FormField({ placeholder: 'Mustermann', title: 'lastname' })
    @IsString({ always: true })
    @IsNotEmpty({ always: true })
    lastName: string

    @ApiProperty({ example: 'Ernst' })
    @FormField({ placeholder: 'Max', title: 'firstname' })
    @IsString({ always: true })
    @IsNotEmpty({ always: true })
    firstName: string

    @ApiProperty({ example: '01.01.1993' })
    @FormField({ placeholder: '01.01.2020', type: InputType.DATE, endDate: dayjs(new Date()).format('YYYY-MM-DD') })
    @ValidateIf(() => false, { groups: [BIRTHDAY_OPTIONAL] })
    @IsNotEmpty({ always: true })
    birthday: Date | null

    @ApiProperty({ example: 'parent@via.de' })
    @FormField({ class: 'two-columns', placeholder: 'max@mustermann.de', title: 'mail' })
    @IsEmail({}, { always: true })
    email: string

    @ApiProperty({ example: '01234 567890' })
    @FormField({ placeholder: '01234 567890', title: 'phone', optional: true })
    @ValidateIf((context) => context.phone, { always: true })
    @IsPhoneNumber('de', { always: true })
    phone: string

    @ApiProperty({ example: 'Verein e.V.' })
    @FormField({ placeholder: 'Verein e.V.', title: 'institution', optional: true })
    @IsOptional({ always: true })
    institution: string

    @ApiProperty({ example: '*****' })
    @FormField({ type: InputType.PASSWORD })
    @MinLength(12, { groups: [NORMAL] })
    @Matches(/(?=.*\d)(?=.*[\W_])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z\d\W_]+/, {
        message: 'password must match regular expression',
        groups: [NORMAL]
    })
    password: string

    @FormField({ type: InputType.PASSWORD, title: 'password-confirm', oneliner: true })
    @ApiHideProperty()
    @SameAs('password', { message: 'passwordConfirm must be the same as your password', always: true })
    passwordConfirm: string

    @FormField()
    @ValidateNested({ always: true })
    address: Address

    @ApiProperty({ example: 'de' })
    @IsOptional({ always: true })
    @IsEnum(Language, { always: true })
    language?: Language

    @ApiProperty({ type: Boolean })
    @IsOptional({ always: true })
    isSelfApplicant: boolean

    constructor(init?: Partial<Register>) {
        this.address = new Address({})
        Object.assign(this, init)
    }
}
