import { ApiProperty } from '@nestjs/swagger'
import { IsBoolean, IsEmail, IsEnum, IsOptional } from 'class-validator'
import { Language } from '../../system'

export class PasswordOneTimePinRequestDTO {
    @ApiProperty({ example: 'parent@via.de' })
    @IsEmail()
    baseUserEmail: string

    @ApiProperty()
    @IsBoolean()
    @IsOptional()
    resend: boolean

    @ApiProperty({ example: 'en' })
    @IsEnum(Language)
    @IsOptional()
    language?: Language
}
