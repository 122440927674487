import {  Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { Lot, Tender } from '.'

@Entity('vergaben_kfz')
export class TenderVehicleConnection {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => Tender, {
    nullable: false,
 onUpdate: 'CASCADE',
onDelete: 'CASCADE' 
  })
  @JoinColumn({name: 'vergabeid'})
  tender: Tender 
  
  /* @ManyToOne(type => Vehicle, { 
    nullable: true,
 onUpdate: 'CASCADE',
onDelete: 'SET NULL' 
  })
  @JoinColumn({ name: 'kfzid' })
  vehicle: Vehicle */
  
  @ManyToOne(type => Lot, {
    nullable: false,
 onUpdate: 'CASCADE',
onDelete: 'CASCADE' 
  })
  @JoinColumn({name: 'zuschlag_losid'})
  winningLot: Lot

  constructor (init?: Partial<TenderVehicleConnection>) {
    Object.assign(this, init)
  }
}
