import { IsBoolean, IsNotEmpty, IsOptional, IsString } from 'class-validator'

export class S3BucketQueryParams {
    @IsString()
    @IsNotEmpty()
    instance: string

    @IsString()
    @IsNotEmpty()
    s3StoragePath: string

    @IsOptional()
    @IsBoolean()
    customS3Image: boolean
}
