import {  Column, Entity, JoinColumn, ManyToOne, OneToMany, OneToOne, PrimaryGeneratedColumn } from 'typeorm'
import { Lot, TenderMeta } from '.'
import { VehicleMode } from '../types/vehicleMode'
import { System } from '../../system'
import { TenderVehicleBidRank } from './modul2'

@Entity('vergaben')
export class Tender {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @Column('varchar', { name: 'name', length: 255 })
  name: string

  @Column('varchar', { name: 'kurzform', length: 255 })
  short: string

  @OneToOne(type => TenderMeta, tenderMeta => tenderMeta.tender)
  information: TenderMeta[]

  @OneToMany(type => Lot, lot => lot.tender)
  lots: Lot[]

  @ManyToOne(type => System, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({ name: 'vergabe_art' })
  type: System

  // Document
  @Column('text', { name: 'begruendung' })
  explanation: string

  @Column('date', { name: 'angebotsfrist_start' })
  offerDeadlineStart: string

  @Column('date', { name: 'angebotsfrist_ende' })
  offerDeadlineEnd: string

  @Column('date', { name: 'bindefrist' })
  bindingUntil: Date

  @Column('varchar', { name: 'ausfuehrungszeitraum', length: 255 })
  executionPeriod: string

  @Column('tinyint', { name: 'vorinfo_erforderlich', width: 1 })
  preinfoNecessary: boolean

  @Column('tinyint', { name: 'online_veroeffentlichen', width: 1 })
  publishOnline: boolean

  @Column('date', { name: 'archiviert_am' })
  archivedAt: Date

  // @Column({ name: 'kfz_behandlung', type: 'enum', enum: VehicleMode, default: VehicleMode.ONE_TO_ONE })
  @Column({ name: 'kfz_behandlung', type: 'tinyint', width: 1, default: VehicleMode.ONE_TO_ONE })
  vehicleMode: VehicleMode

  @OneToMany(type => TenderVehicleBidRank, bidrank => bidrank.tender)
  V2vehicleBidRanks: TenderVehicleBidRank[]

  constructor (init?: Partial<Tender>) {
    Object.assign(this, init)
  }
}
