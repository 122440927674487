import { Type } from 'class-transformer'
import { IsArray, ValidateNested } from 'class-validator'
import type { Direction } from '../../tours'
import type { Weekday } from '../../utils'
import type { GenericLocation } from './generic-routing.types'
import { GenericRoute } from './generic-routing.types'
import type { TransportType } from './TransportType'

export class TimetableEntry {
    readonly type: TransportType

    @Type(() => Date)
    begin: Date

    @Type(() => Date)
    end: Date

    start: string
    exit: string
    schoolName: string
    direction: Direction

    @ValidateNested()
    @Type(() => GenericRoute)
    route?: GenericRoute
}

export interface PublicTransportTimetableEntry extends TimetableEntry {
    readonly type: TransportType.Public
}

export interface IndividualTransportTimetableEntry extends TimetableEntry {
    readonly type: TransportType.Individual

    startPin: GenericLocation
    exitPin: GenericLocation
    company: string
    tour: string
}

export interface LessonTimetableEntry extends TimetableEntry {
    readonly type: null
}

export type TimetableRecord = PublicTransportTimetableEntry | IndividualTransportTimetableEntry

export class Timetable {
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => TimetableEntry)
    monday: TimetableEntry[] = []

    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => TimetableEntry)
    tuesday: TimetableEntry[] = []

    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => TimetableEntry)
    wednesday: TimetableEntry[] = []

    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => TimetableEntry)
    thursday: TimetableEntry[] = []

    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => TimetableEntry)
    friday: TimetableEntry[] = []

    constructor(init?: Timetable) { Object.assign(this, init) }
}

export type TimetableRows = { [hour: number]: TimetableRecord[][] }

export const databaseWeekdayMap: { [weekdayShort: string]: Weekday } = {
    mo: 'monday',
    di: 'tuesday',
    mi: 'wednesday',
    do: 'thursday',
    fr: 'friday'
}