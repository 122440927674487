import { Column, Entity, JoinColumn, OneToOne, PrimaryGeneratedColumn } from "typeorm"
import { ApiProperty } from "@nestjs/swagger"
import { School } from "../../schools/entities"
import { BaseUser } from "./BaseUser.entity"

@Entity('one_time_pins')
export class OneTimePin {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @ApiProperty()
    @Column({ type: 'text', nullable: false })
    pin: string

    @Column({ type: 'timestamp', nullable: false })
    createdAt: Date

    @OneToOne(() => School, school => school.oneTimePin, {
        nullable: true
    })
    @JoinColumn({ name: 'schuleid' })
    school: School

    @OneToOne(() => BaseUser, user => user.resetPasswordPin, {
        nullable: true
    })
    @JoinColumn({ name: 'user_id' })
    user: BaseUser

    constructor(init: Partial<OneTimePin>) {
        Object.assign(this, init)
    }
}