import {  Column, Entity, PrimaryGeneratedColumn } from 'typeorm'

/**
 * Deprecated!!! Can be removed in the near future
 */
@Entity('touren_fahrplan')
export class TourTimetable {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @Column('int', { name: 'touren_fahrten_id', nullable: true })
  tourenFahrtenId: number | null

  @Column('text', { name: 'art', nullable: true })
  art: string | null

  @Column('text', { name: 'haltestelle', nullable: true })
  haltestelle: string | null

  @Column('tinyint', { name: 'hst_art', nullable: true, width: 1 })
  hstArt: boolean | null

  @Column('text', { name: 'geocode', nullable: true })
  geocode: string | null

  @Column('text', { name: 'zeit', nullable: true })
  time: string | null

  @Column('text', { name: 'schüler', nullable: true })
  student: string | null

  @Column('int', { name: 'ind', nullable: true })
  ind: number | null

  constructor (init?: Partial<TourTimetable>) {
    Object.assign(this, init)
  }
}
