import { SchoolSearchResults, SystemID, TransportRequestType } from '@stadtlandnetz/core'
import { getHttpOptions } from '../stores/api.stores'
import { getEndpointUrl, request } from './api-resource.base'

export class SchoolResource {
    constructor(private endpoint: string = 'school') { }

    async search(query?: string | undefined, schoolType?: SystemID, requestType?: TransportRequestType): Promise<SchoolSearchResults> {
        const url = getEndpointUrl(this.endpoint, { query, schoolType, requestType })
        return request(url, getHttpOptions('GET'))
    }

    /* async getFitting(type: SystemID, form: SystemID, profile: SystemID): Promise<School[]> {
        const url = getEndpointUrl('/school/byType', {type, form, profile})
        return request(url, getHttpOptions('GET'))
    } */
}
