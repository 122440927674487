import {
    AddressByLetterVerification,
    BankAccount,
    EmailVerification,
    Guardian,
    SchoolType,
    TransportRequestStatus
} from '@stadtlandnetz/core'
import { AccordingEntitiesResource } from './according-entities.resource'
import { ApiResource, DefaultOptions } from './api-resource.base'
import { AuthResource } from './auth.resource'
import { CheckRequestResource } from './check-transport-request.resource'
import { ConversationResource } from './conversation.resource'
import { DocumentsResource } from './documents.resource'
import { DraftsResource } from './drafts.resource'
import { GeocodeResource } from './geocode.resource'
import { OpenIbanServiceResource } from './openIban-service.resource'
import { RoutingResource } from './routing.resource'
import { SchoolAffiliationResource } from './school-affiliation.resource'
import { SchoolClassesResource } from './school-classes.resource'
import { SchoolResource } from './school.resource'
import { StopsResource } from './stops.resource'
import { StudentsResource } from './students.resource'
import { SystemResource } from './system.resource'
import { TimetableResource } from './timetable.resource'
import { TransportRequestResource } from './transport-request.resource'
import { UploadResource } from './upload.resource'
import { UserVerificationResource } from './user-verification.resource'
import { UserResource } from './user.resource'

interface BankAccountOptions extends DefaultOptions {
    userId: number
}

export class API {
    constructor(
        public accordingEntities = new AccordingEntitiesResource(),
        public auth = new AuthResource(),
        public bankAccount = new ApiResource<BankAccount, BankAccountOptions>('users/{userId}/bankaccounts'),
        public checkRequest = new CheckRequestResource(),
        public conversations = new ConversationResource(),
        public geocode = new GeocodeResource(),
        public guardians = new ApiResource<Guardian, DefaultOptions>('guardians'),
        public drafts = new DraftsResource(),
        public schoolAffiliation = new SchoolAffiliationResource(),
        public schoolClasses = new SchoolClassesResource(),
        public schoolTypes = new ApiResource<SchoolType, DefaultOptions>('schooltypes'),
        public schools = new SchoolResource(),
        public stops = new StopsResource(),
        public students = new StudentsResource(),
        public system = new SystemResource(),
        public transportRequests = new TransportRequestResource(),
        public transportRequestStateMapping = new ApiResource<TransportRequestStatus, DefaultOptions>(
            'requestStateMapping'
        ),
        public users = new UserResource(),
        public verifyAddress = new UserVerificationResource<AddressByLetterVerification>('addressByLetter'),
        public verifyMail = new UserVerificationResource<EmailVerification>('email'),
        public openIbanService = new OpenIbanServiceResource(),
        public uploadResource = new UploadResource(),
        public documentsResource = new DocumentsResource(),
        public routing = new RoutingResource(),
        public timetable = new TimetableResource()
    ) {}
}

export const api = new API()
