import { Column, Entity, JoinTable, ManyToMany, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import { ValidTimespanEnglish, ValidTimespanRequired } from '../../utils'
import { RestrictedAreaType } from '../types'
import { RestrictedAreaPolygon } from './RestrictedAreaPolygon.entity'
import { System } from '../../system'

@Entity('restricted_areas')
export class RestrictedArea {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @Column({ name: 'type', type: 'enum', enum: RestrictedAreaType, nullable: false })
    type: RestrictedAreaType

    @Column('varchar', { name: 'name', length: 255, nullable: true })
    name: string | null

    @Column('text', { name: 'description', nullable: true })
    description: string | null

    @Column((type) => ValidTimespanEnglish)
    valid: ValidTimespanEnglish

    @OneToMany(type => RestrictedAreaPolygon, polygon => polygon.restrictedArea)
    polygons: RestrictedAreaPolygon[]

    @ManyToMany(type => System, { nullable: false })
    @JoinTable({ name: 'restricted_areas_classes' })
    classes: System[]

    constructor(init?: Partial<RestrictedArea>) {
        Object.assign(this, init)
    }
}
