import {
  Column,
  Entity,
  Index,
  PrimaryGeneratedColumn
} from 'typeorm'
import type { State } from '../../utils'

@Index('id', ['id'], { unique: true })
@Entity('kalender_feiertage')
export class PublicHolidays {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @Column('varchar', { name: 'name', nullable: true, length: 100 })
  name: string | null

  @Column('int', { name: 'jahr', nullable: false, width: 4 })
  year: string

  @Column('date', {
    name: 'datum',
    nullable: true
  })
  date: Date | null

  // @Column({ name: 'land', nullable: false, type: 'enum', enum: State })
  @Column({ name: 'land', nullable: false, type: 'varchar', length: 10 })
  state: State

  @Column('tinytext', { name: 'kommentar', nullable: true})
  comment: string | null

  constructor (init?: Partial<PublicHolidays>) {
    Object.assign(this, init)
  }
}
