import { ApiProperty } from "@nestjs/swagger"
import { IsArray, IsNotEmpty, ValidateNested } from "class-validator"
import { Type } from 'class-transformer'
import { LessonTimes } from "../entities"

export class EditLessonTimesDTO {
    @ApiProperty()
    @IsNotEmpty()
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => LessonTimes)
    lessonTimes: LessonTimes[]
}