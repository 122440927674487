import { ApiProperty } from '@nestjs/swagger'
import { ValidateNested } from 'class-validator'
import type { Address } from '../../utils'
import type { Guardian } from '../../students'

export class CreateAddressByLetterVerificationDTO {
    @ApiProperty()
    @ValidateNested()
    address: Address

    @ApiProperty()
    @ValidateNested()
    guardian: Guardian
}
