import { MIME_TYPE, MIME_TYPE_MAP } from '@stadtlandnetz/core'
import { apiBaseUrl, getHttpOptions } from '../stores/api.stores'
import { error, warning } from '../stores/data.stores'

export interface DefaultOptions {
    id: number | string
}

export function getEndpointUrl(endpoint: string, params: Record<string, string | number> = null): URL {
    // Remove the leading slash from the endpoint. The api base url can already have a path segment which would be erased
    // by `new URL(...)` if there is a leading slash (as it would mean the endpoint is relative to the host)
    endpoint = endpoint.replace(/^\//, '')
    const url = new URL(endpoint, apiBaseUrl)

    if (params != null) {
        const filteredParams: Record<string, string> = {}
        for (const [key, value] of Object.entries(params)) {
            if (!value) continue

            filteredParams[key] = typeof value === 'number' ? value.toString() : value
        }
        url.search = new URLSearchParams(filteredParams).toString()
    }

    return url
}

export async function request(
    endpoint: string | URL,
    options: RequestInit,
    hideSnackbars?: boolean,
    returnResponse: boolean = false
): Promise<any> {
    const url = typeof endpoint == 'object' ? endpoint.toString() : getEndpointUrl(endpoint).toString()

    try {
        const response: Response = await fetch(url, options)
        if (returnResponse) {
            return response
        }
        let data: any
        if (Object.values(MIME_TYPE_MAP).includes(response.headers.get('content-type') as MIME_TYPE)) {
            data = await response.blob()
        } else {
            try {
                data = await response.json()
            } catch (error) {
                data = null
            }
        }
        if (data?.message) {
            if (data.message === 'Invalid cookie') return
            if (response.status >= 500) {
                if (!hideSnackbars) {
                    error.set(data.message)
                }
                return
            }
            if (response.status >= 400 && response.status < 500) {
                if (!hideSnackbars) {
                    warning.set(data.message)
                }
                return
            }
        }
        return data
    } catch (e) {
        console.error(e)
    }
}

export class ApiResource<T, K extends DefaultOptions> {
    constructor(protected endpoint: string) {}

    insertIds(options: K): string {
        if (!options) {
            return this.endpoint
        }
        return Object.entries(options).reduce(
            (url, [key, id]) => url.replace('{' + key + '}', id?.toString()),
            this.endpoint
        )
    }

    async getOne(options: K): Promise<T> {
        return await request(`/${this.insertIds(options)}/${options.id}`, getHttpOptions('GET'))
    }

    async getMany(options?: K | undefined, params?: any): Promise<T[]> {
        const url = new URL(`${this.insertIds(options)}`, apiBaseUrl)
        if (params) url.search = new URLSearchParams(params).toString()
        return await request(url.toString(), getHttpOptions('GET'))
    }

    async createOne(entity: Partial<T>, options?: K | undefined): Promise<T> {
        return await request(`${this.insertIds(options)}`, {
            ...getHttpOptions('POST'),
            body: JSON.stringify(entity)
        })
    }

    async createMany(entities: Partial<T>[], options?: K | undefined): Promise<T[]> {
        return await request(`/${this.insertIds(options)}/bulk`, {
            ...getHttpOptions('POST'),
            body: JSON.stringify({ bulk: [entities] })
        })
    }

    async updateEntity(entity: Partial<T>, options: K): Promise<T> {
        return await request(`/${this.insertIds(options)}/${options.id}`, {
            ...getHttpOptions('PATCH'),
            body: JSON.stringify(entity)
        })
    }

    async replaceEntity(entity: T, options: K): Promise<T> {
        return await request(`/${this.insertIds(options)}/${options.id}`, {
            ...getHttpOptions('PUT'),
            body: JSON.stringify(entity)
        })
    }

    async deleteEntity(options: K): Promise<any> {
        const endpoint = `/${this.insertIds(options)}/${options.id}`
        const url = typeof endpoint == 'object' ? endpoint : getEndpointUrl(endpoint).toString()
        return await fetch(url, getHttpOptions('DELETE'))
    }
}
