import { FileDestinationFolder, TransportRequestDraft } from '@stadtlandnetz/core'
import { _ as fmtStore } from 'svelte-i18n'
import { get } from 'svelte/store'
import { SaveDraftAndNavigateEvent } from '../data/convert'
import { api } from '../services/api.service'
import { formatFilesForUpload } from '../services/upload.resource'
import { loading } from '../stores/api.stores'
import { baseUserId, error, savedFiles, showGoBackToDraftBanner, success } from '../stores/data.stores'

async function uploadFiles(event: SaveDraftAndNavigateEvent, draft: TransportRequestDraft) {
    const dto = event.detail.dto
    if (dto.acceptedFiles && dto.acceptedFiles.length > 0) {
        const filesToUpload = formatFilesForUpload(dto.acceptedFiles)
        const uploadResponse = await api.uploadResource.uploadFiles(
            FileDestinationFolder.DRAFTS,
            draft.id,
            filesToUpload
        )
        savedFiles.set(await uploadResponse.json())
    }
}

export async function saveDraft(
    event: SaveDraftAndNavigateEvent,
    $goto: any,
    draftId?: number | undefined
): Promise<void> {
    const $_: Function = get(fmtStore)
    // If draftId is undefined, we are creating a new draft
    const create = draftId === undefined
    try {
        loading.set(create ? 'blocking' : 'background')
        const savedDraft = create
            ? await api.drafts.createFromFormDTO(event.detail.dto, get(baseUserId))
            : await api.drafts.updateFromFormDTO(event.detail.dto, get(baseUserId), draftId)
        await uploadFiles(event, savedDraft)
        if (event.detail.showSnackbar) success.set($_('requests.saved'))
        if (event.detail.section) {
            success.set($_('requests.saved'))
            showGoBackToDraftBanner.set({
                draftId: savedDraft.id,
                entityWasSaved: false
            })
            $goto(`/account/${event.detail.section}`)
        } else if (!event.detail.navigate && savedDraft.id) {
            $goto(`/antraege/draft/${savedDraft.id}`)
        } else if (event.detail.navigate) {
            success.set($_('requests.saved'))
            $goto('/antraege')
        }
    } catch (e) {
        error.set(e)
    } finally {
        loading.set(undefined)
    }
}
