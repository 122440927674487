import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { Lot, Tender } from '..'
import { TransportCompany } from '../../../companies/entities'

@Entity('vergaben_rangfolgen_modul2')
export class TenderVehicleBidRank {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @ManyToOne(type => Tender, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'vergabeid' })
  tender: Tender

  @ManyToOne(type => TransportCompany, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'vuid' })
  company: TransportCompany

  @ManyToOne(type => Lot, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'losid' })
  lot: Lot

  @Column('int', { name: 'rang', nullable: false })
  rank: number

  constructor (init?: Partial<TenderVehicleBidRank>) {
    Object.assign(this, init)
  }
}
