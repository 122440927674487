import { Conversation, Message } from '@stadtlandnetz/core'
import { request } from './api-resource.base'
import { getHttpOptions } from '../stores/api.stores'

export class ConversationResource {
    async getConversationForUser(): Promise<Conversation[]> {
        return request(`/conversations`, getHttpOptions('GET'))
    }

    async sendMessage(conversationId: string, options): Promise<Message[]> {
        return request(`/conversations/${conversationId}/send`, {
            ...getHttpOptions('POST'),
            body: JSON.stringify(options)
        })
    }
}

