import type { CoordinatesDto } from '../../utils'
import { Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import { TourTrips, TourTripsEndTrackingAbsentPerson } from '.'
import { TransportCompanyMember, TransportCompanyVehicle } from '../../companies'

@Entity('touren_fahrten_end_tracking')
export class TourTripsEndTracking {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @ManyToOne(type => TourTrips, tourTrip => tourTrip.endTrackings, {
        nullable: false,
        onUpdate: 'CASCADE'
    })
    @JoinColumn({ name: 'touren_fahrten_id' })
    tourTrip: TourTrips

    @ManyToOne(type => TransportCompanyMember, driver => driver.endTrackings, {
        nullable: false,
        onUpdate: 'CASCADE'
    })
    @JoinColumn({ name: 'fahrer_id' })
    driver: TransportCompanyMember

    @ManyToOne(type => TransportCompanyVehicle, vehicle => vehicle.endTrackings, {
        nullable: true,
        onUpdate: 'CASCADE'
    })
    @JoinColumn({ name: 'kfz_id' })
    vehicle: TransportCompanyVehicle

    @Column({ name: 'date', type: 'date', nullable: false })
    creationDate: Date

    @Column('double', { name: 'distance_driven_km', nullable: false })
    drivenDistance: number

    @Column({ name: 'duration', type: 'time', nullable: false })
    duration: string

    @Column('varchar', { name: 'bemerkungen', length: 1000, nullable: true })
    notes: string | null

    @Column('json', { name: 'coordinates', nullable: false })
    drivenRoute: CoordinatesDto[]

    @OneToMany(type => TourTripsEndTrackingAbsentPerson,
        absentPeople => absentPeople.tourTripEndTracking, { cascade: true })
    absentPeople: TourTripsEndTrackingAbsentPerson[]

    constructor(init?: Partial<TourTripsEndTracking>) {
        Object.assign(this, init)
    }
}
