import { ApiProperty } from '@nestjs/swagger'
import {
    Column,
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryGeneratedColumn
} from 'typeorm'
import type { VariableFieldsValues } from '../../variable-fields'
import { TransportRequest } from './TransportRequest.entity'

@Entity('tbl_antraege_erstattungen')
export class TransportRequestRefunds {
    @ApiProperty({ type: Number })
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @ApiProperty()
    @JoinColumn({ name: 'antrag_id' })
    @ManyToOne(
        (type) => TransportRequest,
        (transportRequest) => transportRequest.refunds,
        {
            nullable: true,
            onUpdate: 'CASCADE',
            onDelete: 'CASCADE',
        }
    )
    transportRequest: TransportRequest | null

    @Column('json', { name: 'var_fields', nullable: true })
    variableFields: [VariableFieldsValues] | null

    @Column('timestamp', {
        name: 'lastChanged',
        default: () => 'CURRENT_TIMESTAMP',
    })
    lastChanged: Date

    constructor(init?: Partial<TransportRequestRefunds>) {
        Object.assign(this, init)
    }
}
