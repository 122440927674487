import {FormField, FormClass} from '@stadtlandnetz/forms'
import {IsNotEmpty, IsIBAN, IsBIC, IsString} from 'class-validator'
import {Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryColumn, PrimaryGeneratedColumn} from "typeorm";
import {BaseUser} from "./BaseUser.entity";
import {ApiHideProperty, ApiProperty} from "@nestjs/swagger";
import {TransportRequest} from "../../transport-request";

@Entity('bank_account')
@FormClass({interfereLabels: true})
export class BankAccount {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
    id: number

    @ApiProperty({example: 'DE07 1234 1234 1234 1234 13'})
    @FormField({placeholder: "DE07 1234 1234 1234 1234 13"})
    @IsIBAN()
    @IsNotEmpty()
    @Column({type: 'varchar', length: 50, nullable: false})
    iban: string

    @ApiProperty({example: 'MSTRSTDT1XX'})
    @FormField({placeholder: "MSTRSTDT1XX"})
    @IsBIC()
    @IsNotEmpty()
    @Column({type: 'varchar', length: 20, nullable: false})
    bic: string

    @ApiProperty({example: 'Erika Musterfrau'})
    @FormField({placeholder: 'Erika Musterfrau'})
    @IsString()
    @IsNotEmpty()
    @Column({type: 'varchar', length: 255, nullable: false})
    owner: string

    @ApiProperty({example: 'DKB'})
    @FormField({placeholder: "DKB"})
    @IsString()
    @IsNotEmpty()
    @Column({type: 'varchar', length: 255, nullable: false})
    bank: string

    @ApiHideProperty()
    @Column({type: 'integer', nullable: false})
    userId: number

    @ManyToOne(type => BaseUser, user => user.bankAccounts, {nullable: false})
    @JoinColumn({name: 'userId'})
    user: BaseUser

    @OneToMany(
        type => TransportRequest,
        transportRequest => transportRequest.bankAccount
    )
    transportRequests: TransportRequest[]

    @Column({type: 'boolean', nullable: true})
    active: boolean

    constructor(init: Partial<BankAccount>) {
        Object.assign(this, init)
    }
}