import type { FieldMetadata, FormComponentRegistry, FormMetadata } from './types'
import { InputType } from './types'

export class MetadataRegistry {
  forms: FormMetadata[] = []
  fields: FieldMetadata[] = []
  components: FormComponentRegistry = {}

  constructor (init: Partial<MetadataRegistry>) {
    Object.assign(this, init)
  }

  getFormMeta(target: Function): FormMetadata | null {
    // @ts-ignore
    return this.forms.find(formMeta => formMeta.target === target)
  }

  getFieldsMeta(target: Function): FieldMetadata[] | null {
    return this.fields.filter(fieldMetadata => fieldMetadata.target === target)
  }

  /**
   * Determines what type of html <input> type should be used for the given javascript type.
   * @param type
   */
  getInputType(type: any) {
    if (type === String) return InputType.TEXT
    if (type === Number) return InputType.NUMBER

    return InputType.TEXT
  }

  associateComponent(inputType: InputType, component: object) {
    this.components[inputType] = component
  }

  getComponent(inputType: InputType): object {

    return this.components[inputType]
  }
}

export const registry = new MetadataRegistry({})
