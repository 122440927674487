export enum TransportRequestState {
    SUBMITTED = 'submitted',
    IN_VALIDATION = 'in_validation',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    PROBABLY_APPROVED = 'probably_approved',
    PROBABLY_REJECTED = 'probably_rejected',
    SELF_PAY = 'self_pay',
    PROBABLY_APPROVED_CHANGE = 'probably_approved_change',
    PROBABLY_REJECTED_CHANGE = 'probably_rejected_change',
    NOT_PRE_EVAUATED = 'not_pre-evaluated'
}