import { Column } from 'typeorm'
import { IsNotEmpty, IsOptional, IsPostalCode, MaxLength } from 'class-validator'
import { FormClass, FormField } from '@stadtlandnetz/forms'
import { ApiProperty } from '@nestjs/swagger'

/**
 * Generic address that is intended to be embedded into other entities.
 *
 * County should have been a part of this data type. However, `TransportEntity`
 * does not have this attribute.
 *
 * Please note that `AddressByLetterVerification` references all these columns in a primary
 *
 * Improve: Factor out to separate table
 */
@FormClass({ interfereLabels: true, defaultGroupName: 'requests.type1.address' })
export class Address {
    @ApiProperty({ example: 'Königsbrücker Straße' })
    @FormField({ placeholder: 'Hauptstraße', class: 'street', max: 50 })
    @IsNotEmpty({ always: true })
    @Column('varchar', { name: 'straße', nullable: false, length: 100 })
    street: string | null

    @ApiProperty({ example: '96' })
    @FormField({ placeholder: '1c', title: 'number', class: 'hnr' })
    @IsNotEmpty({ always: true })
    @Column('varchar', { name: 'hnr', nullable: false, length: 20 })
    houseNumber: string | null

    @ApiProperty({ example: '01099' })
    @FormField({ placeholder: '47051', title: 'zip', class: 'postcode', max: 5 })
    @IsNotEmpty({ always: true })
    @IsPostalCode('DE', { always: true })
    @MaxLength(5, { always: true })
    @Column('varchar', { name: 'plz', nullable: false, length: 5 })
    postalCode: string | null

    @ApiProperty({ example: 'Dresden' })
    @FormField({ placeholder: 'Musterstadt', title: 'city', class: 'town', max: 30 })
    @IsNotEmpty({ always: true })
    @Column('varchar', { name: 'ort', nullable: false, length: 100 })
    town: string | null

    @ApiProperty({ example: null })
    @FormField({ placeholder: 'Westend', title: 'district', class: 'district', optional: true, max: 30 })
    @IsOptional()
    @Column('varchar', { name: 'ortsteil', nullable: true, length: 100 })
    district: string | null

    getFullAddress(breakAddress: boolean = false): string {
        const address = `${this.street} ${this.houseNumber}, ${this.postalCode} ${this.town}`
        return breakAddress ? address.split(',').join('\n') : address
    }

    constructor(init?: Partial<Address>) {
        Object.assign(this, init)
    }
}

export class AddressPrefixed {

    @Column('text', { name: 'addr_plz', nullable: true })
    postalCode: string | null


    @Column('text', { name: 'addr_ort', nullable: true })
    town: string | null


    @Column('text', { name: 'addr_ortsteil', nullable: true })
    district: string | null


    @Column('text', { name: 'addr_straße', nullable: true })
    street: string | null


    @Column('text', { name: 'addr_hnr', nullable: true })
    houseNumber: string | null

    getFullAddress(breakAddress: boolean = false): string {
        const address = `${this.street} ${this.houseNumber}, ${this.postalCode} ${this.town}`
        return breakAddress ? address.split(',').join('\n') : address
    }

    constructor(init?: Partial<AddressPrefixed>) {
        Object.assign(this, init)
    }
}
