import { Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import { ConnectionCheck, TransportRequestReview } from '.'
import { User } from '../../users'
import type { Direction } from '../../tours'

@Entity("antraege_pruefungen_zumutbarkeit")
export class ReasonabilityReview {
    @PrimaryGeneratedColumn({ type: "int", name: "id", unsigned: true })
    id: number;

    @ManyToOne(
        () => TransportRequestReview,
        requestReview => requestReview.reasonabilityReview,
        { onDelete: "CASCADE", onUpdate: "CASCADE", nullable: false }
    )
    @JoinColumn([{ name: "pruefung_id", referencedColumnName: "id" }])
    requestReview: TransportRequestReview;

    @Column('tinyint', {
        name: "richtung",
    })
    direction: Direction;

    // Date and Time for which the transport possibilities are reviewed (= time when student needs to travel)
    @Column("datetime", { name: "zeitpunkt" })
    time: Date;

    // Time at which the review has happened
    @Column("datetime", { name: "pruefung_am" })
    doneAt: Date;

    @ManyToOne((type) => User, (user) => user.reasonabilityReviews, {
        nullable: true,
        onUpdate: 'CASCADE',
        onDelete: 'SET NULL'
    })
    @JoinColumn({ name: "pruefung_user" })
    doneBy: User;

    @Column("tinyint", { name: "gefaehrlichkeit_wohnung_einstieg", width: 1 })
    dangerousEntrance: boolean;

    @Column("tinyint", { name: "gefaehrlichkeit_ausstieg_schule", width: 1 })
    dangerousExit: boolean;

    @Column("float", { name: "max_hstweg", precision: 12 })
    maximumDistanceToStop: number;

    // Earliest daily time at which the student might start travelling
    // Improve: Better javascript representation
    @Column("time", { name: "min_startzeitpunkt" })
    earliestStartingTime: string;

    @Column("float", { name: "max_schulwegezeit", precision: 12 })
    maximumTravelTime: number;

    // Maximum of waiting time between:
    // - arriving at school and lessons start
    // - end of school and departure
    // total for one day given in minutes
    @Column('float', { name: 'max_wartezeit_tag', precision: 12 })
    maximumWaitingTimePerDay: number

    @OneToMany(type => ConnectionCheck, check => check.review)
    connectionChecks: ConnectionCheck[]

    constructor(init?: Partial<ReasonabilityReview>) {
        Object.assign(this, init);
    }
}
