import { request } from './api-resource.base'
import { apiBaseUrl, getHttpOptions } from '../stores/api.stores'
import { Address } from '@stadtlandnetz/core'
export class GeocodeResource {
    async geocodeForward(address: Address): Promise<Response> {
        const url = new URL('geocode/forward', apiBaseUrl).toString()
        return fetch(url, {
            ...getHttpOptions('POST'),
            body: JSON.stringify({ address })
        })
    }

    async geocodeReverse(coordinates): Promise<string> {
        return request(`/geocode/reverse`, {
            ...getHttpOptions('POST'),
            body: JSON.stringify({ coordinates })
        })
    }
}
