import { IsEmail, IsNotEmpty } from 'class-validator'
import { FormClass, FormField, InputType } from '@stadtlandnetz/forms'

@FormClass({ interfereLabels: true })
export class Login {
    @FormField({ oneliner: true })
    @IsEmail()
    mail: string

    @FormField({ oneliner: true, type: InputType.PASSWORD })
    @IsNotEmpty()
    password: string

    constructor(init?: Partial<Login>) {
        Object.assign(this, init)
    }
}
