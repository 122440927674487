export enum Actions {
  ADD = 'add',
  ADHOC_SEARCH = 'adhoc',
  ANALYSIS = 'auswertungen',
  REQUEST_OWNSHARE = 'antraege_ea',
  REQUEST_STATISTIC = 'antrag_statistik',
  CALENDAR = 'kalender',
  COST_STATISTIC = 'kosten_statistik',
  EDIT = 'edit',
  EDIT_API = 'api_bearbeiten',
  EXPLORER = 'objekt_explorer',
  FILEMANAGER = 'dateimanager',
  IMPORT = 'import',
  INITIALIZATION = 'initialisierung',
  MAP_EDITOR = 'map_editor',
  PORTALS = 'portale',
  PUBLIC_TRANSPORT_DATA = 'oepnv_daten',
  QUERY_GENERATOR = 'abfragen_generator',
  QUICK_PLANNING = 'schnellverplanung',
  REMOVE = 'remove',
  ROLES = 'nutzerrollen',
  SCRIPTS = 'scripts',
  SESSIONS = 'sessions',
  SQL = 'sql',
  STACK_PRINT = 'stapeldruck',
  STSTEM_DATA= 'systemdaten',
  SYSTEM = 'system',
  USER = 'nutzerkonten',
  VARIABLE_FIELDS = 'variable_felder',
  VIEW = 'view',
}
