export enum UploadType {
    Upload = 'upload',
    Document = 'document',
    SchoolUpload = 'school_upload'
}

export enum FileDestinationFolder {
    DRAFTS = 'drafts',
    STUDENTS = 'students',
    PASSPORT = 'students_photos'
}

export enum FileType {
    PDF = 'PDF',
    PNG = 'PNG',
    JPEG = 'JPEG',
    BMP = 'BMP'
}

export const MIME_TYPE_MAP = {
    PDF: 'application/pdf',
    PNG: 'image/png',
    JPEG: 'image/jpeg',
    BMP: 'image/bmp'
} as const

export const EXTENSION_MAP: Record<string, FileType> = {
    pdf: FileType.PDF,
    png: FileType.PNG,
    jpeg: FileType.JPEG,
    jpg: FileType.JPEG,
    bmp: FileType.BMP
}

export type MIME_TYPE = typeof MIME_TYPE_MAP[FileType]
