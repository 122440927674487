import {  Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import { DocumentTemplate, StackQueryPlaceholder } from '.'
import type { SqlQuery } from '../../utils'

@Entity('tbl_stapel')
export class Stack {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @Column('varchar', { name: 'bezeichnung', nullable: false, length: 100 })
  name: string

  @Column('varchar', { name: 'beschreibung', nullable: true, length: 255 })
  description: string

  // Improve: convert to enum
  @Column('varchar', { name: 'ebene', nullable: true, length: 100 })
  level: string | null

  @ManyToOne(type => DocumentTemplate, document => document.stacks, {
    onUpdate: 'CASCADE',
    onDelete: 'SET NULL',
    nullable: true
  })
  @JoinColumn({ name: 'dokument', referencedColumnName: 'name' })
  document: DocumentTemplate

  @Column('text', { name: 'selectquery', nullable: false })
  query: SqlQuery

  // Document: What does this do?
  @Column('tinyint', { name: 'preselect', nullable: true, width: 1 })
  preselect: boolean | null

  // Document
  @OneToMany(type => StackQueryPlaceholder, placeholder => placeholder.stack)
  queryPlaceholders: StackQueryPlaceholder[]

  constructor (init?: Partial<Stack>) {
    Object.assign(this, init)
  }
}
