import {
  Column,
  Entity,
  Index,
  PrimaryGeneratedColumn
} from 'typeorm'

@Index('id', ['id'], { unique: true })
@Entity('kalender_ferien')
export class Vacation {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @Column('varchar', { name: 'bezeichnung', nullable: false, length: 100 })
  name: string | null

  @Column('date', {
    name: 'start_date',
    nullable: true
  })
  start: Date 

  @Column('date', {
    name: 'end_date',
    nullable: true
  })
  end: Date

  constructor (init?: Partial<Vacation>) {
    Object.assign(this, init)
  }
}
