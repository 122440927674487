import {  Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import { Tour, TourCostCentre, TourTrips } from '.'
import { ValidTimespanGerman } from '../../utils'


@Entity('touren_finanzen')
export class TourFinances {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => Tour, tour => tour.finances, {
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({name: 'tourid'})
  tour: Tour | null

  @ManyToOne(type => TourTrips, trip => trip.finances, {
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE' 
  })
  @JoinColumn({name: 'fahrtid'})
  trip: TourTrips | null

  // Normally, the MySQL `decimal` must be mapped to string as it can exceed the range
  // supported by the javascript `number` type. Since we set a precision that is safe
  // to be in the bounds supported by javascript, we can it convert it to number.
  @Column('decimal', {
    name: 'km_leer',
    precision: 10,
    scale: 2,
    default: () => "'0.00'"
  })
  emptyKilometer: number

  @Column('decimal', {
    name: 'km_last',
    precision: 10,
    scale: 2,
    default: () => "'0.00'"
  })
  loadKilometer: number

  @Column('decimal', {
    name: 'km_gesamt',
    precision: 10,
    scale: 2,
    default: () => "'0.00'"
  })
  totalKilometer: number

  @Column('decimal', {
    name: 'eur_km_leer',
    precision: 10,
    scale: 2,
    default: () => "'0.00'"
  })
  priceEmptyKilometer: number

  @Column('decimal', {
    name: 'eur_km_last',
    precision: 10,
    scale: 2,
    default: () => "'0.00'"
  })
  priceLoadKilometer: number

  // national regulation, its forbidden to pay for empty kilometers
  // so they recalculate empty + full kilometers for a new full kilometer price to handle the costs
  @Column('decimal', {
    name: 'preis_last_km',
    precision: 10,
    scale: 2,
    default: () => "'0.00'"
  })
  priceLoadKm: number

  // number of working hours of the companion
  @Column('decimal', {
    name: 'h_bp',
    precision: 10,
    scale: 2,
    default: () => "'0.00'"
  })
  hoursCompanion: number

  // price in euro per hours of the companion
  @Column('decimal', {
    name: 'eur_h_bp',
    precision: 10,
    scale: 2,
    default: () => "'0.00'"
  })
  eurPerHourCompanion: number

  @Column('int', {
    name: 'anzahl_fahrten',
    nullable: true,
  })
  numberOfTrips: number

  @Column('decimal', {
    name: 'kosten_fahrt',
    precision: 10,
    scale: 2,
    default: () => "'0.00'"
  })
  pricePerTrip: number

  @Column('decimal', {
    name: 'kosten_beglp',
    precision: 10,
    scale: 2,
    default: () => "'0.00'"
  })
  priceForCompanion: number

  @Column('decimal', {
    name: 'netto',
    precision: 10,
    scale: 2,
    default: () => "'0.00'"
  })
  netPrice: number

  @Column('decimal', {
    name: 'ust_satz',
    precision: 10,
    scale: 2,
    default: () => "'0.00'"
  })
  salesTaxPercentage: number

  @Column('decimal', {
    name: 'ust_betrag',
    precision: 10,
    scale: 2,
    default: () => "'0.00'"
  })
  salesTaxTotal: number

  @Column('decimal', {
    name: 'brutto_fahrt',
    precision: 10,
    scale: 2,
    default: () => "'0.00'"
  })
  grossPricePerTrip: number

  @Column('decimal', {
    name: 'brutto_tag',
    precision: 10,
    scale: 2,
    default: () => "'0.00'"
  })
  grossPricePerDay: number

  @Column(type => ValidTimespanGerman)
  valid: ValidTimespanGerman

  @Column('text', { name: 'bemerkungen', nullable: true })
  notes: string | null

  @OneToMany(type => TourCostCentre, costcentre => costcentre.finance)
  costcentres: TourCostCentre[]

  constructor (init?: Partial<TourFinances>) {
    Object.assign(this, init)
  }
}
