import { Readable, readable } from 'svelte/store'

export function startTimer(target: Date, secondsOnly?: boolean): Readable<string> {
    return readable('', (set) => {
        const update = () => {
            if (target >= new Date()) {
                const secondsRemaining = (+target - +new Date()) / 1000 | 0
                if (secondsOnly) {
                    set(`${secondsRemaining}`)
                } else {
                    set(`${secondsRemaining / 60 | 0}:${('0' + secondsRemaining % 60).slice(-2)}`)
                }
            } else {
                set('')
                clearInterval(interval)
            }
        }
        const interval = setInterval(update, 1000)
        setTimeout(() => update(), 10)
    })
}