import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { ReasonabilityNotes, ReasonabilityResult } from '../types/ReasonabilityReview'
import { ConnectionMeasurements } from '../types/ConnectionMeasurements'
import { ReasonabilityReview } from './ReasonabilityReview.entity'

@Entity('antraege_pruefungen_zumutbarkeit_verbindung')
export class ConnectionCheck {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @Column('json', { name: 'oepnv_abfrage_JSON' })
  source: object

  @Column(type => ConnectionMeasurements)
  measurements: ConnectionMeasurements

  @Column(type => ReasonabilityResult)
  result: ReasonabilityResult

  @Column(type => ReasonabilityNotes)
  notes: ReasonabilityNotes

  @Column('bigint', { name: 'reasonable_id', unsigned: true })
  reasonableId: string

  @ManyToOne(type => ReasonabilityReview, review => review.connectionChecks, {
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'reasonable_id' })
  review: ReasonabilityReview

  constructor (init?: Partial<ConnectionCheck>) {
    Object.assign(this, init)
  }
}
