import { Language, TransportRequestType, TransportRequestTypeCards } from "@stadtlandnetz/core"
import { locale } from "svelte-i18n"
import { get } from "svelte/store"
import { requestTypeCards } from "../stores/data.stores"

export function getTransportCardConfig(transportType: TransportRequestType) {
    const configuration: TransportRequestTypeCards = get(requestTypeCards)
    const { title, button, description } = configuration[transportType]
    const lang = get(locale) as Language
    return {
        title: title[lang] ?? title.de,
        button: button[lang] ?? button.de,
        description: description?.[lang] ?? description.de
    }
}