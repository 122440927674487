import { IsString } from 'class-validator'
import { DmsParams } from './DmsParams'

export class TransferFileJobDto extends DmsParams {
    @IsString() 
    storagePath: string
    
    @IsString()
    instance: string
}
