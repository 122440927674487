import { IsNumber } from "class-validator";

export class CoordinatesDto {
    @IsNumber()
    latitude: number

    @IsNumber()
    longitude: number

    constructor(init?: Partial<CoordinatesDto>) {
        Object.assign(this, init)
    }
}