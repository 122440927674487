import {ApiProperty} from "@nestjs/swagger";
import type { kilometer } from "../../utils";
import {TransportRequestState} from "../../transport-request";
import type { GenericRoute } from "../../transport";
import type { SchoolEntrance } from "../../schools";

export type NearestSchoolByAirlineDBResult = {
    id: number,
    name: string,
    airlineDistance: kilometer,
    street: string,
    houseNumber: number,
    postalCode: number,
    city: string,
    latitude: number,
    longitude: number,
    nearestEntranceByFoot?: SchoolEntrance
    nearestEntranceByCar?: SchoolEntrance
}

export type RequestNearestSchool = {school: NearestSchoolByAirlineDBResult, route: GenericRoute, routeByCar?: GenericRoute}

export class NearestSchoolEvaluation {
    @ApiProperty()
    isNearestSchool: boolean

    @ApiProperty()
    nearestSchoolId: number

    @ApiProperty()
    nearestSchoolName: string

    @ApiProperty()
    distanceToNearestSchool: string

    @ApiProperty()
    nearestSchools: RequestNearestSchool[]
}

export class MinimumDistanceEvaluation {
    @ApiProperty()
    minimumDistanceFulfilled: boolean

    @ApiProperty()
    minimumDistance: string

    @ApiProperty()
    distanceToChosenSchool: string
}

export class EvaluationSummary {
    // TODO: Remove and only rely on status
    // Overall result saying whether the request is likely to be granted or not
    @ApiProperty()
    transportClaim: boolean

    @ApiProperty()
    status: TransportRequestState

    @ApiProperty()
    nearestSchool: NearestSchoolEvaluation | null

    @ApiProperty()
    minimumDistance: MinimumDistanceEvaluation | null

    @ApiProperty()
    schoolDistrictCheck: boolean | null

    // @ApiProperty()
    // dangerous: boolean

    constructor(init: EvaluationSummary) { Object.assign(this, init) }
}

