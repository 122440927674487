import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { TransportCompany } from '.'
import { Tour } from '../../tours'

@Entity('beschwerden', { schema: 'via' })
export class Complain {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    // Improve: Reference `id`
    @ManyToOne((type) => TransportCompany, (company) => company.complains, {
        nullable: true,
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE',
    })
    @JoinColumn({ name: 'unternehmen', referencedColumnName: 'short' })
    company: TransportCompany | null

    // Improve: reference `id`
    @ManyToOne((type) => Tour, (tour) => tour.complains, {
        nullable: true,
        onUpdate: 'CASCADE',
        onDelete: 'SET NULL',
    })
    @JoinColumn({ name: 'tour', referencedColumnName: 'name' })
    tour: Tour | null

    // free text (possible values taken from System table)
    // Improve: Refactor into own table
    @Column('mediumtext', { name: 'art', nullable: true })
    type: string | null

    @Column('mediumtext', { name: 'einreicher', nullable: true })
    submitter: string | null

    @Column('date', { name: 'datum', nullable: true })
    date: Date | null

    @Column('mediumtext', { name: 'beschreibung', nullable: true })
    description: string | null

    @Column('date', { name: 'antwort_vu_datum', nullable: true })
    respondedAt: Date | null

    @Column('mediumtext', { name: 'antwort_vu_text', nullable: true })
    response: string | null

    @Column('date', { name: 'erledigt_am', nullable: true })
    doneAt: string | null

    // Document: Null here means that clerk has not decided yet
    @Column('tinyint', { name: 'berechtigt', nullable: true, width: 1 })
    legitimate: boolean | null

    @Column('mediumtext', { name: 'konsequenz', nullable: true })
    consequence: string | null

    constructor(init?: Partial<Complain>) {
        Object.assign(this, init)
    }
}
