import { TransportCompany } from '../../companies'
import { School } from '../../schools'
import { Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { News } from './news.entity'
import { User } from '../../users'

@Entity('news_rights')
export class NewsRights {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @ManyToOne(
        type => News,
        news => news.newsRights,
        { 
            nullable: false,
            onUpdate: 'CASCADE'
        })
    @JoinColumn({ name: 'news_id' })
    news: News

    @ManyToOne(
        type => TransportCompany,
        transportCompany => transportCompany.newsRights,
        { 
            nullable: false,
            onUpdate: 'CASCADE'
        })
    @JoinColumn({ name: 'vu_id' })
    company: TransportCompany

    @ManyToOne(
        type => School,
        school => school.newsRights,
        { 
            nullable: false,
            onUpdate: 'CASCADE'
        })
    @JoinColumn({ name: 'school_id' })
    school: School

    @ManyToOne(
        type => User,
        user => user.newsRights,
        { 
            nullable: false,
            onUpdate: 'CASCADE'
        })
    @JoinColumn({ name: 'user_id' })
    user: User

    constructor(init?: Partial<NewsRights>) {
        Object.assign(this, init)
    }
}
