import {
    ChangeUserDTO,
    LoginResponse,
    OneTimePinPayloadDTO,
    PasswordOneTimePinRequestDTO,
    PasswordOneTimePinResponseDTO,
    Register,
    ResetPasswordDTO
} from '@stadtlandnetz/core'
import { apiBaseUrl, getHttpOptions } from '../stores/api.stores'
import { request } from './api-resource.base'

export class AuthResource {
    async refresh(): Promise<LoginResponse> {
        return request('/users/refresh', { method: 'GET', credentials: 'include' })
    }

    async register(user: Register): Promise<Response | null> {
        const url = new URL('users/register', apiBaseUrl).toString()
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(user)
        })
    }

    async reactivate(mail: string, password: string): Promise<boolean> {
        return request(
            '/users/reactivate',
            {
                ...getHttpOptions('POST'),
                body: JSON.stringify({ mail, password })
            },
            true
        )
    }

    async login(username: string, password: string): Promise<Response> {
        const url = new URL('users/login', apiBaseUrl).toString()
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ username, password })
        })
    }

    async logout(): Promise<Response> {
        const url = new URL('users/logout', apiBaseUrl).toString()
        return fetch(url, getHttpOptions('POST'))
    }

    async changeUser(user: ChangeUserDTO): Promise<Response> {
        return request(
            '/users/update-user',
            {
                ...getHttpOptions('POST'),
                body: JSON.stringify(user)
            },
            true,
            true
        )
    }

    async sendResetPasswordPin(dto: PasswordOneTimePinRequestDTO): Promise<PasswordOneTimePinResponseDTO | null> {
        return request('/users/sendResetPasswordPin', {
            ...getHttpOptions('POST'),
            body: JSON.stringify(dto)
        })
    }

    async resetPassword(dto: ResetPasswordDTO): Promise<boolean> {
        return request('/users/resetPassword', {
            ...getHttpOptions('POST'),
            body: JSON.stringify(dto)
        })
    }

    async checkOTPForUser(dto: OneTimePinPayloadDTO): Promise<any> {
        return fetch(new URL('users/checkOneTimePin', apiBaseUrl).toString(), {
            ...getHttpOptions('POST'),
            body: JSON.stringify(dto)
        })
    }

    async confirmBundID(dto: Register): Promise<Response> {
        return request(
            '/users/bundid/confirm',
            {
                ...getHttpOptions('POST'),
                body: JSON.stringify(dto)
            },
            true,
            true
        )
    }
}
