import {  Column, Entity, PrimaryGeneratedColumn } from 'typeorm'

@Entity('historie')
export class Historie {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @Column('timestamp', {
    name: 'timestamp',
    default: () => 'CURRENT_TIMESTAMP'
  })
  timestamp: Date

  @Column('text', { name: 'column_name' })
  columnName: string

  @Column('text', { name: 'old_value' })
  oldValue: string

  @Column('text', { name: 'new_value' })
  newValue: string

  @Column('int', { name: 'sid' })
  sid: number

  @Column('text', { name: 'tabelle' })
  tabelle: string

  constructor (init?: Partial<Historie>) {
    Object.assign(this, init)
  }
}
