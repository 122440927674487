import { registry } from './registry'
import type { DataClass, FieldOptions, FormOptions } from './types'

/**
 * Pushes general settings for the whole class to the registry.
 *
 * We can use this to store options that are relevant to all fields of a class.
 *
 * @param options
 * @constructor
 */
export function FormClass(options?: FormOptions): ClassDecorator {
  if (!options) options = {}
  return (target: Function) => {
    // @ts-ignore
    registry.forms.push({ target, options })
  }
}

/**
 * Indicates that a certain property of class should be shown in the forms.
 *
 * It is a decorator factory that takes some `FieldOptions` as an input.
 *
 * Given the following class
 * ```
 * class Student {
 *    @FormField({placeholder: 'Peter'})
 *    name: string
 * }
 * ```
 *
 * the decorator factory gets called with `options = {placeholder: 'peter'}`.
 *
 * @param options
 * @constructor
 */
export function FormField(options?: FieldOptions | null): PropertyDecorator {
  if (!options) options = {}

  /**
   * The function returned below. In the example from above, the variables are set to:
   * - target: `Student`
   * - propertyName: `name`
   *
   * Based on these information, it creates an object of type `FieldMetadata` and pushes it to `registry.fields`, a list
   * of all properties that should be shown in the forms.
   */
  // @ts-ignore
  return (target: DataClass, propertyName: string) => {
    registry.fields.push({
      target: target.constructor,
      propertyName,
      // @ts-ignore
      options
    })
  }
}
