import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { TripRequirement } from '.'

// VAB = Verkehrsautomatisierung Berlin
@Entity('fahrten_api_vab')
export class FahrtenApiVab {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(
    () => TripRequirement,
    { onDelete: "CASCADE", onUpdate: "CASCADE" }
  )
  @JoinColumn([{ name: "fahrtid", referencedColumnName: "id" }])
  mondayTrip: TripRequirement

  @Column('varchar', { name: 'identificationNumber', length: 255 })
  identificationNumber: string

  @Column('text', { name: 'startTyp' })
  startTyp: string

  @Column('text', { name: 'startAdresse' })
  startAdresse: string

  @Column('text', { name: 'zielTyp' })
  zielTyp: string

  @Column('text', { name: 'zielAdresse' })
  zielAdresse: string

  @Column('timestamp', { name: 'ts', default: () => 'CURRENT_TIMESTAMP' })
  timestamp: Date

  constructor (init?: Partial<FahrtenApiVab>) {
    Object.assign(this, init)
  }
}
