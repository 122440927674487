import {Column, Entity, JoinTable, ManyToMany, ManyToOne, OneToMany, PrimaryGeneratedColumn} from "typeorm";
import {BaseUser} from "../../users/entities";
import {TransportRequest} from "../../transport-request/entities";
import {Message} from "./Message.entity";
import {ApiProperty} from "@nestjs/swagger";

@Entity()
export class Conversation {
    // @ApiProperty({ type: Number })
    @PrimaryGeneratedColumn()
    id: number

    //
    @Column({type: 'varchar', length: 255, nullable: false})
    subject: string

    @ApiProperty({ type: () => BaseUser})
    @ManyToMany(type => BaseUser, user => user.conversations, { cascade: true })
    @JoinTable()
    users: BaseUser[]

    @ApiProperty({ type: () => Message, isArray: true})
    @OneToMany(type => Message, message => message.conversation, { cascade: true })
    messages: Message[]

    /*
     * The following attributes are possible links to other tables in the database.
     * They all have to be nullable, as there are conversations with e.g. general questions which do not relate
     * to any other entity.
     */
    @ApiProperty({ type: () => TransportRequest})
    @ManyToOne(
        type => TransportRequest,
        transportRequest => transportRequest.conversations,
        {nullable: true}
    )
    transportRequest: TransportRequest

    constructor(init: Partial<Conversation>) {
        Object.assign(this, init)
    }
}