import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { User } from '../../users/entities'
import { DocumentTemplate } from '.'
import { ApiHideProperty } from '@nestjs/swagger'

@Entity('archiv')
export class Document {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @Column('varchar', { name: 'name', length: 255 })
  name: string

  // This should be one of the DocumentLevel values for myVia use case
  @Column('varchar', { name: 'level', length: 255 })
  level: string

  @Column('int', { name: 'referenzid' })
  reference: number

  @ApiHideProperty()
  @ManyToOne(
    () => User,
    (user) => user.id,
    { onDelete: "SET NULL", onUpdate: "CASCADE", nullable: true }
  )
  @JoinColumn([{ name: "userid", referencedColumnName: "id" }])
  user: User

  @Column('date', { name: 'date' })
  date: Date

  @ManyToOne(
    () => DocumentTemplate,
    (documentTemplate) => documentTemplate.id,
    { onDelete: "RESTRICT", onUpdate: "CASCADE" }
    )
  @JoinColumn([{ name: "refdocumentid", referencedColumnName: "id" }])
  referencedDocumentTemplate: DocumentTemplate

  @Column({ type: 'mediumtext', name: 'content', nullable: false })
  content: string

  // Info: 
  // this has nothing to do with the stack function of via, kind of legacy
  // no relation
  @Column('int', { name: 'stapelid', unsigned: true})
  printQueueID: number

  @Column('tinyint', { name: 'pdfprint', nullable: false, width: 1, default: false})
  pdfprint: boolean | null

  @Column({ type: 'varchar', name: 'mailId', length: 255, nullable: true })
  mailIdentifier: string | null

  @Column({ name: 'expirationDate', type: 'date', nullable: true })
  expirationDate: Date | null

  constructor (init?: Partial<Document>) {
    Object.assign(this, init)
  }
}
