import { ApiProperty } from '@nestjs/swagger'
import { IsEmail, IsOptional, IsString, Matches, MinLength, ValidateIf } from 'class-validator'

export class ChangeUserDTO {
    @ApiProperty({ example: 'username@example.com' })
    @IsOptional()
    @IsEmail()
    username: string

    @ApiProperty({ example: '*****' })
    @ValidateIf((o) => Boolean(o.newPassword))
    @IsString()
    currentPassword: string

    @ApiProperty({ example: '*****' })
    @ValidateIf((o) => !o.username)
    @IsString()
    @MinLength(12)
    @Matches(/(?=.*\d)(?=.*[\W_])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z\d\W_]*/, {
        message: 'password must match regular expression'
    })
    newPassword?: string

    constructor(init?: Partial<ChangeUserDTO>) {
        Object.assign(this, init)
    }
}
