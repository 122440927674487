import { ApiProperty } from '@nestjs/swagger'
import { IsBoolean, IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator'
import { Language, Theme } from '.'

export interface LandingPageTextConfig {
    landingTitle: string
    landingText: string
}

export class GeneralFrontendConfigurationDTO {
    @ApiProperty({ example: 'Dresden' })
    @IsString()
    @IsNotEmpty()
    cityName: string

    @IsString()
    @IsOptional()
    landingPageText?: {
        [key in Language]?: {
            default: LandingPageTextConfig,
            loggedIn: LandingPageTextConfig
        }
    }

    @IsBoolean()
    @IsOptional()
    selfPayment?: boolean

    @IsOptional()
    @IsEnum(Theme)
    theme?: Theme

    @IsOptional()
    @IsBoolean()
    enableConfetti?: boolean

    @IsOptional()
    @IsBoolean()
    enableFeedback?: boolean

    @IsOptional()
    @IsBoolean()
    enableTimetable?: boolean

    @IsOptional()
    @IsBoolean()
    enableLandingFAQ?: boolean

    @IsOptional()
    @IsBoolean()
    enableChangeRequestFeature?: boolean

    @IsOptional()
    @IsBoolean()
    enableRequestsWithoutRoutes?: boolean

    @IsOptional()
    @IsBoolean()
    considerSchoolYearEndWithVacation?: boolean

    @IsOptional()
    @IsBoolean()
    enableBundID?: boolean

    @IsOptional()
    @IsBoolean()
    maintenanceMode?: boolean
}