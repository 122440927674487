import { BaseUserID, System, TransportRequest, TransportRequestEvaluationLogs } from '@stadtlandnetz/core'
import { TransportRequestFormDTO, convertFormDTOToSaveDTO } from '../data/convert'
import { getHttpOptions } from '../stores/api.stores'
import { bankAccount } from '../stores/data.stores'
import { DefaultOptions, request } from './api-resource.base'
import { api } from './api.service'

export interface TransportRequestOptions extends DefaultOptions {
    studentId: number
}

export class TransportRequestResource {
    constructor() {}

    async getOwn(): Promise<TransportRequest[]> {
        return request(`/transport-requests/according`, getHttpOptions('GET'))
    }

    async getTransportRequestEvaluationLogs(requestId: number): Promise<TransportRequestEvaluationLogs> {
        return request('/transport-requests/evaluationLogs', {
            ...getHttpOptions('POST'),
            body: JSON.stringify({ requestId })
        })
    }

    async getAdditionalAttributes(): Promise<System[]> {
        return request('/transport-requests/additionalAttributes', getHttpOptions('GET'))
    }

    async saveFromDTO(dto: TransportRequestFormDTO, userId: BaseUserID, draftId?: number): Promise<boolean> {
        const saveDTO = convertFormDTOToSaveDTO(dto)
        const saved = await api.checkRequest.saveTransportRequest(saveDTO, draftId)
        if (saved) {
            const bankAccounts = await api.bankAccount.getMany({ id: 0, userId })
            if (bankAccounts && bankAccounts.length > 0) {
                const bank = bankAccounts.find((account) => account.active === true)
                bankAccount.set(bank)
            }
            if (draftId) await api.drafts.deleteEntity({ id: draftId })
        }
        return Boolean(saved)
    }
}
