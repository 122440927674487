import { IsEmail, IsNotEmpty, IsString, IsOptional, Matches, MinLength } from 'class-validator'
import { FormClass, FormField, InputType } from '@stadtlandnetz/forms'
import { SameAs } from '../../utils'
import { ApiHideProperty, ApiProperty } from '@nestjs/swagger'

@FormClass({ interfereLabels: true })
export class ResetPasswordDTO {
    @ApiProperty({ example: 'parent@via.de' })
    @FormField({ placeholder: 'max@mustermann.de', title: 'mail', oneliner: true })
    @IsEmail()
    email: string

    @ApiProperty({ example: '*****' })
    @FormField({ type: InputType.PASSWORD, oneliner: true })
    @MinLength(12)
    @IsNotEmpty()
    @Matches(/(?=.*\d)(?=.*[\W_])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z\d\W_]*/, {
        message: 'password must match regular expression',
    })
    password: string

    @FormField({ type: InputType.PASSWORD, title: 'password-confirm', oneliner: true })
    @ApiHideProperty()
    @IsOptional()
    @SameAs('password', { message: 'passwordConfirm must be the same as your password' })
    passwordConfirm?: string

    @ApiProperty({ example: '123456' })
    @FormField({ type: InputType.PIN, oneliner: true })
    @IsString()
    @Matches(/[0-9a-fA-F]{6}/, { message: 'value must be otp' })
    oneTimePin: string

    constructor(init?: Partial<ResetPasswordDTO>) {
        Object.assign(this, init)
    }
}
