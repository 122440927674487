import { Column, Entity, OneToMany, OneToOne, PrimaryGeneratedColumn } from 'typeorm'
import { UserDashboards, UserMeta, UserTodo, UserWindow } from '.'
import { News, NewsRights } from '../../news'
import { Tour } from '../../tours/entities'
import { ReasonabilityReview } from '../../transport-request'

@Entity('user')
export class User {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @Column('varchar', { name: 'name', nullable: false, length: 255 })
  name: string

  @Column('varchar', { name: 'pass', nullable: false, length: 255 })
  password: string | null

  @OneToOne(type => UserMeta, userMeta => userMeta.user, {
    eager: true
  })
  meta: UserMeta | null

  @OneToOne(
    type => UserDashboards,
    userDashboards => userDashboards.user
  )
  dashboards: UserDashboards | null

  @OneToMany(type => UserWindow, window => window.user)
  windows: UserWindow[]

  @OneToMany(type => UserTodo, userTodo => userTodo.user)
  todos: UserTodo[]

  @OneToMany(type => ReasonabilityReview, review => review.doneBy)
  reasonabilityReviews: ReasonabilityReview[]

  @OneToMany(type => Tour, tour => tour.clerk)
  tours: Tour[]

  @OneToMany(type => News, news => news.author)
  news: News[]

  @OneToMany(type => NewsRights, newsRights => newsRights.user)
  newsRights: NewsRights[]

  @Column('tinyint', { name: 'removed', width: 1, default: false })
  removed: boolean

  constructor(init?: Partial<User>) {
    Object.assign(this, init)
  }
}
