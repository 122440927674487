import { Student } from '@stadtlandnetz/core'
import { differenceInYears } from 'date-fns'

// Return the text for the options that are shown in the `Preselect` components
export function studentPreselectText(student: Student): string {
    return `${student.firstName} ${student.lastName}, ${differenceInYears(
        new Date(),
        new Date(student.birthday)
    )} Jahre`
}

export function checkIfAgeUnder18(birthday: Date | string): boolean {
    try {
        if (!birthday) return false
        const today = new Date()
        const birthdate = new Date(birthday)
        let age = today.getFullYear() - birthdate.getFullYear()
        const monthsDifference = today.getMonth() - birthdate.getMonth()
        const dayDifference = today.getDate() - birthdate.getDate()

        if (monthsDifference < 0 || (monthsDifference === 0 && dayDifference < 0)) {
            age--
        }
        // To catch invalid dates like 0001-01-01
        return age < 18 || birthdate.getFullYear() < 1950
    } catch (e) {
        console.error(e)
        return false
    }
}
