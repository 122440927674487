import { BankAccount, TransportRequestPageConfiguration } from '@stadtlandnetz/core'
import { SectionName } from '@stadtlandnetz/forms'

export function isBankAccountSectionEnabled(pageConfiguration: TransportRequestPageConfiguration): boolean {
    return Object.values(pageConfiguration).some((dto) =>
        dto.some((section) => section.name === SectionName.BANKACCOUNT && section.enabled)
    )
}

export function bankAccountIsEmpty(bankAccount: BankAccount): boolean {
    return (
        bankAccount &&
        (bankAccount.iban === '' || bankAccount.iban === null) &&
        (bankAccount.bank === '' || bankAccount.bank === null) &&
        (bankAccount.owner === '' || bankAccount.owner === null) &&
        (bankAccount.bic === '' || bankAccount.bic === null)
    )
}
