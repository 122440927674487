import {ApiProperty} from "@nestjs/swagger";

export class SystemSmall {
    @ApiProperty()
    id: number

    // Corresponds to the value property of system
    @ApiProperty()
    name: string

    constructor(init: SystemSmall) {
        Object.assign(this, init)
    }
}
