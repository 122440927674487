import {  Column, Entity, PrimaryGeneratedColumn } from 'typeorm'

@Entity('tbl_shortcodes')
export class Shortcodes {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @Column('varchar', { name: 'shortcode', nullable: false, length: 255 })
  shortcode: string

  @Column('text', { name: 'content', nullable: true })
  content: string | null

  constructor (init?: Partial<Shortcodes>) {
    Object.assign(this, init)
  }
}
