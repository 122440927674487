import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { School } from './School.entity'

@Entity()
export class SchoolDistrict {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @ManyToOne(type => School, school => school.districts, {
        nullable: false,
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE'
    })
    @JoinColumn({ name: 'school_id' })
    school: School

    @Column('polygon', { nullable: true })
    district: string | null

    constructor(init?: Partial<SchoolDistrict>) {
        Object.assign(this, init)
    }
}