import {
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryGeneratedColumn
} from 'typeorm'
import {ApiProperty} from "@nestjs/swagger";
import {TransportRequest} from "./TransportRequest.entity";
import { Guardian } from '../../students';

@Entity('tbl_antraege_guardians')
export class TransportRequestGuardians {
    @ApiProperty({type: Number})
    @PrimaryGeneratedColumn({type: 'int', name: 'id', unsigned: true})
    id: number

    @ApiProperty()
    @JoinColumn({name: 'antragId'})
    @ManyToOne(type => TransportRequest, transportRequest => transportRequest.guardians, {
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE',
    })
    transportRequest: TransportRequest

    @ApiProperty()
    @JoinColumn({name: 'guardianId'})
    @ManyToOne(type => Guardian, {
        eager: true,
        onUpdate: 'CASCADE',
        onDelete: 'RESTRICT'
    })
    guardian: Guardian

    constructor(init?: Partial<TransportRequestGuardians>) {
        Object.assign(this, init)
    }
}