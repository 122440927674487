export enum Modules {
  Inquiry = 'abfragen',
  Addresses = 'adressen',
  Documents = 'dokumente',
  Functions = 'funktion',
  Stops = 'haltestellen',
  Invoices = 'rechnungen',
  Students = 'schueler',
  Schools = 'schulen',
  System = 'system',
  Tours = 'touren',
  TransportCompanies = 'unternehmen',
  VariableAreas = 'var_bereiche',
  Tenders = 'vergaben'
}
