import {  Column, Entity, PrimaryGeneratedColumn, ManyToOne, JoinColumn } from 'typeorm'
import { Tender } from '..'
import { TransportCompany } from '../../../companies/entities'

@Entity('vergaben_kfz_modul2')
export class TenderVehicleSet {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => Tender, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({name: 'vergabeid'})
  tender: Tender

  @ManyToOne(type => TransportCompany, {
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'SET NULL'
  })
  @JoinColumn({name: 'vuid'})
  company: TransportCompany

  @Column('int', { name: 'kapazitaet_sys_id' })
  kapazitaetSysId: number

  @Column('int', { name: 'anz_kfz' })
  anzKfz: number

  constructor (init?: Partial<TenderVehicleSet>) {
    Object.assign(this, init)
  }
}
