import { ApiProperty } from "@nestjs/swagger"
import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from "typeorm"
import { Guardian } from '../../../students'
import { Address } from "../../../utils"
import { VerificationState } from "../../types/VerificationState.enum"
import { BaseUser } from "../BaseUser.entity"

@Index(['user', 'address.street', 'address.houseNumber', 'address.postalCode', 'address.town', 'address.district'], { unique: true })
@Entity('verification_address_by_letter')
export class AddressByLetterVerification {
    @PrimaryGeneratedColumn()
    id: number

    @ManyToOne(type => BaseUser, user => user.addressByLetterVerification)
    user: BaseUser

    @ManyToOne(type => Guardian, guardian => guardian.addressByLetterVerification, {
        nullable: true,
        onUpdate: 'CASCADE',
        onDelete: 'RESTRICT'
    })
    @JoinColumn({ name: 'guardianId' })
    guardian: Guardian | null

    @Column(type => Address)
    address: Address

    @ApiProperty({ enum: VerificationState })
    @Column({ type: 'enum', enum: VerificationState, nullable: false, default: VerificationState.PENDING })
    state: VerificationState

    @Column({ type: 'varchar', length: 255, nullable: false })
    code: string

    // Will be used for calculating the expiration date

    @Column({ type: 'datetime', nullable: true })
    sentAt: Date | null


    @Column({ type: 'date', nullable: false })
    arrivesEarliest: Date


    @Column({ type: 'date', nullable: false })
    arrivesLatest: Date


    @Column({ type: 'datetime', nullable: true })
    verifiedAt: Date | null

    // Used in case the address is verified by an EWO api
    @Column({ name: 'api_answer', type: 'boolean', nullable: true, width: 1, default: false })
    apiAnswer: boolean

    constructor(init: Omit<Partial<AddressByLetterVerification>, 'id'>) { Object.assign(this, init) }
}