import { stringToEnumValue } from '../../utils'
import type { Connection } from 'typeorm'

/**
 * VIA currently supports three types of placeholders.
 *
 * - Text: Inserts user input into the query
 * - Date: Inserts the current date into the query
 * - Selection: Presents the user with a list of values which are either
 *    static or the result from another query.
 *
 * @example
 * textPlaceholder = "{Schulkürzel/0/%}"
 * datePlaceholder = "{Datum/1/}"
 * selection1 = "{Landkreis/2/%;ERZ;MSN;Z}"
 * selection2 = "{Schule/2/SELECT kürzel FROM schulen}"
 */
enum PlaceholderValueType {
  TEXT = 0,
  DATE = 1,
  SELECTION = 2
}

/**
 * A single placeholder of a report.
 *
 */
export class ReportPlaceholder {
  name: string
  type: PlaceholderValueType
  values: string[] = []
  valuesRaw: string
  selectedValue: string

  constructor (serialized: string) {
    let values = serialized.replace('}', '').replace('{', '').split('/')
    this.name = values[0]
    this.type = stringToEnumValue(PlaceholderValueType, values[1])
    this.valuesRaw = values[2]
  }

  evaluateValues = async (connection: Connection) => {
    switch (this.type) {
      case PlaceholderValueType.TEXT:
        this.values.push(this.valuesRaw)
        break

      case PlaceholderValueType.DATE:
        this.values = [new Date().toISOString().slice(0, 10)]
        break

      case PlaceholderValueType.SELECTION:
        if (this.valuesRaw.indexOf('SELECT') > -1) {
          // Run Query and get values from the database
          // let queryRunner = connection.createQueryRunner()
          // let result = await queryRunner.query(this.valuesRaw.replace(';', ' LIMIT 5;'))
          // await queryRunner.release()
          let result;
          try {
            result = await connection.query(this.valuesRaw)
          } catch (e) {
            console.log(e)
          }
          result.forEach(element => {
            const value: string = String(Object.values(element)[0])
            this.values.push(value)
          })
        } else {
          this.values = this.valuesRaw.split(';')
        }
        break
    }
    this.selectedValue = this.values[0]
  }
}
