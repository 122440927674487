import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { Tender } from '.'

@Entity('vergaben_meta')
export class TenderMeta {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => Tender, tender => tender.information, {
onUpdate: 'CASCADE',
onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'vergabeid' })
  tender: Tender

  @Column('varchar', { name: 'feldname' })
  key: string

  @Column('text', { name: 'value1' })
  value1: string

  @Column('text', { name: 'value2' })
  value2: string

  constructor (init?: Partial<TenderMeta>) {
    Object.assign(this, init)
  }
}
