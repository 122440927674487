import { Column } from 'typeorm'

/**
 * Needed so the clerk has the opportunity to manually adjust the results
 * because of his/her personal local knowledge
 */
export class ReasonabilityResult {
  // Positive values mean that everything is within the boundaries
  @Column('tinyint', { name: 'ergebnis_gef_hstweg', width: 1 })
  safety: boolean
  @Column('tinyint', { name: 'ergebnis_laenge_hstweg', width: 1 })
  distance: boolean
  @Column('tinyint', { name: 'ergebnis_startzeit', width: 1 })
  startingTime: boolean
  @Column('tinyint', { name: 'ergebnis_schulwegezeit', width: 1 })
  totalTime: boolean
  @Column('tinyint', { name: 'ergebnis_wartezeit', width: 1 })
  waitingTime: boolean
}

export class ReasonabilityNotes {
  @Column('varchar', { name: 'bemerkungen_gef_hstweg', length: 255 })
  safety: string

  @Column('varchar', { name: 'bemerkungen_laenge_hstweg', length: 255 })
  distance: string

  @Column('varchar', { name: 'bemerkungen_startzeit', length: 255 })
  startingTime: string

  @Column('varchar', { name: 'bemerkungen_schulwegezeit', length: 255 })
  totalTime: string

  @Column('varchar', { name: 'bemerkungen_wartezeit', length: 255 })
  waitingTime: string
}


