import {  Column, Entity, PrimaryGeneratedColumn } from 'typeorm'

@Entity('sd_rechnungen_nummern')
export class InvoiceNumber {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @Column('text', { name: 'jahr', nullable: true })
  year: string | null

  // will be deprecated
  /*
  @Column('int', { name: 'rechnungid', nullable: true })
  invoiceId: string | null
  */

  constructor (init?: Partial<InvoiceNumber>) {
    Object.assign(this, init)
  }
}
