import {
    CheckTransportRequestDTO,
    SaveTransportRequestDTO,
    TransportRequest,
    TransportRequestDraftID
} from '@stadtlandnetz/core'
import { apiBaseUrl, getHttpOptions } from '../stores/api.stores'
import { getEndpointUrl, request } from './api-resource.base'

export class CheckRequestResource {
    async checkTransportRequest(data: CheckTransportRequestDTO): Promise<Response> {
        const url = new URL('transport-requests/check', apiBaseUrl).toString()
        return fetch(url, {
            ...getHttpOptions('POST'),
            body: JSON.stringify(data)
        })
    }

    async saveTransportRequest(data: SaveTransportRequestDTO, draftId?: TransportRequestDraftID): Promise<TransportRequest> {
        const url = getEndpointUrl('/transport-requests/save', { draftId })
        return request(url, {
            ...getHttpOptions('POST'),
            body: JSON.stringify(data)
        })
    }
}
