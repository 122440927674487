import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { DocumentTemplate } from '.'
import type { SqlQuery } from '../../utils'
import type { PlaceholderSubType, PlaceholderType } from '../types'

@Entity('dok_textmarken')
export class Placeholder {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => DocumentTemplate, document => document.placeholders, {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
    nullable: false
  })
  @JoinColumn({name: 'dokid'})
  document: DocumentTemplate | null

  @Column('varchar', { name: 'name', nullable: false, length: 100 })
  name: string

  @Column('varchar', { name: 'beschreibung', nullable: false, length: 255, default: ''})
  description: string 

  @Column('mediumtext', { name: 'Ssql', nullable: false })
  selectSQL: SqlQuery

  // @Column({ name: 'art', nullable: false, type: 'enum', enum: PlaceholderType })
  @Column({ name: 'art', nullable: false, type: 'tinyint', width: 1 })
  type: PlaceholderType

  // @Column({ name: 'artid', nullable: true, type: 'enum', enum: PlaceholderSubType })
  @Column({ name: 'artid', nullable: true, type: 'tinyint', width: 1 })
  subtype: PlaceholderSubType

  @Column('mediumtext', { name: 'art_ausgefüllt', nullable: true })
  preFilledValued: string | null

  constructor (init?: Partial<Placeholder>) {
    Object.assign(this, init)
  }
}
