import {  Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { Stack } from '.'
import type { SqlQuery } from '../../utils'

@Index('id', ['id'], { unique: true })
@Entity('tbl_stapel_textmarken')
export class StackQueryPlaceholder {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @ManyToOne(type => Stack, stack => stack.queryPlaceholders,
   {
     onUpdate: 'CASCADE',
     onDelete: 'CASCADE'
   } )
  @JoinColumn({name: 'stapelid'})
  stack: Stack

  @Column('varchar', { name: 'bezeichnung', nullable: false, length: 100})
  name: string

  @Column('mediumtext', { name: 'beschreibung', nullable: true })
  description: string | null

  @Column('text', { name: 'selectquery', nullable: false })
  query: SqlQuery

  constructor (init?: Partial<StackQueryPlaceholder>) {
    Object.assign(this, init)
  }
}
