import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { Direction, TourTrips  } from '../../tours'
import { TripRequirement } from '.'

// Improve: Embed into `TripRequirement`?
@Entity('fahrten_zeiten')
export class TripTimes {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(
    () => TripRequirement,
    (tripRequirement) => tripRequirement.times,
    { onDelete: "CASCADE", onUpdate: "CASCADE" }
  )
  @JoinColumn([{ name: "fahrtid", referencedColumnName: "id" }])
  tripRequirement: TripRequirement

  @Column('tinyint',{ name: 'richtung'})
  richtung: Direction

  @Column('time', { name: 'mo_zeit', nullable: true })
  moZeit: string
  @Column('time', { name: 'di_zeit', nullable: true })
  diZeit: string
  @Column('time', { name: 'mi_zeit', nullable: true })
  miZeit: string
  @Column('time', { name: 'do_zeit', nullable: true })
  doZeit: string
  @Column('time', { name: 'fr_zeit', nullable: true })
  frZeit: string

  @ManyToOne(
    () => TourTrips,
    { onDelete: "SET NULL", onUpdate: "CASCADE", nullable: true }
  )
  @JoinColumn([{ name: "mo_fahrtid", referencedColumnName: "id" }])
  mondayTrip: TourTrips

  @ManyToOne(
    () => TourTrips,
    { onDelete: "SET NULL", onUpdate: "CASCADE", nullable: true }
  )
  @JoinColumn([{ name: "di_fahrtid", referencedColumnName: "id" }])
  tuesdayTrip: TourTrips

  @ManyToOne(
    () => TourTrips,
    { onDelete: "SET NULL", onUpdate: "CASCADE", nullable: true }
  )
  @JoinColumn([{ name: "mi_fahrtid", referencedColumnName: "id" }])
  wednesdayTrip: TourTrips

  @ManyToOne(
    () => TourTrips,
    { onDelete: "SET NULL", onUpdate: "CASCADE", nullable: true }
  )
  @JoinColumn([{ name: "do_fahrtid", referencedColumnName: "id" }])
  thursdayTrip: TourTrips


  @ManyToOne(
    () => TourTrips,
    { onDelete: "SET NULL", onUpdate: "CASCADE", nullable: true }
  )
  @JoinColumn([{ name: "fr_fahrtid", referencedColumnName: "id" }])
  fridayTrip: TourTrips

  constructor (init?: Partial<TripTimes>) {
    Object.assign(this, init)
  }
}
