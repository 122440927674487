import {  Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { Stop } from '../../transport/'
import type { MoveType } from '../../tours'
import { PublicTransportRelation } from '.'

@Index('id', ['id'], { unique: true })
@Entity('oepnv_relationen_einausstieg')
export class PublicTransportRelationStops {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @Column('tinyint',{ name: 'type', nullable: false })
  type: MoveType

  @ManyToOne(type => Stop, { 
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({name: "hstid"})
  stop: Stop

  @ManyToOne(type => PublicTransportRelation,relation => relation.stops, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({name: "relationsid"})
  relation: PublicTransportRelation

  constructor (init?: Partial<PublicTransportRelationStops>) {
    Object.assign(this, init)
  }
}
