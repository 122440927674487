import {
    Column,
    Entity, Index,
    OneToMany,
    PrimaryGeneratedColumn
} from 'typeorm'
import { TransportRequest } from '../../transport-request'
import { MovableFreeDay } from '../../schools/entities'
import { dateTransformer } from "../../utils"

export type SchoolYearID = number

@Entity('kalender_schuljahr')
export class SchoolYear {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: SchoolYearID

    @Index({ unique: true })
    @Column('varchar', { name: 'schuljahr_system', nullable: false, length: 25 })
    name: string

    @Column('date', {
        name: 'sj_start',
        nullable: true,
        transformer: dateTransformer
    })
    start: Date

    @Column('date', {
        name: 'sj_ende',
        nullable: true,
        transformer: dateTransformer
    })
    end: Date

    @Column('date', {
        name: 'sj_ende_mit_ferien',
        nullable: true,
        transformer: dateTransformer
    })
    endWithHolidays: Date

    @OneToMany(type => TransportRequest, request => request.schoolYear)
    requests: TransportRequest[]

    @OneToMany(type => MovableFreeDay, freeDay => freeDay.schoolYear)
    movableFreeDays: MovableFreeDay[]

    constructor(init?: Partial<SchoolYear>) {
        Object.assign(this, init)
    }
}
