import { registerDecorator, ValidationArguments, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'

export function IsAfter(property: string, validationOptions?: ValidationOptions) {
    return (object: any, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [property],
            validator: IsAfterConstraint,
        })
    }
}

@ValidatorConstraint({ name: 'IsAfter' })
export class IsAfterConstraint implements ValidatorConstraintInterface {
    validate(value: any, args: ValidationArguments) {
        const [relatedPropertyName] = args.constraints
        const relatedValue = (args.object as any)[relatedPropertyName]
        if (!relatedValue)
            return true
        const [h1, m1] = relatedValue.split(':').map(Number)
        const [h2, m2] = value.split(':').map(Number)
        return h1 < h2 || h1 === h2 && m1 < m2
    }
}