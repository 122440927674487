import {  Column, Entity, PrimaryGeneratedColumn, JoinColumn, ManyToOne } from 'typeorm'
import { School } from '.'
import type { SchoolWeekType } from '../types/SchoolEnums'

@Entity('schulen_wochen')
export class SchoolWeek {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(
    () => School,
    { onDelete: "CASCADE", onUpdate: "CASCADE",  nullable: false }
    )
  @JoinColumn([{ name: "schulid", referencedColumnName: "id" }])
  School: School

  @Column('int', { name: 'kw', nullable: false })
  calendarWeek: number

  @Column('int', { name: 'jahr', nullable: false })
  year: number

  @Column('tinyint',{ name: 'woche_rythmus' })
  type: SchoolWeekType

  constructor (init?: Partial<SchoolWeek>) {
    Object.assign(this, init)
  }
}
