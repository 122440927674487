import { ApiProperty } from '@nestjs/swagger'
import type { SingleInfoBox } from './infoBoxes.dto'

export class FrequentlyAskedQuestion {
    @ApiProperty({ example: '{"de": ".....", "en": "...."}' })
    question: SingleInfoBox

    @ApiProperty({ example: '{"de": ".....", "en": "...."}' })
    answer: SingleInfoBox
}

export class CategoryFrequentlyAskedQuestions {
    @ApiProperty({ example: '{"de": ".....", "en": "...."}' })
    categoryTitle: SingleInfoBox

    @ApiProperty({ type: () => FrequentlyAskedQuestion, isArray: true })
    questions: FrequentlyAskedQuestion[]
}
