import {
    Entity,
    JoinColumn,
    ManyToOne,
    PrimaryGeneratedColumn
} from 'typeorm'
import {Student} from '../../students/entities'
import {School, SchoolClass} from '../../schools/entities'
import {ApiProperty} from "@nestjs/swagger";
import {TransportRequest} from "./TransportRequest.entity";

@Entity('tbl_antraege_geschwister')
export class TransportRequestSiblings {
    @ApiProperty({type: Number})
    @PrimaryGeneratedColumn({type: 'int', name: 'id', unsigned: true})
    id: number

    @ApiProperty()
    @JoinColumn({name: 'antragId'})
    @ManyToOne(type => TransportRequest, transportRequest => transportRequest.siblings, {
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE',
    })
    transportRequest: TransportRequest

    @ApiProperty()
    @JoinColumn({name: 'studentId'})
    @ManyToOne(type => Student, {
        eager: true,
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE'
    })
    sibling: Student

    @ApiProperty()
    @JoinColumn({name: 'schulId'})
    @ManyToOne(type => School, {
        onUpdate: 'CASCADE',
        onDelete: 'RESTRICT'
    })
    school: School

    @ApiProperty()
    @JoinColumn({name: 'klassenId'})
    @ManyToOne(type => SchoolClass, {
        onUpdate: 'CASCADE',
        onDelete: 'RESTRICT'
    })
    class: SchoolClass

    constructor(init?: Partial<TransportRequestSiblings>) {
        Object.assign(this, init)
    }
}
