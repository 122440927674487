import {  Column, Entity, PrimaryGeneratedColumn } from 'typeorm'

@Entity('fahrer_meta')
export class DriverMeta {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

 /*  @OneToOne(type => Driver, driver => driver.meta, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'fahrer_id'})
  driver: Driver */

  @Column('varchar', { name: 'fahrer_password', length: 255 })
  password: string

  constructor (init?: Partial<DriverMeta>) {
    Object.assign(this, init)
  }
}
