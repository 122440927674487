import {  Column, Entity, PrimaryGeneratedColumn } from 'typeorm'
import type { CalenderMonth } from '../../utils'

@Entity('kalender')
export class Calendar {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  // @Column( { name: 'monat', nullable: false, type: 'enum', enum: CalenderMonth })
  @Column( { name: 'monat', nullable: false, type: 'varchar', length: 10 })
  month: CalenderMonth

  @Column('int', { name: 'jahr', width: 4, nullable: false })
  year: string

  @Column('tinyint', { name: 'tage', width: 2, nullable: false })
  days: number

  @Column('tinyint', { name: 'wochentage', width: 2, nullable: false })
  weekdays: number
  
  @Column('tinyint', { name: 'schultage', width: 2, nullable: false })
  schooldays: number

  constructor (init?: Partial<Calendar>) {
    Object.assign(this, init)
  }
}
