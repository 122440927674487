import { Column } from 'typeorm'

export class ConnectionMeasurements {
  // Measured in meters
  @Column('int', { name: 'ist_hstweg_wohnung_einstieg' })
  distanceFromHomeToEntryStop: number
  @Column('int', { name: 'ist_hstweg_ausstieg_schule' })
  distanceFromExitStopToSchool: number
  @Column('time', { name: 'ist_startzeitpunkt' })
  startingTimeAtHome: string

  // Measured in minutes
  @Column('int', { name: 'ist_schulwegezeit_wohnung_einstieg' })
  timeFromHomeToEntryStop: number
  @Column('int', { name: 'ist_schulwegezeit_einstieg_ausstieg' })
  timeFromEntryToExitStop: number
  @Column('int', { name: 'ist_schulwegezeit_ausstieg_schule' })
  timeFromExitStopToSchool: number

  // Can be more than the three attribute above added together
  @Column('int', { name: 'ist_schulwegezeit_gesamt' })
  totalTravelTime: number

  @Column('int', { name: 'ist_wartezeit' })
  waitingTimeUntilSchoolStart: number
}
