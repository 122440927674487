import { IsBoolean, IsNotEmpty, IsNumber } from 'class-validator'

export class TransportRequestInput {
  @IsNumber()
  student: number

  @IsNotEmpty()
  schoolYear: string

  @IsBoolean() @IsNotEmpty()
  hasPublicTransport: boolean

  @IsBoolean() @IsNotEmpty()
  hasIndividualStudentTransport: boolean
}