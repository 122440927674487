import {

  Column,
  Entity, JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn
} from 'typeorm'
import { System } from '../../system'

// Lars: Own ticket
@Entity('schulform')
export class SchoolForm {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => System, {
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({ name: 'bezeichnung' })
  name: System

  @Column('float', { name: 'mindestentfernung', precision: 12 })
  minimumDistance: number

  constructor (init?: Partial<SchoolForm>) {
    Object.assign(this, init)
  }
}
