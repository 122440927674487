import { SameAs } from '@stadtlandnetz/core'
import { FormClass, FormField, InputType } from '@stadtlandnetz/forms'
import { IsEmail, IsNotEmpty, Matches, MinLength } from 'class-validator'

@FormClass({ interfereLabels: true, defaultGroupName: 'Account Credentials' })
export class Account {
    @FormField({ type: InputType.EMAIL, title: 'new-mail', autocomplete: 'new-password' })
    @IsEmail()
    username: string

    @FormField({ type: InputType.EMAIL, title: 'mail-confirm', autocomplete: 'new-password' })
    @IsEmail()
    @SameAs('username', { message: 'confirmUsername must be the same as your new email' })
    confirmUsername: string

    // Used in change email form, different label than currentPassword
    @FormField({ type: InputType.PASSWORD, title: 'password', autocomplete: 'on' })
    @IsNotEmpty()
    password: string

    @FormField({ type: InputType.PASSWORD, title: 'currentPassword', autocomplete: 'on' })
    @IsNotEmpty()
    currentPassword: string

    @FormField({ type: InputType.PASSWORD, title: 'new-password' })
    @IsNotEmpty()
    @MinLength(12)
    @Matches(/(?=.*\d)(?=.*[\W_])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z\d\W_]*/, {
        message: 'password must match regular expression'
    })
    newPassword: string

    @FormField({ type: InputType.PASSWORD, title: 'new-password-confirm' })
    @IsNotEmpty()
    @SameAs('newPassword', { message: 'passwordConfirm must be the same as your password' })
    passwordConfirm: string

    constructor(init?: Partial<Account>) {
        Object.assign(this, init)
    }
}
