import { ApiProperty } from "@nestjs/swagger";
import { IsEnum } from "class-validator";
import { TransportRequestType } from "../../transport-request";
import type { SingleInfoBox } from "./infoBoxes.dto";

export enum EvaluationResult {
    POSITIVE = 'positive', // Transport claim can be granted
    NEGATIVE = 'negative' // Request is more likely to be rejected
}

export type EvaluationResultContent = {
    [key in EvaluationResult]: SingleInfoBox
}

export class SingleEvaluationResultText {
    @ApiProperty({
        example: '{"positive": {"de": ".....", "en": "...."}, "negative": {"de": ".....", "en": "...."}}'
    })
    title: EvaluationResultContent

    @ApiProperty({
        example: '{"positive": {"de": ".....", "en": "...."}, "negative": {"de": ".....", "en": "...."}}'
    })
    text: EvaluationResultContent

    @ApiProperty({
        example: '{"positive": {"de": ".....", "en": "...."}, "negative": {"de": ".....", "en": "...."}}'
    })
    note: EvaluationResultContent
}

export type EvaluationResultTextDto = {
    [key in TransportRequestType]?: SingleEvaluationResultText
}

export class EvaluationResultTextQuery {
    @IsEnum(TransportRequestType)
    transportRequestType: TransportRequestType
}