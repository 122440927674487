import { ApiProperty } from '@nestjs/swagger'
import { Column } from 'typeorm'

export const literalFloatTransformer = {
    from: (raw: string | null) => {
        if (raw == null) return null
        return Number.parseFloat(raw)
    },
    to: (value: number | null) => {
        if (value == null) return null
        return value.toString()
    }
}


export class Coordinates {
    @ApiProperty({ example: 51.0804316 })
    @Column('varchar', { name: 'latitude', length: 255, transformer: literalFloatTransformer })
    latitude: number

    @ApiProperty({ example: 13.761510766486476 })
    @Column('varchar', { name: 'longitude', length: 255, transformer: literalFloatTransformer })
    longitude: number

    constructor(init?: Coordinates) {
        Object.assign(this, init)
    }
}

/**
 * Alternative variation found in the database
 */
// export class CoordinatesShort extends Coordinates {
export class CoordinatesShort {
    @Column('text', { name: 'lat', nullable: true, transformer: literalFloatTransformer })
    latitude: number | null

    @Column('text', { name: 'lng', nullable: true, transformer: literalFloatTransformer })
    longitude: number | null

    constructor(init?: Partial<CoordinatesShort>) {
        Object.assign(this, init)
    }
}


export class CoordinatesPrefixed {
    @Column('text', { name: 'addr_lat', nullable: true, transformer: literalFloatTransformer })
    latitude: string | null

    @Column('text', { name: 'addr_lng', nullable: true, transformer: literalFloatTransformer })
    longitude: string | null

    constructor(init?: Partial<CoordinatesPrefixed>) {
        Object.assign(this, init)
    }
}

export class RequiredCoordinates {
  @Column('varchar', { name: 'addr_lat', length: 255, nullable: false, transformer: literalFloatTransformer })
  latitude: string | null

  @Column('varchar', { name: 'addr_lng', length: 255, nullable: false, transformer: literalFloatTransformer })
  longitude: string | null

  constructor (init?: Partial<RequiredCoordinates>) {
    Object.assign(this, init)
  }
}
