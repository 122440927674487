import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { RestrictedArea } from './RestrictedArea.entity'
import type { Coordinates } from '../../utils'

@Entity('restricted_areas_polygons')
export class RestrictedAreaPolygon {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @ManyToOne((type) => RestrictedArea, (restrictedArea: RestrictedArea) => restrictedArea.polygons, {
        nullable: false,
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE',
    })
    @JoinColumn({ name: 'restrictedAreaId' })
    restrictedArea: RestrictedArea

    @Column('polygon', { nullable: false })
    polygon: string

    constructor(init?: Partial<RestrictedAreaPolygon>) {
        Object.assign(this, init)
    }
}
