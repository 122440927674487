// Creates NxN PNG icon from given svg, png or jpeg icon
export async function formatIcon(iconBase64: string, size = 32): Promise<string>  {
    let [_, width, height]: number[] = iconBase64
        .match(/data:image\/svg\+xml.*width=.(\d+).*height=.(\d+)/)
        ?.map(match => parseInt(match)) ?? []
    if (!width) {
        // Given icon is not svg, load the image to get size
        if (iconBase64.match(/data:image\/(png|jpeg)/)) {
            [width, height] = await new Promise<number[]>((resolve) => {
                const img = new Image()
                img.onload = () => resolve([img.width, img.height])
                img.src = iconBase64
            })
        } else {
            return ''
        }
    }
    const canvas = document.createElement('canvas')
    canvas.width = canvas.height = size
    const scaleX = width < height ? size / height : 1
    const scaleY = width > height ? size / width : 1
    const offsetX = width > height ? 0 : (size - width * scaleX) / 2
    const offsetY = width < height ? 0 : (size - height * scaleY) / 2
    const widthScaled = width < height ? width * scaleX : size
    const heightScaled = width > height ? height * scaleY : size
    const ctx = canvas.getContext('2d')
    await new Promise<void>((resolve) => {
        const img = new Image()
        img.onload = () => resolve(ctx.drawImage(img, offsetX, offsetY, widthScaled, heightScaled))
        img.src = iconBase64
    })
    return canvas.toDataURL('image/png')
}

// Creates NxN ICO icon from given svg, png or jpeg icon
export async function toIco(svgBase64: string, size = 32): Promise<string> {
    const pngDataURL = await formatIcon(svgBase64, size)
    if (!pngDataURL) {
        return ''
    }
    const pngData = atob(pngDataURL.slice(22))                  // Decode base64 data
    if (                                                        // Make sure PNG is less than 256x256
        pngData.charCodeAt(15+1) || pngData.charCodeAt(15+2) || pngData.charCodeAt(15+3) || 
        pngData.charCodeAt(15+5) || pngData.charCodeAt(15+6) || pngData.charCodeAt(15+7)
    ) {
        return
    }
    let icoFile = "\x00\x00\x01\x00\x01\x00"                    // First 6 bytes are constant
    icoFile += pngData[15+4]                                    // PNG width byte
    icoFile += pngData[15+8]                                    // PNG height byte
    icoFile += "\x00\x00\x01\x00\x18\x00"                       // Add more (probably constant) information
    let lenBytes = pngData.length                               // Add encoded length
    for (let i = 0; i < 4; i++) {
        icoFile += String.fromCharCode(lenBytes % 256)
        lenBytes >>= 4
    }
    icoFile += "\x16\x00\x00\x00"                               // We had 0x16 bytes before now
    icoFile += pngData                                          // Add the png data
    return `data:image/x-icon;base64,${window.btoa(icoFile)}`   // Return base64 encoded ico file
}