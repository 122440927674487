import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { TenderBid } from '.'

// ranking gets calculated by various criteria and can be manipulated by user
@Entity('vergaben_rangfolge')
export class TenderRankingOrder {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => TenderBid, tenderbid => tenderbid.rankingOrders, { 
    nullable: false,
   onUpdate: 'CASCADE',
onDelete: 'CASCADE' 
  })
  @JoinColumn({ name: 'angebotid'})
  tenderbid: TenderBid

  @Column('int', { name: 'rang' })
  rang: number

  constructor (init?: Partial<TenderRankingOrder>) {
    Object.assign(this, init)
  }
}
