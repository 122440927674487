import {
    Address,
    BankAccount,
    displayAsKilometer,
    EvaluationSummary,
    GenericRoute,
    getTransportRequestType,
    Guardian,
    RequestNearestSchool,
    SaveTransportRequestDTO,
    SchoolClassID,
    SchoolTypeRelation,
    SchoolWish,
    Sibling,
    Student,
    SystemID,
    TransportRequest,
    TransportRequestDraft,
    TransportRequestDraftData,
    TransportRequestEvaluationLogs,
    TransportRequestState,
    YesNoPreselect
} from "@stadtlandnetz/core";
import type { SchoolSearchResult } from '@stadtlandnetz/core';
import { api } from "../services/api.service";
import { guardians, savedFiles, students } from "../stores/data.stores";
import { get } from "svelte/store";
import { stateMappingIncludesStatus } from "../helpers";

export interface TransportRequestFormDTO extends Omit<SaveTransportRequestDTO, 'siblings' | 'guardians'> {
    siblings: YesNoPreselect<Sibling>
    guardians: YesNoPreselect<Guardian>
}

export type TransportRequestDTOEvent = CustomEvent<TransportRequestFormDTO>
export type SaveDraftAndNavigateEvent = CustomEvent<{ dto: TransportRequestFormDTO, section: string | undefined, navigate: boolean | undefined, showSnackbar: boolean | undefined, }>

export type SchoolSearchAndRouteResult = { school: SchoolSearchResult, route: GenericRoute }

function getCachedEvaluationSummary(requestLogs: TransportRequestEvaluationLogs): EvaluationSummary {
    const transportClaim = stateMappingIncludesStatus(requestLogs.resultNeed, TransportRequestState.PROBABLY_REJECTED) || 
        stateMappingIncludesStatus(requestLogs.resultNeed, TransportRequestState.REJECTED)
    stateMappingIncludesStatus(requestLogs.resultNeed, TransportRequestState.SELF_PAY) ? false : true
    return new EvaluationSummary({
        transportClaim,
        //@ts-ignore
        status: requestLogs.resultNeed,
        minimumDistance: {
            minimumDistanceFulfilled: requestLogs.minimumDistanceFulfilled,
            minimumDistance: '',
            distanceToChosenSchool: displayAsKilometer(requestLogs.distanceToChosenSchool)
        },
        nearestSchool: requestLogs.isNearestSchool != null ? {
            distanceToNearestSchool: '',
            isNearestSchool: requestLogs.isNearestSchool,
            nearestSchoolId: null,
            nearestSchoolName: '',
            nearestSchools: requestLogs.nearestSchools
        } : null
    })
}

export async function convertTransportRequestToFormDTO(transportRequest: TransportRequest, isModificationRequest: boolean = false):
    Promise<TransportRequestFormDTO> {
    savedFiles.set([])
    const allGuardians = get(guardians) as Guardian[]
    transportRequest = {
        ...transportRequest,
        evaluationLogs: await api.transportRequests.getTransportRequestEvaluationLogs(transportRequest.id)
    }
    let classID: SchoolClassID | null = null

    if (transportRequest.visitedSchoolId && transportRequest.class) {
        const classesOfSchool = await api.schoolClasses.getBySchool(transportRequest.visitedSchoolId)
        classID = classesOfSchool.find(schoolClass => schoolClass.name == transportRequest.class)?.id
    }

    const formDTO = {
        additionalAttributes: transportRequest.attributes.map(attribute => attribute.system.id),
        address: transportRequest.address,
        bankAccount: transportRequest.bankAccount ?? new BankAccount({}),
        guardians: transportRequest.guardians.length === 0 ? allGuardians : transportRequest.guardians.map((requestGuardian) => requestGuardian.guardian),
        schoolWish: new SchoolWish({
            type: transportRequest.visitedSchool.types.find(type => type.type === SchoolTypeRelation.TYPE).systemId,
            form: transportRequest.formId,
            class: classID,
            profile: transportRequest.profileId,
            school: transportRequest.visitedSchoolId,
            year: transportRequest.schoolYearId,
        }),
        siblings: transportRequest.siblings.map(sibling => new Sibling({
            studentId: sibling.sibling.id,
            firstName: sibling.sibling.firstName,
            lastName: sibling.sibling.lastName,
            birthday: sibling.sibling.birthday,
            school: sibling.school,
            class: sibling.class
        })),
        student: transportRequest.student,
        type: getTransportRequestType(transportRequest),
        orderDate: {
            orderDate: transportRequest.orderDate,
            selectedDate: transportRequest.orderDate
        },
        files: transportRequest.files ?? [],
        notes: {
            notes: transportRequest.notes
        },
        routeToSchool: transportRequest.evaluationLogs?.routeToSchool,
        summary: getCachedEvaluationSummary(transportRequest.evaluationLogs),
        requestVariableFields: transportRequest.variableFieldsRequest?.[0],
        refundVariableFields: transportRequest.variableFieldsRefund?.[0],
        parentTransportRequestId: isModificationRequest ? transportRequest.id : null,
    }
    return formDTO
}

export function convertFormDTOToSaveDTO(dto: TransportRequestFormDTO): SaveTransportRequestDTO {
    return {
        ...dto,
        guardians: Array.isArray(dto.guardians) ? dto.guardians : [],
        siblings: Array.isArray(dto.siblings) ? dto.siblings : [],
    }
}

export function convertDraftToFormDTO(draft: TransportRequestDraft): TransportRequestFormDTO {
    savedFiles.set([])
    const { user, request: data } = draft
    const accountStudents = get(students) as Student[]

    function findType(key: SchoolTypeRelation): SystemID | undefined {
        return data.visitedSchool?.types?.find(type => type.key == key)?.id
    }

    const formDTO = {
        additionalAttributes: data.additionalAttributes,
        address: data.address ?? new Address(),
        bankAccount: data.bankAccount,
        guardians: data.guardians ?? [],
        schoolWish: new SchoolWish({
            school: data.visitedSchool?.school,
            type: findType(SchoolTypeRelation.TYPE),
            form: findType(SchoolTypeRelation.FORM),
            profile: findType(SchoolTypeRelation.PROFILE),
            class: data.visitedSchool?.class,
            year: data.schoolYear?.id
        }),
        siblings: data.siblings,
        student:
            (data.student &&
                accountStudents.find(
                    (student) =>
                        student.id === data.student?.id ||
                        (student.firstName?.toUpperCase() === data.student.firstName?.toUpperCase() &&
                            student.lastName?.toUpperCase() === data.student.lastName?.toUpperCase())
                )) ??
            data.student ??
            new Student(),
        type: data.type,
        orderDate: data.orderDate,
        lastSection: data.lastSection,
        files: draft.files ?? [],
        notes: data.notes,
        requestVariableFields: data.requestVariableFields,
        refundVariableFields: data.refundVariableFields,
        parentTransportRequestId: data.parentTransportRequestId,
        passportImage: data.passportImage
    }
    return formDTO
}

export function convertFormDTOtoDraft(transportRequestDto: Partial<TransportRequestFormDTO>): TransportRequestDraftData {
    const draftData: TransportRequestDraftData = {} as TransportRequestDraftData
    if (transportRequestDto.student)
        draftData.student = transportRequestDto.student
    if (transportRequestDto.guardians && transportRequestDto.guardians.length > 0)
        draftData.guardians = transportRequestDto.guardians
    if (transportRequestDto.additionalAttributes && transportRequestDto.additionalAttributes.length > 0)
        draftData.additionalAttributes = transportRequestDto.additionalAttributes
    if (transportRequestDto.address) draftData.address = transportRequestDto.address

    draftData.siblings = transportRequestDto.siblings
    const { schoolWish } = transportRequestDto

    if (schoolWish.year) {
        draftData.schoolYear = { id: transportRequestDto.schoolWish.year }
    }
    if (schoolWish.type) {
        // @ts-ignore
        draftData.visitedSchool = {}
        draftData.visitedSchool.types = [{
            key: SchoolTypeRelation.TYPE,
            id: schoolWish.type
        }]
    }
    if (schoolWish.form) {
        draftData.visitedSchool.types.push({
            key: SchoolTypeRelation.FORM,
            id: schoolWish.form
        })
    }
    if (schoolWish.profile) {
        draftData.visitedSchool.types.push({
            key: SchoolTypeRelation.PROFILE,
            id: schoolWish.profile
        })
    }
    if (schoolWish.class) {
        draftData.visitedSchool.class = schoolWish.class
    }
    if (schoolWish.school) {
        draftData.visitedSchool.school = schoolWish.school
    }
    if (transportRequestDto.bankAccount) draftData.bankAccount = transportRequestDto.bankAccount
    if (transportRequestDto.type)
        draftData.type = transportRequestDto.type
    if (transportRequestDto.orderDate) draftData.orderDate = transportRequestDto.orderDate
    if (transportRequestDto.lastSection) draftData.lastSection = transportRequestDto.lastSection
    if (transportRequestDto.notes) draftData.notes = transportRequestDto.notes
    if (transportRequestDto.requestVariableFields) draftData.requestVariableFields = transportRequestDto.requestVariableFields
    if (transportRequestDto.refundVariableFields) draftData.refundVariableFields = transportRequestDto.refundVariableFields
    if (transportRequestDto.parentTransportRequestId) draftData.parentTransportRequestId = transportRequestDto.parentTransportRequestId
    if (transportRequestDto.passportImage) draftData.passportImage = transportRequestDto.passportImage

    // @ts-ignore
    draftData.changed = new Date()

    return draftData
}

export function convertSchoolsResult(schoolsByAirlineDBResult: RequestNearestSchool[]): SchoolSearchAndRouteResult[] {
    return schoolsByAirlineDBResult?.map(schoolEntry => {
        return {
            school: {
                id: schoolEntry.school.id,
                schoolTypeId: '',
                name: schoolEntry.school.name,
                types: [],
                address: {
                    street: schoolEntry.school.street,
                    houseNumber: schoolEntry.school.houseNumber.toString(),
                    postalCode: schoolEntry.school.postalCode.toString(),
                    town: schoolEntry.school.city,
                }
            },
            route: schoolEntry.route
        }
    })
}