import { JWTPayload, Role } from '@stadtlandnetz/core'

export function parseJwt(token: string): JWTPayload {
    const parsed = JSON.parse(atob(token.split('.')[1]))
    return {
        username: parsed.username,
        userId: parsed.sub,
        roles: parsed.roles,
        iat: new Date(parsed.iat * 1000),
        exp: new Date(parsed.exp * 1000)
    }
}

export function isAdmin(jwt: JWTPayload): boolean {
    return !!jwt && jwt.roles.includes(Role.Admin)
}
