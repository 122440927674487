export enum Language {
    GERMAN = 'de',
    ENGLISH = 'en',
    UKRAINIAN = 'uk',
    RUSSIAN = 'ru',
    ROMANIAN = 'ro',
    ARABIC = 'ar'
}

export const LANGUAGE_MAP: Record<Language, string> = {
    [Language.GERMAN]: 'Deutsch',
    [Language.ENGLISH]: 'English',
    [Language.UKRAINIAN]: 'Українська',
    [Language.RUSSIAN]: 'Русский',
    [Language.ROMANIAN]: 'Română',
    [Language.ARABIC]: 'العربية'
}