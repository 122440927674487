import { ApiHideProperty } from '@nestjs/swagger'
import type { TransportCompanyMember } from '../../companies/entities'
import type { Guardian } from '../../students'
import type { Language } from '../../system'
import type { BankAccount } from '../entities'
import type { BundIDStatus } from './BundID.types'

export class LoginResponse {
    access_token: string
    expiration: string
    refresh_token: string
    baseUserId: number
    guardian: Guardian
    isSelfApplicant: boolean
    @ApiHideProperty()
    companyMember?: TransportCompanyMember
    @ApiHideProperty()
    bankAccount: BankAccount
    bundID?: BundIDStatus
    language?: Language
}
