import { FileDestinationFolder } from '@stadtlandnetz/core'
import { apiBaseUrl, getHttpOptions, token } from '../stores/api.stores'
import { get } from 'svelte/store'

export class ExpressMulterFile {
    fileName: string
    data: Blob
}

export function formatFilesForUpload(filesArray): ExpressMulterFile[] {
    const filesToUpload: ExpressMulterFile[] = []
    filesArray.forEach((file) => {
        filesToUpload.push({
            fileName: file.name,
            data: new Blob([new Uint8Array(file.buffer)])
        })
    })
    return filesToUpload
}

export class UploadResource {
    async uploadFiles(
        entityType: FileDestinationFolder,
        entityId: number,
        files: ExpressMulterFile[]
    ): Promise<Response> {
        const fd = new FormData()
        files.forEach((file) => {
            fd.append('files', file.data, file.fileName)
        })
        return fetch(new URL(`transport-requests/upload/${entityType}/${entityId}`, apiBaseUrl), {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${get(token)}`
            },
            credentials: 'include',
            body: fd
        })
    }

    async deleteUploadedFile(filePath: string): Promise<Response> {
        return fetch(new URL('transport-requests/deleteFile', apiBaseUrl).toString(), {
            ...getHttpOptions('POST'),
            body: JSON.stringify({ filePath })
        })
    }
}
