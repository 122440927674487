import {  Column, Entity, Index, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import { TripPlan } from '../types/TripPlan'
import {ValidTimespanGerman, VariableFields} from '../../utils'
import { TripStopReturn, TripStopTo } from '../types/TripStop'
import { Tour, WaypointStudent, WaypointType } from '../../tours'
import { TransportRequest } from '../../transport-request'
import { TripTimes } from '.'
import { Student } from '../../students'
import { School} from '../../schools/entities'
import type { SchoolWeekType } from '../../schools'

// TODO: Nick -  can we remove that id_2 index?
@Index('id_2', ['id'], { unique: true })
@Index('id', ['id'], {})
@Entity('fahrten')
export class TripRequirement {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => Student, student => student.requirements, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE' 
  })
  @JoinColumn({ name: 'snr', referencedColumnName: 'studentNumber'})
  student: Student

  @ManyToOne(type => Tour, tour => tour.requirements, {
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({ name: 'tour', referencedColumnName: 'name'})
  tour: Tour

  @ManyToOne(type => School, school => school.requirements, {
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({ name: 'schule', referencedColumnName: 'short'})
  school: School

  @ManyToOne(type => TransportRequest, transportRequest => transportRequest.requirements, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE' 
  })
  @JoinColumn({ name: 'antragid'})
  request: TransportRequest

  @Column({ name: 'woche', type: 'tinyint', width: 1, nullable: true })
  // @Column({ name: 'woche', type: 'enum', enum: SchoolWeekType, nullable: true })
  week: SchoolWeekType

  // Free text
  @Index()
  @Column('varchar', { name: 'einstieg', nullable: true, length: 255})
  entrance: string | null
  @Index()
  @Column('varchar', { name: 'ausstieg', nullable: true, length: 255 })
  exit: string | null
  @Column('varchar', { name: 'klasse', nullable: true, length: 100 })
  class: string | null

  @Column(type => ValidTimespanGerman)
  valid: ValidTimespanGerman

  @Column(type => TripPlan)
  plan: TripPlan

  // not in use
  /*
  @Column('mediumtext', { name: 'row', nullable: true })
  row: string | null
  */

  @Column('mediumtext', { name: 'bemerkungen', nullable: true })
  notes: string | null

  @Column(type => TripStopTo )
  stopTo: TripStopTo

  @Column(type => TripStopReturn)
  stopReturn: TripStopReturn

  @OneToMany(type => TripTimes, (tripTime) => tripTime.tripRequirement)
  times: TripTimes[]

  @Column(type => VariableFields)
  variableFields: VariableFields

  constructor (init?: Partial<TripRequirement>) {
    Object.assign(this, init)
  }
}
