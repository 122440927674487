import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { TourFinances } from '.'

//relates between finances and cost centre
@Entity('touren_finanzen_kostenstellen')
export class TourCostCentre {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => TourFinances, finances => finances.costcentres, {
    nullable: false,
 onUpdate: 'CASCADE',
onDelete: 'CASCADE' 
  })
  @JoinColumn({ name: 'touren_finanzen_id' })
  finance: TourFinances

  @Column('varchar', { name: 'kostenstelle', nullable: false, length: 255 })
  costCentre: string

  @Column('int', { name: 'anteil_prozent', nullable: false})
  percentage: number

  constructor (init?: Partial<TourCostCentre>) {
    Object.assign(this, init)
  }
}
