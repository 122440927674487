import {  Column, Entity, JoinColumn, OneToOne, PrimaryGeneratedColumn } from 'typeorm'
import { User } from '.'

// Improve: Replace this table by a classical Many-To-Many relation between user and widgets
@Entity('user_dashboards')
export class UserDashboards {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @OneToOne(type => User, user => user.dashboards, {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
    nullable: false
  })
  @JoinColumn({ name: 'userid' })
  user: User

  @Column('json', { name: 'widgets', nullable: true })
  widgets: object | null

  constructor (init?: Partial<UserDashboards>) {
    Object.assign(this, init)
  }
}
