import { IsArray, ValidateNested } from "class-validator";
import type { Student } from "../../students";
import type { TransportCompanyMember } from "../../companies";
import type { Shipment } from "../entities";
import type { Coordinates } from "../../utils";

export interface TourTripStudent extends Partial<Student> {
    needsWheelchair: boolean
}

export interface TourTripWaypoint extends Omit<Partial<Shipment>, 'students'> {
    students?: TourTripStudent[] | null
    companions?: TransportCompanyMember[] | null
}

export type StartOrEndTrip = {
    address: string
    coordinates: Coordinates
}

export class TourTripDetailsDto {
    startTrip: StartOrEndTrip
    
    endTrip: StartOrEndTrip

    @IsArray()
    @ValidateNested({each: true})
    waypoints: TourTripWaypoint[]

    constructor (init?: Partial<TourTripDetailsDto>) {
        Object.assign(this, init)
    }
}