import { ApiProperty } from "@nestjs/swagger"
import { IsArray, IsNotEmpty } from "class-validator"
import { Type } from 'class-transformer'

export class TimetableRequestDTO {
    @ApiProperty()
    @IsNotEmpty()
    @IsArray()
    @Type(() => Number)
    requestIds: number[]

    @ApiProperty()
    @IsNotEmpty()
    @Type(() => Date)
    startDate: Date

    @ApiProperty()
    @IsNotEmpty()
    @Type(() => Date)
    endDate: Date
}