import { ApiProperty } from '@nestjs/swagger'
import { IsBoolean, IsDateString, IsString } from 'class-validator'

export class SchoolOneTimePinResponseDTO {
    @ApiProperty()
    @IsString()
    schoolName: string

    @ApiProperty()
    @IsString()
    schoolEmail: string

    @ApiProperty()
    @IsDateString()
    resendAt: Date

    @ApiProperty()
    @IsBoolean()
    existingPin: boolean
}
