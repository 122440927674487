export function* combinations(arrOfArr) {
    let [head, ...tail] = arrOfArr
    let remainder = tail.length ? combinations(tail) : [[]]
    for (let r of remainder) for (let h of head) yield [h, ...r]
}

export function zip(...arrays) {
    const length = Math.min(...arrays.map(arr => arr.length));
    return Array.from({ length }, (value, index) => arrays.map((array => array[index])));
}

export function pairwise<T>(arr: T[]): [T, T][] {
    const result = []
    for (let i = 0; i < arr.length - 1; i++)
        result.push([arr[i], arr[i + 1]])
    return result
}
