import { School, SchoolEntrance } from '../../schools/entities'
import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { TransportRequest } from '.'
import { SchoolEnum } from '../types'

@Entity('tbl_antraege_schulen')
export class TransportRequestNearestSchools {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => TransportRequest, request => request.closestSchools, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'antragID' })
  request: TransportRequest

  @ManyToOne(type => School, school => school.closestSchools, {
    nullable: false,
    onUpdate: 'CASCADE'
  })
  @JoinColumn({ name: 'schuleID' })
  school: School

  @ManyToOne(type => SchoolEntrance, {
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'entrance_foot_id' })
  entranceByFoot: SchoolEntrance

  @ManyToOne(type => SchoolEntrance, {
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'entrance_car_id' })
  entranceByCar: SchoolEntrance

  @Column({type: "enum", enum: SchoolEnum, nullable: false})
  type: SchoolEnum

  @Column('double', {
    name: 'distFoot',
    nullable: true,
    default: null
  })
  distanceByFoot: number | null

  @Column('double', {
    name: 'distCar',
    nullable: true,
    default: null
  })
  distanceByCar: number | null

  constructor (init?: Partial<TransportRequestNearestSchools>) {
    Object.assign(this, init)
  }
}
