import {  Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { System } from '../../system'
import { School } from '.'

/**
 * Manual entries for the closest school of a certain type on a town-to-town basis.
 * Here, the clerk can manual enter school which have the cheapest travel route for a
 * certain town.
 *
 * Please note, not all customers use this functionality. See `SchoolService.getNearest`
 * for more information.
 *
 * ¯\_(ツ)_/¯
 */
@Index('id', ['id'], { unique: true })
@Entity('schulen_naechstgelegen')
export class NearestSchool {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @Column('varchar', { name: 'ort', nullable: false, length: 255 })
  town: string

  @ManyToOne(
    () => System,
    { onDelete: "RESTRICT", onUpdate: "CASCADE",  nullable: false }
    )
  @JoinColumn([{ name: "schulart_system_id", referencedColumnName: "id" }])
  schoolType: System

  @ManyToOne(
    () => School,
    { onDelete: "SET NULL", onUpdate: "CASCADE",  nullable: true }
    )
  @JoinColumn([{ name: "naechstgelegene_schule_id", referencedColumnName: "id" }])
  school: School

  constructor (init?: Partial<NearestSchool>) {
    Object.assign(this, init)
  }
}
