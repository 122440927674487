import {  Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { TransportCompany } from '.'
import { ValidTimespan } from '../../utils'

// a transport company is owner of several transportation (bus) lines
@Index('id', ['id'], { unique: true })
@Entity('unternehmen_linien')
export class TransportCompanyLine {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @ManyToOne(type => TransportCompany, transportCompany => transportCompany.lines, { 
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
   })
  @JoinColumn({ name: 'unternehmen_id' })
  company: TransportCompany

  @Column('varchar', { name: 'linie', nullable: false, length: 100 })
  line: string

  @Column(type => ValidTimespan)
  valid: ValidTimespan

  constructor (init?: Partial<TransportCompanyLine>) {
    Object.assign(this, init)
  }
}
