import {  Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { TransportCompany } from '.'
import { User } from '../../users'

/**
 * SD has no meaning anymore
 */
@Entity('sd_rechnungen')
export class Invoice {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @Index({ unique: true })
  @Column('varchar', {name: 'rn_nr', nullable: false, length: "100"})
  invoiceNumber: string

  @ManyToOne(type => TransportCompany, company => company.invoices, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({name: 'vu_id'})
  company: TransportCompany

  @Column('mediumtext', { name: 'rn_extern', nullable: true })
  externalInvoiceNumber: string | null

  // Improve: not nullable
  @Column('date', { name: 'posteingang', nullable: true })
  inbox: string | null

  // Document
  // Improve: Better name?
  @Column('date', { name: 'fällig_rn', nullable: true })
  dueInvoiceNumber: string | null

  // Indicates if this is correcting invoice
  // Improve: not nullable
  @Column('tinyint', { name: 'korrektur_rn', nullable: true, width: 1 })
  isCorrection: boolean | null

  @ManyToOne(type => Invoice, { 
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({name: 'korrektur_nr', referencedColumnName: 'invoiceNumber' })
  correction: Invoice | null

  @Column('mediumtext', { name: 'prüfung', nullable: true })
  review: string | null

  // Document
  @Column('mediumtext', { name: 'hh_jahr', nullable: true })
  hhYear: string | null

  // Free text
  @Column('mediumtext', { name: 'schuljahr', nullable: true })
  schoolYear: string | null

  // Improve: Refactor to enum. Any form of validation?
  // format: 01,02..12
  @Column('varchar', { name: 'monat', nullable: true, length: "2" })
  month: string | null

  // Improve: Better datatype (int)
  @Column('mediumtext', { name: 'anz_tage', nullable: true })
  numberOfDays: string | null

  // Improve: Not nullable if mandatory
  @Column('mediumtext', { name: 'lk', nullable: true })
  county: string | null

  // Improve: Better name? Link to `System` or Enum?
  @Column('mediumtext', { name: 'kostenart', nullable: true })
  typeOfCost: string | null

  @Column('decimal', { name: 'netto', nullable: false, precision: 10, scale: 2 })
  netPrice: number

  @Column('decimal', {
    name: 'ust_betrag',
    nullable: true,
    precision: 10,
    scale: 2
  })
  salesTaxTotal: string | null

  @Column('decimal', {
    name: 'brutto',
    nullable: true,
    precision: 10,
    scale: 2
  })
  grossPrice: number | null

  // Document
  // Improve: Better datatype (varchar)? Better name
  @Column('mediumtext', { name: 'fällig_kasse', nullable: true })
  dueCashRegister: string | null

  @Column('mediumtext', { name: 'bemerkungen', nullable: true })
  notes: string | null

  @Column('date', { name: 'zahlung_bezahlt', nullable: true })
  paidAt: Date | null

  @Column('date', { name: 'zahlung_angewiesen', nullable: true})
  paymentInstructedAt: Date | null

  @ManyToOne(type => User, { 
    nullable: true,
 onUpdate: 'CASCADE',
   onDelete: 'SET NULL' 
  })
  @JoinColumn({name: 'erfassung_bearbeiterid'})
  registration: User | null

  @ManyToOne(type => User, { 
    nullable: true,
 onUpdate: 'CASCADE',
   onDelete: 'SET NULL' 
  })
  @JoinColumn({name: 'zahlung_Bearbeiterid'})
  paymentRegistration: User | null

  @ManyToOne(type => User, { 
    nullable: true,
onUpdate: 'CASCADE',
   onDelete: 'SET NULL'
  })
  @JoinColumn({name: 'aufnahme_bearbeiterid'})
  recordingRegistration: User | null
  
  // Improve: Check names
  // Document: both
  @Column('mediumtext', { name: 'bemerkungen_aufnahme', nullable: true })
  notesOnReception: string | null
  @Column('mediumtext', { name: 'bemerkungen_kasse', nullable: true })
  notesOnCashRegister: string | null

  constructor (init?: Partial<Invoice>) {
    Object.assign(this, init)
  }
}
