import { IsNotEmpty, IsString } from "class-validator"

export class CompressJobQueryParams {
    @IsString()
    @IsNotEmpty()
    instance: string

    @IsString()
    @IsNotEmpty()
    jobId: string
}