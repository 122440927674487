import {  Column, Entity, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import { Permission, UserMeta } from '.'

@Entity('user_roles')
export class UserRole {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @Column('varchar', { name: 'name', nullable: false, length: 255})
  name: string

  // Deprecated
  @Column('text', { name: 'perms', nullable: true })
  perms: string | null

  @OneToMany(type => UserMeta, userMeta => userMeta.role)
  users: UserMeta[]

  @OneToMany(type => Permission, permission => permission.role)
  permissions: Permission[]

  constructor (init?: Partial<UserRole>) {
    Object.assign(this, init)
  }
}
