import { Column } from 'typeorm'

/**
 * Describes which direction is needed at any weekday.
 */
export class TripPlan {
  @Column('tinyint', { name: 'mo_hin', nullable: true, width: 1 })
  mondayTo: boolean | null
  @Column('tinyint', { name: 'mo_rück', nullable: true, width: 1 })
  mondayReturn: boolean | null

  @Column('tinyint', { name: 'di_hin', nullable: true, width: 1 })
  tuesdayTo: boolean | null
  @Column('tinyint', { name: 'di_rück', nullable: true, width: 1 })
  tuesdayReturn: boolean | null

  @Column('tinyint', { name: 'mi_hin', nullable: true, width: 1 })
  wednesdayTo: boolean | null
  @Column('tinyint', { name: 'mi_rück', nullable: true, width: 1 })
  wednesdayReturn: boolean | null

  @Column('tinyint', { name: 'do_hin', nullable: true, width: 1 })
  thursdayTo: boolean | null
  @Column('tinyint', { name: 'do_rück', nullable: true, width: 1 })
  thursdayReturn: boolean | null

  @Column('tinyint', { name: 'fr_hin', nullable: true, width: 1 })
  fridayTo: boolean | null
  @Column('tinyint', { name: 'fr_rück', nullable: true, width: 1 })
  fridayReturn: boolean | null
}