import {Column, Entity, ManyToOne} from "typeorm";
import {Message} from "./Message.entity";
import {BaseUser} from "../../users/entities";
import {ApiProperty} from "@nestjs/swagger";

@Entity()
export class UserMessageInteraction {

    @ManyToOne(
        type => Message,
        message => message.userInteractions,
        {primary: true, nullable: false}
    )
    message: Message


    @ManyToOne(
        type => BaseUser,
        user => user.messageInteractions,
        {primary: true, nullable: false}
    )
    user: BaseUser


    @Column({type: 'timestamp', nullable: true })
    readAt: Date


    @Column({ type: 'timestamp', nullable: true })
    printedAt: Date

    constructor(init: Partial<UserMessageInteraction>) {Object.assign(this, init)}
}