import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { TransportCompanyVehicle } from '.'

@Entity('unternehmen_kfz_kilometerstand', { schema: 'via' })
export class TransportCompanyvehicleMileage {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @Column('timestamp', { name: 'timestamp', nullable: false })
    creationDate: Date

    @Column('double', { name: 'kilometerstand', nullable: false })
    mileage: number

    @ManyToOne(type => TransportCompanyVehicle, vehicle => vehicle.mileages, {
        nullable: false,
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE'
    })
    @JoinColumn({ name: 'kfz_id' })
    vehicle: TransportCompanyVehicle

    constructor(init?: Partial<TransportCompanyvehicleMileage>) {
        Object.assign(this, init)
    }
}