import { IsArray, IsBoolean, IsEnum, IsNumber, IsObject, IsString, ValidateNested } from "class-validator"
import type { VariableFieldConditions } from "./VariableFieldConditions.dto"
import type { SqlQuery } from "../utils"
import { VariableFieldType, MyViaOptions } from "."

/**
 * It defines the structure of a variable field to create a custom
 * form. It is set by VIA. The keys should not be changed.
 */
export class VariableField {
    @IsString()
    name: string

    @IsEnum(VariableFieldType)
    type: VariableFieldType

    @IsString()
    prevalue: string | string[] | boolean // default field value -> only string on VIA side

    @IsString()
    listquery: SqlQuery // Sql query to run to populate the items of a list field

    @IsArray()
    listitems: string[] // Contains static items for a list field

    @IsNumber()
    max_chars: number

    @IsBoolean()
    required: boolean

    @IsBoolean()
    readOnly: boolean

    @IsNumber()
    order: number // The order based on which to show the field in the form

    @IsObject()
    @ValidateNested()
    conditions: VariableFieldConditions

    @IsObject()
    @ValidateNested()
    myVia: MyViaOptions
}