import {
  Column,
  Entity,
  Index,
  PrimaryGeneratedColumn
} from 'typeorm'

/**
 * Corner case and deprecated: Legacy table for transport requests
 *
 * Simple key-value store used in VIA
 */
@Entity('system_anträge')
export class SystemRequest {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @Column('text', { name: 'schluessel' }) key: string
  @Column('text', { name: 'wert1' }) value1: string
  @Column('text', { name: 'wert2' }) value2: string

  constructor (init?: Partial<SystemRequest>) {
    Object.assign(this, init)
  }
}
