import {
    Column,
    Entity,
    PrimaryGeneratedColumn
} from 'typeorm'
import { placeholderTransformer } from '../reports.transformers'
import type { ReportPlaceholder } from '../types/ReportPlaceholder'
import { type SqlQuery, combinations } from '../../utils'

@Entity('editor')
export class Report {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @Column('varchar', { name: 'name', nullable: false, length: 255 })
    name: string

    @Column('mediumtext', { name: 'sql_select', nullable: false })
    query: SqlQuery

    @Column('text', { name: 'sql_execute', nullable: true })
    afterHook: SqlQuery | null

    @Column('text', { name: 'description', nullable: false })
    description: string

    @Column('mediumtext',
        { name: 'textmarken', nullable: false, transformer: placeholderTransformer })
    placeholders: ReportPlaceholder[]

    @Column('tinyint', { name: 'removed', nullable: false, default: false, width: 1 })
    removed: boolean

    @Column('tinyint', { name: 'execute_external', width: 1 })
    executeExternal: boolean

    buildQuerys = async (connection): Promise<any> => {
        //build up query
        let selectQuery = this.query
        let executeQuery = this.afterHook
        if (selectQuery) selectQuery = this.query.replace(/\|/g, '\'')
        if (executeQuery) executeQuery = this.afterHook.replace(/\|/g, '\'')
        let selectQuerys = []
        let executeQuerys = []
        let valuesOfAllPlaceholders = []

        if (this.placeholders && this.placeholders.length !== 0) {
            // Evaluate each single placeholder
            for (const placeholder of this.placeholders) {
                await placeholder.evaluateValues(connection)
                const values = []
                for (const value of placeholder.values) {
                    values.push({ name: placeholder.name, value })
                }
                valuesOfAllPlaceholders.push(values)
            }
            // Create the combinations of all placeholders
            const combinationsofValues = [...combinations(valuesOfAllPlaceholders)]
            for (const values of combinationsofValues) {
                let Query = selectQuery
                for (const value of values) {
                    const replace = '\\[' + value.name + '\\]'
                    Query = Query.replace(new RegExp(replace, 'g'), value.value)
                }
                selectQuerys.push({ Query, values })

                if (executeQuery) {
                    let Query = executeQuery
                    for (const value of values) {
                        const replace = '\\[' + value.name + '\\]'
                        Query = Query.replace(new RegExp(replace, 'g'), value.value)
                    }
                    executeQuerys.push({ Query, values })
                }
            }
        } else {
            selectQuerys.push({ Query: selectQuery, values: [] })
            executeQuerys.push({ Query: executeQuery, values: [] })
        }
        return { select: selectQuerys, execute: executeQuerys }
    }

    constructor(init?: Partial<Report>) {
        Object.assign(this, init)
    }
}
