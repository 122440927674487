import {Column, Entity, PrimaryGeneratedColumn} from "typeorm";
import {ApiProperty} from "@nestjs/swagger";
import type { GraphhopperCacheType } from "../../utils";

@Entity('gh_results')
export class GraphhopperCache {
    @PrimaryGeneratedColumn({type: 'int', name: 'id', unsigned: true})
    id: number

    @ApiProperty()
    @Column({type: 'text', nullable: true})
    request: string
    
    @ApiProperty()
    @Column({type: 'json', nullable: false})
    result: string

    @Column({type: 'integer', nullable: false})
    type: GraphhopperCacheType

    @Column({ type: 'timestamp', nullable: false})
    timestamp: Date

    @Column('varchar', { name: 'search_hash', length: 255, nullable: true })
    searchHash: string | null

    constructor(init: Partial<GraphhopperCache>) {
        Object.assign(this, init)
    }
}