import {Column, Entity, Index, ManyToMany, OneToMany, OneToOne, PrimaryGeneratedColumn} from 'typeorm'
import {IsEmail, IsEmpty, IsNotEmpty, IsOptional, IsString, MaxLength} from 'class-validator'
import {ApiHideProperty, ApiProperty} from "@nestjs/swagger";
import {MovableFreeDay, SchoolClass, SchoolEntrance, SchoolLogin, SchoolType} from '.'
import {Address, CoordinatesShort, slashListTransformer, VariableFields} from '../../utils'
import {Stop} from '../../transport/entities'
import {TripRequirement} from '../../routing/entities'
import type {SchoolDayOffer} from '../types'
import {CrudValidationGroups} from "../../utils/validation";
import {FormClass, FormField, InputType} from "@stadtlandnetz/forms";
import {EvaluationSchemaAssignment} from "../../evaluation-schema";
import { OneTimePin } from '../../users/entities';
import { TransportRequestNearestSchools } from '../../transport-request/entities';
import { NewsRights } from '../../news';
import { SchoolDistrict } from './SchoolDistrict.entity';
import type { TransportRequestType } from '../../transport-request';

const {CREATE, UPDATE} = CrudValidationGroups

export type SchoolID = number

@Entity('schulen', {schema: 'via'})
@FormClass({interfereLabels: true, defaultGroupName: 'forms.schoolInformation'})
export class School {
    @IsEmpty({groups: [CREATE]})
    @PrimaryGeneratedColumn({type: 'int', name: 'id', unsigned: true})
    id: number

    /*
    @FormField({
        type: InputType.SELECT,
        title: 'schoolType'
    })
     */
    @IsNotEmpty()
    @OneToMany(type => SchoolType, schoolType => schoolType.school, { cascade: true })
    types: SchoolType[]

    @FormField({
        type: InputType.SELECT,
        title: 'schoolName'
    })
    @IsNotEmpty({groups: [CREATE]})
    @IsString({always: true})
    @MaxLength(100, {always: true})
    @Column('mediumtext', {name: 'name', nullable: true})
    name: string | null

    @IsNotEmpty({groups: [CREATE]})
    @IsOptional({groups: [UPDATE]})
    @Index({ unique: true })
    @Column('varchar', {name: 'kürzel', nullable: false, length: 100})
    short: string

    @Column(type => Address)
    address: Address

    @Column('varchar', { name: 'lk', length: 100, nullable: true })
    county: string | null

    @Column(type => CoordinatesShort)
    coordinates: CoordinatesShort

    @Column('varchar', {name: 'direktor', nullable: true, length: '255'})
    director: string | null

    @IsEmail({}, {always: true})
    @Column('varchar', {name: 'email', nullable: true, length: '255'})
    email: string | null

    @Column('varchar', {name: 'sekretariat', nullable: true, length: '255'})
    secretary: string | null

    @Column('varchar', {name: 'telefon', nullable: true, length: '255'})
    phone: string | null

    @Column('varchar', {name: 'fax', nullable: true, length: '255'})
    fax: string | null

    // Increase schools flexibility for evaluation
    @Column({ type: 'json', nullable: true })
    showInMyvia: Record<TransportRequestType, boolean>

    @Column({ type: 'json', nullable: true })
    considerForClosestSchool: Record<TransportRequestType, boolean>

    // legacy 08:00 | 09:00
    // Unterrichts-Beginn and UnterrichtsEnde -> auto converted in schulen_zeiten
    @Column('mediumtext', {name: 'u_beginn', nullable: true, transformer: slashListTransformer})
    lessonsStartAt: string[] | null
    @Column('mediumtext', {name: 'u_ende', nullable: true, transformer: slashListTransformer})
    lessonsEndAt: string[] | null

    // TODO: Can this be removed?
    // outdated, not in use in via
    @Column('varchar', {name: 'ausstieg', nullable: true, length: 255})
    ausstieg: string | null

    @Column('mediumtext', {name: 'besonderheiten', nullable: true})
    specialFeatures: string | null

    // TODO: Can this be removed?
    // outdated, not in use in via
    @Column('mediumtext', {name: 'tags', nullable: true})
    tags: string | null

    @Column(type => VariableFields)
    variableFields: VariableFields

    /**
     *  Saves the id of the school used in an external system.
     *  Sometimes needed when data is imported from another program or is even synchronised
     */
    @Column('text', {name: 'id_extern'})
    externalId: string

    @Column('varchar', {name: 'max_class', length: "10"})
    highestClass: string

    // Document
    @Column('varchar', {name: 'gta', length: 255, nullable: true})
    fullDayOffer: SchoolDayOffer

    @Column('text', {name: 'holder'})
    holder: string

    @ManyToMany(type => Stop, (stop) => stop.name)
    @Column('varchar', {name: 'hst', length: 255, nullable: true})
    stops: string

    @FormField({
        type: InputType.TEXT,
        title: 'class'
    })
    @IsNotEmpty()
    @ApiProperty({type: () => SchoolClass})
    @OneToMany(type => SchoolClass, schoolClass => schoolClass.school, { cascade: true, eager: true })
    classes: SchoolClass[]

    @OneToOne(type => SchoolLogin, login => login.school)
    login: SchoolLogin | null

    @OneToOne(type => OneTimePin, oneTimePin => oneTimePin.school)
    oneTimePin: OneTimePin | null

    @OneToMany(type => SchoolDistrict, district => district.school)
    districts: SchoolDistrict[]

    @OneToMany(
        () => SchoolEntrance,
        schoolEntrance => schoolEntrance.school
    )
    entrances: SchoolEntrance[]

    @OneToMany(type => MovableFreeDay, freeDay => freeDay.school)
    movableFreeDays: MovableFreeDay[]

    @OneToMany(type => TripRequirement, requirement => requirement.school)
    requirements: TripRequirement[]

    @ApiHideProperty()
    @OneToMany(type => EvaluationSchemaAssignment, evaluationSchemaAssignment => evaluationSchemaAssignment.school, { eager: true })
    evaluationSchemaAssignments: EvaluationSchemaAssignment[]

    @OneToMany(type => TransportRequestNearestSchools, closestSchools => closestSchools.school)
    closestSchools: TransportRequestNearestSchools[]

    @OneToMany(
        type => NewsRights,
        newsRights => newsRights.school
    )
    newsRights: NewsRights[]

    constructor(init?: Partial<School>) {
        Object.assign(this, init)
    }
}
