import { IsBoolean, IsEnum, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";

export enum ResourceType {
    GUARDIAN = "guardian",
    REQUEST = "antrag"
}

export class AddressVerificationPayloadDto {
    @IsNumber()
    resourceId: number
    
    @IsEnum(ResourceType)
    resourceType: ResourceType
}

export class AddressVerificationResultDto {
    @IsNumber()
    resourceId: number

    @IsEnum(ResourceType)
    resourceType: ResourceType

    @IsBoolean()
    verified: boolean

    @IsString()
    @IsOptional()
    error?: string

    constructor(init: Partial<AddressVerificationResultDto>) {
        Object.assign(this, init)
    }
}