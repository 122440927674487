export enum DocumentTemplateVisibility {
  VISIBLE = 0,
  OUTDATED = 1,
  INVISIBLE = 2
}

export enum DocumentStyleKey {
  BOTTOM = 'bottom',
  CONTENT = 'content',
  FAMILY = 'family',
  LANDSCAPE = 'landscape',
  LEFT = 'left',
  OPTIONS = 'options',
  PAGE_BREAK = 'page_break',
  RIGHT = 'right',
  SIZE = 'size',
  TOP = 'top'
}

export enum DocumentStyleProperty {
  CSS = 'CSS',
  FONT = 'font',
  FOOTER = 'Footer',
  FORMAT = 'Format',
  HEADER = 'Header',
  MARGIN = 'Ränder'
}

export enum ReplacePosition {
  BEFORE_PLACEHOLDER_BEFORE_TABLE = 0,
  AFTER_PLACEHOLDER_BEFORE_TABLE = 1,
  AFTER_PLACEHOLDER_AFTER_TABLE = 2
}

export enum PlaceholderType {
  MANUAL = 0, 
  PROGRAMMED = 1,
  FROM_LIBRARY = 2
}

export enum PlaceholderSubType {
  TEXTBOX = 0,
  TEXTFIELD = 1,
  DATEFIELD = 2,
  SELECTION = 3
}

export enum DocumentLevel {
  STUDENT = 'Schüler',
  SCHOOLS = 'Schulen',
  TICKETS = 'Tickets',
  COMPANIES = 'Unternehmen',
  REQUESTS = 'Anträge'
}