import {

  Column,
  Entity,
  Index,
  PrimaryGeneratedColumn
} from 'typeorm'


@Index('id_2', ['id'], { unique: true })
@Index('id', ['id'], {})
@Entity('schüler')
export class LegacyStudent {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @Column('tinytext', { name: 'snr', nullable: true })
  studentNumber: string | null

  @Column('varchar', { name: 'name', nullable: true, length: 100 })
  name: string | null

  @Column('text', { name: 'vorname', nullable: true })
  vorname: string | null

  @Column('mediumtext', { name: 'plz', nullable: true })
  plz: string | null

  @Column('mediumtext', { name: 'ort', nullable: true })
  ort: string | null

  @Column('mediumtext', { name: 'ortsteil', nullable: true })
  ortsteil: string | null

  @Column('mediumtext', { name: 'strasse', nullable: true })
  strasse: string | null

  @Column('mediumtext', { name: 'hnr', nullable: true })
  hnr: string | null

  @Column('mediumtext', { name: 'eltern', nullable: true })
  eltern: string | null

  @Column('mediumtext', { name: 'bemerkungen', nullable: true })
  bemerkungen: string | null

  @Column('mediumtext', { name: 'lk', nullable: true })
  lk: string | null

  @Column('tinyint', { name: 'begleitperson', nullable: true, width: 1 })
  begleitperson: boolean | null

  @Column('tinyint', { name: 'kraftknoten', nullable: true, width: 1 })
  kraftknoten: boolean | null

  @Column('tinyint', { name: 'rollstuhl_sitzend', nullable: true, width: 1 })
  rollstuhlSitzend: boolean | null

  @Column('tinyint', { name: 'rollstuhl_mitnehmen', nullable: true, width: 1 })
  rollstuhlMitnehmen: boolean | null

  @Column('mediumtext', { name: 'tags', nullable: true })
  tags: string | null

  @Column('mediumtext', { name: 'attribute', nullable: true })
  attribute: string | null

  @Column('mediumtext', { name: 'kontakt', nullable: true })
  kontakt: string | null

  @Column('text', { name: 'var_fields' })
  varFields: string

  constructor (init?: Partial<LegacyStudent>) {
    Object.assign(this, init)
  }
}
