import { Column, Entity, Index, JoinColumn, ManyToOne, OneToMany, OneToOne, PrimaryGeneratedColumn } from 'typeorm'
import { VariableFields } from '../../utils'
import { TransportCompany, TransportCompanyDepot, TransportCompanyVehicleDriver, TransportCompanyvehicleMileage } from '.'
import { TourTrips, TourTripsEndTracking } from '../../tours'

@Index('id', ['id'], { unique: true })
@Entity('unternehmen_kfz', { schema: 'via' })
export class TransportCompanyVehicle {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @ManyToOne(type => TransportCompany, transportCompany => transportCompany.vehicles, {
        nullable: true
    })
    @JoinColumn({ name: 'unternehmenID' })
    company: TransportCompany | null

    @ManyToOne(type => TransportCompanyDepot, depot => depot.vehicles, {
        nullable: true
    })
    @JoinColumn({ name: 'betriebshofID' })
    companyDepot: TransportCompanyDepot | null

    @Column('varchar', { name: 'kennzeichen', length: 255, nullable: false })
    licensePlate: string

    @Column('varchar', { name: 'marke', length: 255, nullable: true })
    brand: string | null

    @Column('varchar', { name: 'modell', length: 255, nullable: true })
    model: string | null

    @Column('int', { name: 'fahrgastplaetze', nullable: true })
    numberOfPassengerSeats: number | null

    @Column('int', { name: 'rollstuhlplaetze', nullable: true })
    numberOfWheelchairSeats: number | null

    @Column('varchar', { name: 'bemerkungen', length: 1000, nullable: true })
    notes: string | null

    @Column(type => VariableFields)
    variableFields: VariableFields

    @OneToOne(type => TourTrips, tourTrip => tourTrip.usedVehicle, {
        nullable: true
    })
    tourTrip: TourTrips

    @OneToMany(type => TransportCompanyVehicleDriver, vehicleDriver => vehicleDriver.vehicle)
    drivers: TransportCompanyVehicleDriver[]

    @OneToMany(type => TransportCompanyvehicleMileage, mileage => mileage.vehicle)
    mileages: TransportCompanyvehicleMileage[]

    @OneToMany(type => TourTripsEndTracking, endTracking => endTracking.vehicle)
    endTrackings: TourTripsEndTracking[]

    constructor(init?: Partial<TransportCompanyVehicle>) {
        Object.assign(this, init)
    }
}