import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { Lot } from '.'

@Entity('lose_meta')
export class LotMeta {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => Lot, lot => lot.information, { 
    nullable: false,
 onUpdate: 'CASCADE',
onDelete: 'CASCADE' 
  })
  @JoinColumn({ name: 'losid' })
  lot: Lot

  @Column('varchar', { name: 'feldname', length: 255 })
  key: string

  @Column('text', { name: 'value1' })
  value1: string

  @Column('text', { name: 'value2' })
  value2: string

  constructor (init?: Partial<LotMeta>) {
    Object.assign(this, init)
  }
}
