import {  Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import { NecessityReview, ReasonabilityReview, TransportRequest } from '.'

@Entity('antraege_pruefungen')
export class TransportRequestReview {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => TransportRequest, transportRequest => transportRequest, { 
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({name: 'antragsid'})
  request: TransportRequest

  @Column('varchar', { name: 'bemerkungen', length: 255 })
  notes: string

  @Column('tinyint', { name: 'ergebnis_notwendigkeit', width: 1 })
  needed: boolean

  @Column('tinyint', { name: 'ergebnis_zumutbarkeit', width: 1 })
  reasonable: boolean

  // Given in meters
  @Column('float', { name: 'distanz_wohnung_schule_fussweg', precision: 12 })
  distanceFromHomeToSchoolByFoot: number
  @Column('float', { name: 'distanz_wohnung_schule_auto', precision: 12 })
  distanceFromHomeToSchoolByCar: number

  @OneToMany(
    type => NecessityReview,
    necessityReview => necessityReview.requestReview
  )
  necessityReviews: NecessityReview[]

  @OneToMany(
    type => ReasonabilityReview,
    reasonabilityReview => reasonabilityReview.requestReview
  )
  reasonabilityReview: ReasonabilityReview[]

  constructor (init?: Partial<TransportRequestReview>) {
    Object.assign(this, init)
  }
}
