import {
    IsBoolean,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
    ValidateIf,
    ValidateNested
} from 'class-validator'
import type { Address } from '../../utils'
import type { Guardian } from '../../students'

export enum AddressVerificationMethod {
    API = 'api',
    LETTER = 'letter',
    NO_CHECK = 'noCheck'
}

export enum AddressVerificationApiVersion {
    MESO_43 = 'Meso_43',
    XMELD_30 = 'XMeld_30'
}

export class Proxy {
    @IsString()
    @IsNotEmpty()
    host: string

    @IsNumber()
    port: number
}

export class AddressVerificationConfiguration {
    @IsEnum(AddressVerificationMethod)
    method: AddressVerificationMethod

    @IsEnum(AddressVerificationApiVersion)
    @ValidateIf(context => context.method === AddressVerificationMethod.API)
    apiSpec?: AddressVerificationApiVersion

    @ValidateIf(context => context.apiSpec)
    @IsBoolean()
    runVerificationCron?: boolean

    @ValidateIf(context => context.apiSpec)
    @IsString()
    @IsNotEmpty()
    apiUrl?: string

    @IsOptional()
    @ValidateNested()
    proxy: Proxy
}

export class MesoApiConfiguration extends AddressVerificationConfiguration {
    @IsNumber()
    cityMandant: number
}

export class XMeldApiConfiguration extends AddressVerificationConfiguration {
    @IsString()
    @IsNotEmpty()
    customerName: string

    @ValidateNested()
    customerAddress: Address

    @IsString()
    @IsNotEmpty()
    customerEmail: string

    @IsString()
    @IsNotEmpty()
    customerPhone: string

    @IsString()
    @IsNotEmpty()
    providerName: string

    @IsString()
    @IsNotEmpty()
    municipalityKey: string

    // Wrapper around XMeld payload - {xmeld_payload} will be replaced with the payload
    @IsString()
    @IsNotEmpty()
    soapEnvelope: string

    // Whether to base64 encode the XMeld payload
    @IsBoolean()
    encodePayload: boolean = false

    // Location of XMeld result in response body (e.g. S:Body.response.xmeld.result)
    @IsString()
    @IsNotEmpty()
    responseKey: string
}

export type AddressVerificationApiParams<ApiConfig extends AddressVerificationConfiguration> = {
    guardian: Guardian
    address: Address
    configuration: ApiConfig
}