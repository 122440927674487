import { IsEnum } from "class-validator"
import type { News } from "../entities"
import { UserGroupNews } from "./userGroupNews.enum"

export class NewsParametersDto {
    @IsEnum(UserGroupNews)
    userGroup: UserGroupNews
}

export type NewsResult = {
    [category: string]: Partial<News>[]
}