import { footerData } from "../stores/data.stores"
import { get } from "svelte/store"
import { footerKeys, System } from "@stadtlandnetz/core"
import { activeFooterLinkContent, footerLinkBreadcrumb } from "../stores/api.stores"

export function openFooterDataPrivacyContent(): string | null {
    const data: System[] = get(footerData)
    const dataPrivacy = data ? data.find((data) => data.value === footerKeys.privacy) : undefined
    if (!dataPrivacy) return null
    else if (dataPrivacy.value2) window.open(dataPrivacy.value2, '_blank', 'noopener=yes, noreferrer=yes') 
    else if (dataPrivacy.value3) {
        //@ts-ignore
        activeFooterLinkContent.set(dataPrivacy.value3.htmlContent)
        footerLinkBreadcrumb.set(dataPrivacy.value)
        return dataPrivacy.value
    }
}