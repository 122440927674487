import 'reflect-metadata'
import type { DataClass, FieldMetadata, FieldOptions, Fields, FormMetadata, FormOptions } from './types'
import { registry } from './registry'
import { Form } from './form'

const basicTypes = [String, Number]

export function buildForm(target: DataClass, displayedFields: Object[] = null): Form | null {
    const formMetadata = registry.getFormMeta(target)
    if (!formMetadata) return null
    let allFields = getFields(formMetadata)

    if (displayedFields)
        allFields = allFields.map(field =>
            displayedFields.find(name => name == field.id) ? field : { ...field, hidden: true }
        )

    return new Form({ fields: allFields, options: formMetadata.options })
    // return getRecursiveForm(formMetadata)
}

/**
 * Calculated the default field options based on the given form options.
 * These will be overwritten by those directly specified using the decorators.
 * @param formOptions
 * @param fieldMetadata
 */
function getPreset(formOptions: FormOptions, fieldMetadata: FieldMetadata): FieldOptions {
    const preset: FieldOptions = {}
    if (formOptions.interfereLabels) preset.label = fieldMetadata.propertyName

    return preset
}

export function getFields(formMetadata: FormMetadata): Fields {
    // @ts-ignore
    const allFields = registry.getFieldsMeta(formMetadata.target).map(fieldMeta => {
        const fieldName = fieldMeta.propertyName
        // @ts-ignore
        const propertyType = Reflect.getMetadata('design:type', formMetadata.target.prototype, fieldName)

        if (!basicTypes.includes(propertyType) && !fieldMeta.options.type) {
            const nestedFormMeta = 'name' in propertyType && propertyType.name != ''
                ? registry.getFormMeta(propertyType)
                : registry.getFormMeta(propertyType())

            if (!nestedFormMeta) {
                console.error(`There is no form registered for ${fieldName}`)
                return []
            }
            const subFields = getFields(nestedFormMeta)
            if (subFields.length == 0)
                console.error(`No fields were found for ${propertyType.name}`)

            const subGroupName = nestedFormMeta.options.defaultGroupName || nestedFormMeta.target.name
            for (const subField of subFields.filter(f => !f.group)) {
                subField.group = subGroupName
            }

            return subFields.map(field => {
                return { ...field, nested: fieldName }
            })
        }

        return {
            ...{
                id: fieldName,
                name: fieldName,
                type: registry.getInputType(propertyType),
                nested: false
            }, ...getPreset(formMetadata.options, fieldMeta), ...fieldMeta.options
        }
    })

    // @ts-ignore
    const flatFields = allFields.flat()
    return flatFields
}

// export function getRecursiveForm (formMetadata: FormMetadata): Form | null {
//   const form = new Form({ fields: [], options: formMetadata.options })
//
//   // @ts-ignore
//   for (const fieldMeta of registry.getFieldsMeta(formMetadata.target)) {
//     const fieldName = fieldMeta.propertyName
//     // @ts-ignore
//     const propertyType = Reflect.getMetadata('design:type', formMetadata.target.prototype, fieldName)
//
//     if (!basicTypes.includes(propertyType)) {
//       const nestedFormMeta = registry.getFormMeta(propertyType)
//       if (!nestedFormMeta)
//         console.warn(`There is no form registered for ${fieldName}`)
//       // @ts-ignore
//       form.subForms.push(getRecursiveForm(nestedFormMeta))
//       // const subGroupName = nestedFormMeta.options.defaultGroupName || nestedFormMeta.target.name
//       // for (const subField of subFields.filter(f => !f.group)) {
//       //   subField.group = subGroupName
//       // }
//     } else {
//       form.fields.push({
//         ...{
//           id: fieldName,
//           name: fieldName,
//           type: registry.getInputType(propertyType)
//         }, ...getPreset(formMetadata.options, fieldMeta), ...fieldMeta.options
//       })
//     }
//   }
//
//   return form
// }