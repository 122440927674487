import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { DocumentTemplate } from '.'
import type { DocumentStyleKey, DocumentStyleProperty } from '../types'

@Entity('dok_style')
export class DocumentStyle {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => DocumentTemplate, document => document.styles, {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn({ name: 'dok_id' })
  document: DocumentTemplate

  // @Column({ name: 'property', type: 'enum', nullable: false, enum: DocumentStyleProperty })
  @Column({ name: 'property', type: 'varchar', length: 100, nullable: false })
  property: DocumentStyleProperty

  // @Column( { name: 'skey', type: 'enum', nullable: false, enum: DocumentStyleKey })
  @Column( { name: 'skey', type: 'varchar', length: 100, nullable: false })
  key: DocumentStyleKey

  @Column('mediumtext', { name: 'svalue', nullable: true })
  value: string | null

  constructor (init?: Partial<DocumentStyle>) {
    Object.assign(this, init)
  }
}
