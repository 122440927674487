import { Column, Entity, Index, ManyToOne, PrimaryGeneratedColumn } from "typeorm";
import { VerificationState } from "../../types/VerificationState.enum";
import { BaseUser } from "../BaseUser.entity";

@Index(['user', 'email'], { unique: true })
@Entity('verification_email')
export class EmailVerification {
    //
    @PrimaryGeneratedColumn()
    id: number

    @ManyToOne(type => BaseUser, user => user.emailVerifications, { nullable: false })
    user: BaseUser

    //
    @Column({ type: 'varchar', length: 255, nullable: false })
    email: string

    // @ApiProperty({ enum: VerificationState })
    @Column({ type: 'enum', enum: VerificationState, nullable: false, default: VerificationState.PENDING })
    state: VerificationState

    @Column({ type: 'varchar', length: 255, nullable: false })
    code: string

    //
    @Column({ type: 'datetime', nullable: true})
    sentAt: Date | null

    //
    @Column({ type: 'datetime', nullable: true})
    verifiedAt: Date | null

    constructor(init: Omit<EmailVerification, 'id' | 'state' | 'verifiedAt' >) { Object.assign(this, init) }
}