import { ApiProperty } from '@nestjs/swagger'
import { FormClass, FormField, InputType } from '@stadtlandnetz/forms'
import { Type } from 'class-transformer'
import { IsEmail, IsNotEmpty, IsPhoneNumber, ValidateIf, ValidateNested } from 'class-validator'
import { Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import { System } from '../../system'
import { AddressByLetterVerification } from '../../users'
import { BaseUser } from '../../users/entities/BaseUser.entity'
import { Address, dayjs, Gender } from '../../utils'
import { Title } from '../../utils/Title'

export enum GuardianValidation {
    BIRTHDAY_REQUIRED = 'birthday_required',
    BIRTHDAY_OPTIONAL = 'birthday_optional'
}

@Entity()
@FormClass({ interfereLabels: true })
export class Guardian {
    @PrimaryGeneratedColumn()
    id: number

    @FormField({
        type: InputType.SELECT,
        options: [
            {
                title: '$mr',
                value: 'männlich'
            },
            {
                title: '$ms',
                value: 'weiblich'
            },
            {
                title: '$mx',
                value: 'divers'
            }
        ],
        title: 'salutation'
    })
    @IsNotEmpty({ always: true })
    @Column({ type: 'enum', enum: Gender, nullable: true })
    sex: Gender

    @FormField({
        type: InputType.SELECT,
        optional: true,
        title: 'type'
    })
    @JoinColumn({ name: 'art' })
    @ManyToOne((type) => System, { eager: true, nullable: true })
    type: System

    @FormField({
        type: InputType.SELECT,
        optional: true,
        options: [
            {
                title: 'Dr.',
                value: 'Dr.'
            },
            {
                title: 'Prof.',
                value: 'Prof.'
            },
            {
                title: 'Prof. Dr.',
                value: 'Prof. Dr.'
            }
        ]
    })
    @Column({ type: 'enum', enum: Title, nullable: true })
    title: Title

    @FormField({ placeholder: 'Verein e.V.', optional: true })
    @Column('varchar', { name: 'institution', length: 255, nullable: true })
    institution: string

    @FormField({ placeholder: 'Mustermann', title: 'lastname' })
    @IsNotEmpty({ always: true })
    @Column({ type: 'varchar', length: 255 })
    lastName: string

    @FormField({ placeholder: 'Max', title: 'firstname' })
    @IsNotEmpty({ always: true })
    @Column({ type: 'varchar', length: 255 })
    firstName: string

    @FormField({ placeholder: '01.01.2020', type: InputType.DATE, endDate: dayjs(new Date()).format('YYYY-MM-DD') })
    @IsNotEmpty({ groups: [GuardianValidation.BIRTHDAY_REQUIRED] })
    @Column('date', { name: 'gb_date', nullable: true })
    birthday: Date | null

    @FormField({ placeholder: 'Stadt', optional: true })
    @Column('varchar', { name: 'geburtsort', length: 100, nullable: true })
    birthplace: string

    @FormField({ placeholder: 'max@mustermann.de', title: 'mail' })
    @IsEmail({}, { always: true })
    @Column({ type: 'varchar', length: 255, nullable: true })
    email: string

    @FormField({ optional: true })
    @Column({ type: 'varchar', length: 100, nullable: true })
    @ValidateIf((context) => context.phone, { always: true })
    @IsPhoneNumber('de', { always: true })
    phone: string

    @FormField()
    @IsNotEmpty({ always: true })
    @Type(() => Address)
    @ValidateNested({ always: true })
    @Column((type) => Address)
    address: Address

    @ValidateNested()
    @ApiProperty({ type: () => BaseUser })
    @ManyToOne((type) => BaseUser, (user) => user.parents)
    @JoinColumn()
    user: BaseUser

    // Should be oneToOne relation but due to production data before this feature is rolled out.
    @OneToMany((type) => AddressByLetterVerification, (verification) => verification.guardian)
    addressByLetterVerification: AddressByLetterVerification

    constructor(init?: Partial<Guardian>) {
        Object.assign(this, init)
        if (!init || Object.keys(init).length === 0 || !init.address) {
            this.address = new Address({})
        } else {
            Object.assign(this.address, init.address)
        }
    }

    fullname(): string {
        return `${this.firstName} ${this.lastName}`
    }
}
