import { ApiProperty } from '@nestjs/swagger'
import { IsBoolean, IsDate } from 'class-validator'

export class PasswordOneTimePinResponseDTO {
    @ApiProperty()
    @IsDate()
    resendAt: Date

    @ApiProperty()
    @IsBoolean()
    existingPin: boolean
}
