import { FormClass, FormField, InputType } from "@stadtlandnetz/forms";
import { IsString } from "class-validator";

@FormClass({defaultGroupName: 'Notes',interfereLabels: true})
export class NotesDto {
    
    @FormField({type: InputType.TEXTAREA})
    notes?: string | null

    constructor(init: NotesDto) {
        Object.assign(this, init)
    }
}