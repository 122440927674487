import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { DocumentTemplate } from '.'
import type { ReplacePosition } from '../types'

/**
 * Fixed text replacement inside a document (similar to a regex)
 */
@Entity('dok_replacements')
export class DocumentReplacement {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => DocumentTemplate, document => document.replacements, {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
    nullable: false
  })
  @JoinColumn({ name: 'dokid' })
  document: DocumentTemplate | null

  @Column('varchar', { name: 'beschreibung', nullable: false, length: 255 })
  description: string

  @Column('text', { name: 'suchenach', nullable: false})
  searchingFor: string

  @Column('text', { name: 'ersetzedurch', nullable: false})
  replaceBy: string

  // @Column({ name: 'beforeafter', nullable: false, type: 'enum', enum: ReplacePosition })
  @Column({name: 'beforeafter', type: 'tinyint', width: 1, nullable: false})
  position: ReplacePosition

  constructor (init?: Partial<DocumentReplacement>) {
    Object.assign(this, init)
  }
}
