import {dayjs, mysqlDateFormatString} from "./dates";

export const pipeListTransformer = {
  from: (raw: string) => {
    if (raw != null) return raw.split('|')
    else return []
  },
  to: (list: string[]) => {
    if (list) return list.join('|')
    else return ''
  }
}

export const slashListTransformer = {
  from: (raw: string) => {
    if (raw != null) return raw.split('/')
    else return []
  },
  to: (list: string[]) => {
    if (list) return list.join('/')
    else return ''
  }
}

/**
 * Please note that this transformer is related to the understanding of MySQL what a date is.
 * In contrast to JavaScript, MySQL stores dates as `YYYY-MM-DD`
 */
export const dateTransformer = {
  from: (raw: string): Date => {
    return new Date(Date.parse(`${raw}T00:00`))
  },
  to: (date: Date): string => {
    return dayjs(date).format(mysqlDateFormatString)
  }
}

export const literalBoolean = {
  from: (raw: string) => {
    return raw === 'True'
  },
  to: (value: boolean) => {
    return value ? 'True' : 'False'
  }
}