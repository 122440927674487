import { ApiProperty } from '@nestjs/swagger'
import { IsString } from 'class-validator'
import type { SingleInfoBox } from './infoBoxes.dto'
import type { Language } from './language.type'

export type LegacyConsentContent = string
export type LegacyConsentCheckboxes = string[]

export class ConsentDataDTO {
    @ApiProperty()
    @IsString()
    content: SingleInfoBox | LegacyConsentContent

    @ApiProperty()
    @IsString()
    checkboxes: { [key in Language]?: string[] } | LegacyConsentCheckboxes
}
