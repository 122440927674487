import { Type } from "class-transformer";
import { IsArray, IsBoolean, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";
import { CoordinatesDto } from "../../utils";

export class AbsentTourTripPerson {
    @IsNumber()
    id: number

    @IsBoolean()
    isStudent: boolean
}

export class TourTripEndTrackingDto {
    @IsNumber()
    tourTripId: number

    @IsNumber()
    @IsOptional()
    vehicleId?: number

    @IsNumber()
    distanceDriven: number

    @IsString()
    duration: string

    @IsOptional()
    @IsString()
    notes?: string | null

    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => CoordinatesDto)
    coordinates: CoordinatesDto[]

    @IsOptional()
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => AbsentTourTripPerson)
    absentPeople?: AbsentTourTripPerson[] | null

    constructor(init?: Partial<TourTripEndTrackingDto>) {
        Object.assign(this, init)
    }
}