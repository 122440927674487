import { request } from './api-resource.base'
import { getHttpOptions } from '../stores/api.stores'
import {
    CreateAddressByLetterVerificationDTO,
    ValidateAddressByLetterAttemptDTO,
    ValidateEmailAttemptDTO
} from '@stadtlandnetz/core'

export type verified = { verified: boolean }
export class UserVerificationResource<T> {
    basePath = '/users/verify/'

    constructor(private endpoint: string) {}

    async status(): Promise<T[]> {
        return request(this.basePath + this.endpoint, getHttpOptions('GET'))
    }

    async start(data?: CreateAddressByLetterVerificationDTO): Promise<T> {
        return request(this.basePath + this.endpoint + '/start', {
            ...getHttpOptions('POST'),
            ...(data ? { body: JSON.stringify(data) } : {})
        })
    }

    async verify(
        dto: ValidateEmailAttemptDTO | ValidateAddressByLetterAttemptDTO,
        hideSnackbar = false
    ): Promise<boolean> {
        return request(
            this.basePath + this.endpoint,
            {
                ...getHttpOptions('POST'),
                body: JSON.stringify(dto)
            },
            hideSnackbar
        )
    }
}
