import { TransportCompanyMember } from '../../companies'
import { Student } from '../../students'
import { Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { TourTripsEndTracking } from '.'

@Entity('touren_fahrten_end_tracking_no_attendees', { schema: 'via' })
export class TourTripsEndTrackingAbsentPerson {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @ManyToOne(type => TourTripsEndTracking, tripEndTacking => tripEndTacking.absentPeople, {
        nullable: false,
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE'
    })
    @JoinColumn({ name: 'touren_fahrten_end_tracking_id' })
    tourTripEndTracking: TourTripsEndTracking

    @ManyToOne(type => Student, {
        nullable: true,
        onUpdate: 'CASCADE'
    })
    @JoinColumn({ name: 'student_id' })
    student: Student | null

    @ManyToOne(type => TransportCompanyMember, {
        nullable: true,
        onUpdate: 'CASCADE'
    })
    @JoinColumn({ name: 'bp_id' })
    companion: TransportCompanyMember | null

    constructor(init?: Partial<TourTripsEndTrackingAbsentPerson>) {
        Object.assign(this, init)
    }
}