import { ApiResource, DefaultOptions, request } from './api-resource.base'
import { BaseUser, DeleteAccountResultDto, FeedbackDto, Guardian, Language, Student } from '@stadtlandnetz/core'
import { getHttpOptions } from '../stores/api.stores'

export class UserResource extends ApiResource<BaseUser, DefaultOptions> {
    constructor() {
        super('users');
    }

    async getStudents(): Promise<Student[]> {
        return request(`/users/students`, getHttpOptions('GET'))
    }

    async getGuardians(): Promise<Guardian[]> {
        return request(`/users/guardians`, getHttpOptions('GET'))
    }

    async deleteOrDeactivateAccount(password: string): Promise<DeleteAccountResultDto> {
        return request(`/users/deleteAccount`, {
            ...getHttpOptions('POST'),
            body: JSON.stringify({ password }),
        })
    }

    async sendFeedback(feedback: FeedbackDto): Promise<boolean> {
        return request('/users/sendFeedback', {
            ...getHttpOptions('POST'),
            body: JSON.stringify(feedback)
        })
    }

    async setLanguage(language: Language): Promise<boolean> {
        return request('/users/language', {
            ...getHttpOptions('POST'),
            body: JSON.stringify({ language })
        })
    }
}
