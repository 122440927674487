export enum FamilyStatus {
    'LD' = 'ledig',
    'VH' = 'verheiratet',
    'VW' = 'verwitwet',
    'GS' = 'geschieden',
    'EA' = 'Ehe aufgehoben',
    'LP' = 'in eingetragener Lebenspartnerschaft',
    'LV' = 'durch Tod aufgelöste Lebenspartnerschaft',
    'LA' = 'aufgehobene Lebenspartnerschaft',
    'LE' = 'durch Todeserklärung aufgelöste Lebenspartnerschaft',
    'NB' = 'nicht bekannt'
}
