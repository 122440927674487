import {  Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { Lot, Tender } from '..'
import { TenderVehicleSet } from '.'
import { TransportCompany } from '../../../companies/entities'

@Entity('vergaben_kfz_nutzung_modul2')
export class TenderVehicleUsage {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @ManyToOne(type => Lot, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'losid' })
  lot: Lot

  @ManyToOne(type => TenderVehicleSet, {
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'SET NULL'
  })
  @JoinColumn({ name: 'setid' })
  set: TenderVehicleSet

  @ManyToOne(type => TransportCompany, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'vuid' })
  company: TransportCompany

  @ManyToOne(type => Tender, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'vergabeid' })
  tender: Tender

  constructor (init?: Partial<TenderVehicleUsage>) {
    Object.assign(this, init)
  }
}
