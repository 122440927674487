import { Language } from '../system'

export function getTranslationString(
    obj: { [key in Language]?: string } | null | undefined,
    lang: Language
): string | undefined {
    return obj?.[lang] ?? obj?.[Language.GERMAN]
}

export function getTranslationObject<T>(
    obj: { [key in Language]?: T } | null | undefined,
    lang: Language
): T | undefined {
    return obj?.[lang] ?? obj?.[Language.GERMAN]
}
