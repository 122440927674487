export enum WeekDays {
    MONDAY = 'mo',
    TUESDAY = 'di',
    WEDNESDAY = 'mi',
    THURSDAY = 'do',
    FRIDAY = 'fr',
    SATURDAY = 'sa',
    SUNDAY = 'so'
}

export const weekDaysTemplate = new Map<number, WeekDays>([
    [0, WeekDays.SUNDAY],
    [1, WeekDays.MONDAY],
    [2, WeekDays.TUESDAY],
    [3, WeekDays.WEDNESDAY],
    [4, WeekDays.THURSDAY],
    [5, WeekDays.FRIDAY],
    [6, WeekDays.SATURDAY]
]);