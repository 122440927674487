import {  Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { TicketName } from '.'
import {FareZoneConnectionsVersion} from "./FareZoneConnectionsVersion.entity";

@Index('id', ['id'], { unique: true })
@Entity('ticket_types')
export class TicketType {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @ManyToOne(type => FareZoneConnectionsVersion, { nullable: false, onUpdate: 'CASCADE', onDelete: 'NO ACTION' })
  @JoinColumn({ name: 'version_id' })
  versionId: number

  @ManyToOne(type => TicketName, name => name.types, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({name: "ticket_type"})
  name: TicketName

  @Column('varchar', { name: 'fare_zone', length: "100" })
  fareZone: string

  constructor (init?: Partial<TicketType>) {
    Object.assign(this, init)
  }
}
