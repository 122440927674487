import { ApiProperty } from "@nestjs/swagger"
import { IsNotEmpty, IsString } from "class-validator"

export class SchoolAffiliationLoginDTO {
    @ApiProperty({ example: 322 })
    @IsString()
    @IsNotEmpty()
    schoolId: number

    @ApiProperty({ example: '123456' })
    @IsString()
    @IsNotEmpty()
    pin: string
}
