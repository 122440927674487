import {

  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn
} from 'typeorm'
import { ValidTimespanGerman, VariableFields } from '../../utils'
import { User } from '../../users'
import {TourenPersonenKfz, TourFinances} from '.'
import { pipeListTransformer } from '../../utils/transformers'
import { Complain, TransportCompany } from '../../companies'
import { TripRequirement } from '../../routing'

@Index('id_3', ['id'], { unique: true })
@Index('id', ['id'], {})
@Entity('touren')
export class Tour {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @Index({ unique: true })
  @Column('varchar', { name: 'tour',length: 255 })
  name: string
  
  @OneToOne(type => TourFinances, tourFinances => tourFinances.tour)
  finances: TourFinances

  // this is just a string for holding information
  @Column('varchar', { name: 'kapazität', nullable: true, length: 255 })
  capacity: string | null

  @ManyToOne(type => TransportCompany, transportCompany => transportCompany.tours, {
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({name: 'unternehmen', referencedColumnName: 'short' })
  company: TransportCompany

  @ManyToOne(type => TransportCompany, transportCompany => transportCompany.toursAsSub, {
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({name: 'vu_sub', referencedColumnName: 'short' })
  subCompany: TransportCompany

  // Day at which the contract cannot be extended anymore by the authority
  @Column('date', { name: 'max_laufzeit', nullable: true })
  maximumTermOfValidity: string | null

  @Column('tinyint', { name: 'leer', nullable: true, width: 1 })
  empty: boolean | null

  @Column('text', { name: 'bemerkungen', nullable: true })
  notes: string | null

  @Column('varchar', { name: 'lk', nullable: true, length: 255})
  county: string | null

  @Column('tinyint', {
    name: 'removed',
    nullable: false,
    width: 1,
    default: false
  })
  removed: boolean

  // If there are many, they are split by this char //
  @Column({
    type: 'text',
    name: 'attribute',
    nullable: true,
    transformer: pipeListTransformer
  })
  attributes: string

  // Deprecated!!! Was a relation to Driver by name :(
  @Column('text', { name: 'fahrer', nullable: true })
  driver: string | null

  // Deprecated!!! Was a relation to Vehicle by license plate (would have a specific format)
  @Column('text', { name: 'fahrzeug', nullable: true })
  vehicle: string | null

  @Column('varchar', { name: 'begleitperson', nullable: true, length: 255})
  companion: string | null

  @Column(type => ValidTimespanGerman)
  valid: ValidTimespanGerman

  @Column('text', { name: 'tags', nullable: true, transformer: pipeListTransformer})
  tags: string | null

  @ManyToOne(type => User, user => user.tours, {
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'SET NULL'
  })
  @JoinColumn({name: 'bearbeiter'})
  clerk: User | null

  @Column('text', { name: 'notizen_vu_public', nullable: true })
  publicNotesForTransportCompany: string | null

  @Column('text', { name: 'notizen_vu_private', nullable: true })
  privateNotesForTransportCompany: string | null

  @Column('timestamp', {
    name: 'lastChanged',
    default: () => 'CURRENT_TIMESTAMP'
  })
  lastChanged: Date

  @OneToMany(type => TourenPersonenKfz, person => person.tour)
  people: TourenPersonenKfz[]

  @Column(type => VariableFields)
  variableFields: VariableFields

  @OneToMany(type => Complain, complain => complain.tour)
  complains: Complain[]

  @OneToMany(type => TripRequirement, requirement => requirement.tour)
  requirements: TripRequirement[]

  constructor (init?: Partial<Tour>) {
    Object.assign(this, init)
  }
}
