import { getHttpOptions } from '../stores/api.stores'
import { getEndpointUrl, request } from "./api-resource.base"
import {
    ConsentDataDTO,
    CustomerConfigurationDto,
    CategoryFrequentlyAskedQuestions,
    SingleEvaluationResultText,
    System,
    VariableField,
    TransportRequestType
} from '@stadtlandnetz/core'

export class SystemResource {
    async getFooterData(): Promise<System[]> {
        return request('/system/footerData', { ...getHttpOptions('GET') })
    }

    async getConsentData(): Promise<ConsentDataDTO> {
        return request('/system/consentData', { ...getHttpOptions('GET') })
    }

    async getCustomerConfiguration(): Promise<CustomerConfigurationDto> {
        return request('/system/customerConfiguration', { ...getHttpOptions('GET') })
    }

    async getVarFieldsConfiguration(key: string, transportRequestType: TransportRequestType): Promise<VariableField[]> {
        const url = getEndpointUrl(`/system/varFieldsConfiguration/${key}`, { transportRequestType })
        return request(url, { ...getHttpOptions('GET') })
    }

    async getVarFieldsConfigurationForSchoolAffiliation(): Promise<{
        [key in TransportRequestType]: VariableField[]
    }> {
        const url = getEndpointUrl(`/system/varFieldsConfigurationForSchoolAffiliation`)
        return request(url, { ...getHttpOptions('GET') })
    }

    async getEvaluationResultText(transportRequestType: TransportRequestType): Promise<SingleEvaluationResultText> {
        return request('/system/evaluationText', {
            ...getHttpOptions('POST'),
            body: JSON.stringify({ transportRequestType })
        })
    }

    async getFrequentlyAskedQuestions(): Promise<CategoryFrequentlyAskedQuestions[]> {
        return request('/system/frequentlyAskedQuestions', { ...getHttpOptions('GET') })
    }
}
