import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { ValidTimespanRequired } from '../../utils'
import { TransportCompanyMember, TransportCompanyVehicle } from '.'

@Index('id', ['id'], { unique: true })
@Entity('unternehmen_kfz_fahrer', { schema: 'via' })
export class TransportCompanyVehicleDriver {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @ManyToOne(type => TransportCompanyVehicle, vehicle => vehicle.drivers, {
        nullable: false
    })
    @JoinColumn({ name: 'kfzID' })
    vehicle: TransportCompanyVehicle

    @ManyToOne(type => TransportCompanyMember, driver => driver.vehicles, {
        nullable: false
    })
    @JoinColumn({ name: 'fahrerID' })
    driver: TransportCompanyMember

    @Column(type => ValidTimespanRequired)
    valid: ValidTimespanRequired

    constructor(init?: Partial<TransportCompanyVehicleDriver>) {
        Object.assign(this, init)
    }
}