import {ValidTimespan} from "../../utils";
import {Column, Entity, JoinColumn, JoinTable, ManyToMany, ManyToOne, OneToMany, PrimaryGeneratedColumn} from "typeorm";
import { SchoolClass, SchoolType} from "../../schools/entities";
import { School } from '../../schools/entities'
import {EvaluationSchema} from "./EvaluationSchema.entity";
import {TransportRequest} from "../../transport-request";

@Entity()
export class EvaluationSchemaAssignment {
    @PrimaryGeneratedColumn({ type: 'int' })
    id: number

    @ManyToOne(
        type => School,
        school => school.evaluationSchemaAssignments,
        { nullable: false }
    )
    school: School

    @ManyToOne(
        type => EvaluationSchema,
        evaluationSchema => evaluationSchema.assignments,
        { nullable: false, eager: true }
    )
    @JoinColumn({ name: 'schemaId' })
    schema: EvaluationSchema

    @Column({ name: 'schemaId', type: "int", width: 10, unsigned: true, nullable: false })
    schemaId: number

    @Column(type => ValidTimespan)
    validTimespan: ValidTimespan

    // Here, we link to "concrete" instances of classes and types.
    // This way, changes to classes and types will be reflected here.
    @ManyToMany(type => SchoolClass, { eager: true })
    @JoinTable({ name: 'evaluation_schema_assignments_classes'})
    classes: SchoolClass[]

    @ManyToMany(type => SchoolType, { eager: true })
    @JoinTable({ name: 'evaluation_schema_assignments_school_types' })
    schoolTypes: SchoolType[]

    @OneToMany(
        type => TransportRequest,
        transportRequest => transportRequest.usedEvaluationSchemaAssignment
    )
    transportRequests: TransportRequest[]

    // Checks if the schema assignment is currently in use
    isInUse(date: Date = new Date()): Boolean {
        return this.validTimespan.contains(date)
    }

    constructor(init: Partial<EvaluationSchemaAssignment> & Pick<EvaluationSchemaAssignment, 'school' | 'schema'>) { Object.assign(this, init) }
}