import { Column } from 'typeorm'
import type { StopType } from '../../transport'

// TODO: Common base class
export class TripStopTo {
  @Column('tinyint',{
    name: 'hst_hin_art',
  })
  type: StopType

  @Column('text', { name: 'hst_hin_lat' })
  latitude: string

  @Column('text', { name: 'hst_hin_lng' })
  longitude: string
}

export class TripStopReturn {
  @Column('tinyint',{
    name: 'hst_rueck_art',
  })
  type: StopType

  @Column('text', { name: 'hst_rueck_lat' })
  latitude: string

  @Column('text', { name: 'hst_rueck_lng' })
  longitude: string
}