import {  Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import { TransportCompany } from '../../companies'
import { ValidTimespan } from '../../utils'
import { PublicTransportRelationStops } from '.'
import { Stop } from '../../transport/'


@Entity('oepnv_relationen')
export class PublicTransportRelation {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @Column('text', { name: 'rel_nr', nullable: true })
  relationNumber: string | null

  @ManyToOne(type => Stop, {
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({name: 'einstieg_hst_id'})
  entrys: Stop

  @ManyToOne(type => Stop, {
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({name: 'austieg_hst_id'})
  exits: Stop

  @ManyToOne(type => TransportCompany, (company: TransportCompany) => company.relations, {
    onUpdate: 'CASCADE',
    onDelete: 'SET NULL',
    nullable: true
  })
  @JoinColumn({ name: 'vu_id' })
  transportCompany: TransportCompany

  @Column('text', { name: 'linie', nullable: true })
  linie: string | null

  @Column('decimal', { name: 'preis', nullable: false, precision: 6, scale: 2 })
  price: string | null

  @Column({ type: 'varchar', name: 'fahrplan_km', length: 255, nullable: true })
  kilometer: string | null

  @Column('tinyint', { name: 'ist_extern', nullable: false, default: 0, width: 1 })
  isExternal: boolean

  /*
  deprecated
  @Column('text', { name: 'migration_einstieg', nullable: true })
  migrationEinstieg: string | null
  @Column('text', { name: 'migration_ausstieg', nullable: true })
  migrationAusstieg: string | null
  
  */
  @Column('text', { name: 'bemerkung', nullable: true })
  notes: string | null

  @Column('tinyint', { name: 'disabled', nullable: true, width: 1, default: 0 })
  disabled: boolean

  @Column(type => ValidTimespan)
  valid: ValidTimespan

  @OneToMany(type => PublicTransportRelationStops, stop => stop.relation)
  stops: PublicTransportRelationStops

  constructor (init?: Partial<PublicTransportRelation>) {
    Object.assign(this, init)
  }
}
