import { ApiProperty } from "@nestjs/swagger";
import { FormClass, FormField, InputType } from "@stadtlandnetz/forms";
import { IsNotEmpty, IsString } from "class-validator";

@FormClass()
export class FeedbackDto {
    @ApiProperty({example: 'Good user experience!'})
    @FormField({ type: InputType.TEXTAREA, title: 'feedback', oneliner: true, rows: 8, max: 1000, resizable: false })
    @IsString()
    @IsNotEmpty()
    content: string

    constructor(init: Partial<FeedbackDto>) { Object.assign(this, init) }
}