import {  Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { Lot } from '.'
import { Tour } from '../../tours/entities'

@Entity('lose_touren')
export class LotTourConnection {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => Lot, { 
    nullable: false,
onUpdate: 'CASCADE',
onDelete: 'CASCADE'
  })
  @JoinColumn({name: 'losid'})
  lot: Lot

  @ManyToOne(type => Tour, { 
    nullable: false,
   onUpdate: 'CASCADE',
onDelete: 'RESTRICT' 
  })
  @JoinColumn({name: 'tourid'})
  tourid: number

  constructor (init?: Partial<LotTourConnection>) {
    Object.assign(this, init)
  }
}
