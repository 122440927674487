import { FormClass, FormField, InputType } from "@stadtlandnetz/forms"
import { IsNotEmpty } from "class-validator"
import type { School, SchoolClass } from "../../schools/entities"
import type { Student } from "../entities"

@FormClass({interfereLabels: true})
export class Sibling {
    @IsNotEmpty()
    studentId: number

    @FormField({placeholder: 'Peter', title: 'firstname'})
    @IsNotEmpty()
    firstName: string

    @FormField({placeholder: 'Lustig', title: 'lastname'})
    @IsNotEmpty()
    lastName: string

    @FormField({placeholder: '01.01.2020', type: InputType.DATE})
    @IsNotEmpty()
    birthday: Date | null

    // It is a choice for now to hide this two fields and VIA will take care of this information
    // we will keep only to add an entry to tbl_geschwister table 
    /* @FormField({
        type: InputType.DROPDOWNSEARCH,
        title: 'schoolName'
    }) */
    // @IsNotEmpty() this line should be enabled once the ListView is re-written
    // @IsString({always: true})
    school: School

    /* @FormField({
        type: InputType.SELECT,
        title: 'schoolClass'
    }) */
    // @IsNotEmpty() this line should be enabled once the ListView is re-written
    class: SchoolClass

    constructor(init?: Partial<Sibling>) {
        Object.assign(this, init)
    }

    static fromStudent(student: Student): Sibling {
        return new Sibling({
            firstName: student.firstName,
            lastName: student.lastName,
            studentId: student.id,
            birthday: student.birthday
        })
    }
}