import { ApiProperty } from '@nestjs/swagger'
import { IsEmail, IsString } from 'class-validator'

export class OneTimePinPayloadDTO {
    @ApiProperty({ example: 'parent@via.de' })
    @IsEmail()
    baseUserEmail: string

    @ApiProperty({ example: '123456' })
    @IsString()
    oneTimePin: string
}
