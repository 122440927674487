import { Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryColumn } from 'typeorm'
import { BaseUser } from '../../users/entities'
import { UploadType } from '../types'
import { Message } from '../../messages/entities'
import { UserFileInteraction } from '../../messages/entities/UserFileInteraction.entity'
import { TransportRequest, TransportRequestDraft } from '../../transport-request/entities'

/**
 * A file is a binary blob that gets saved to some storage backend.
 */
@Entity()
export class File {
    @PrimaryColumn({ type: 'varchar', length: 255, nullable: false })
    path: string

    @Column({ type: 'enum', enum: UploadType, nullable: false })
    type: UploadType

    @ManyToOne((type) => BaseUser, (user) => user.files, { nullable: true })
    @JoinColumn({ name: 'userId' })
    user: BaseUser

    @ManyToOne((type) => TransportRequestDraft, (draft) => draft.files, {
        nullable: true,
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE',
    })
    @JoinColumn({ name: 'draftId' })
    draft: TransportRequestDraft

    @ManyToOne((type) => TransportRequest, (request) => request.files, {
        nullable: true,
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE',
    })
    @JoinColumn({ name: 'requestId' })
    request: TransportRequest

    @ManyToOne((type) => Message, (message) => message.attachments, { nullable: true, cascade: true })
    message: Message

    @OneToMany((type) => UserFileInteraction, (interaction) => interaction.file)
    userInteractions: UserFileInteraction[]

    constructor(init: Partial<File>) {
        Object.assign(this, init)
    }
}
