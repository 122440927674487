import {  Column, Entity, Index, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import { ValidTimespan, VariableFields } from '../../utils'
import { Ticket } from '.'
import { Stop } from '../../transport/'
import { TransportRequest } from '../../transport-request'

@Index('id', ['id'], { unique: true })
@Entity('tbl_oepnv_fahrtanforderung')
export class PublicTransportRequirement {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @ManyToOne(type => TransportRequest, (request => request.publicTransportRequirements ), {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'antrag_id'})
  transportRequest: TransportRequest

  @ManyToOne(type => Stop, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({ name: 'einstieg'})
  entry: Stop

  @ManyToOne(type => Stop, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({ name: 'ausstieg'})
  exit: Stop

  @Column(type => ValidTimespan)
  valid: ValidTimespan
/* deprecated
  @ManyToOne(
    type => TransportCompany,
      company => company.publicTransportRequirements,
    { nullable: true,
   onUpdate: 'CASCADE',
   onDelete: 'RESTRICT' 
    }
  )
  @JoinColumn({name: 'unternehmen'})
  transportCompany: TransportCompany
*/
  // deprecated
  /*
  @Column('text', { name: 'linie', nullable: true })
  line: string | null
  */

  @Column('text', { name: 'bemerkungen', nullable: true })
  notes: string | null

  @Column(type => VariableFields)
  variableFields: VariableFields

  // deprecated
  /*
  @Column('int', { name: 'gewaehlteVerbindung', nullable: true })
  chosen: number | null

  @Column('int', { name: 'alte_antrag_id' })
  alteAntragId: number
  */

  @OneToMany(type => Ticket, ticket => ticket.requirement)
  tickets: Ticket 

  constructor (init?: Partial<PublicTransportRequirement>) {
    Object.assign(this, init)
  }
}
