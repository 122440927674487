import {

  Column,
  Entity,
  Index,
  PrimaryGeneratedColumn
} from 'typeorm'

@Entity('system_anträge_ea')
export class SystemRequestCopayment {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @Column('varchar', { name: 'nameStufe', length: 100 })
  name: string

  // Absolute value in euros
  @Column('decimal', { name: 'preisMonat', precision: 10, scale: 0 })
  monthlyCopayment: number

  constructor (init?: Partial<SystemRequestCopayment>) {
    Object.assign(this, init)
  }
}
