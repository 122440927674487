import {

  Column,
  Entity,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn
} from 'typeorm'
import { TransportRequestReview } from '.'

@Entity('antraege_pruefungen_notwendigkeit')
export class NecessityReview {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(
    () => TransportRequestReview,
    requestReview => requestReview.necessityReviews,
    { onDelete: 'CASCADE', onUpdate: 'CASCADE', nullable: false }
  )
  @JoinColumn([{ name: 'pruefung_id', referencedColumnName: 'id' }])
  requestReview: TransportRequestReview

  @Column('tinyint', { name: 'anderes_bl', width: 1, nullable: false, default: false})
  otherState: boolean

  @Column('tinyint', {
    name: 'gefaehrlicher_schulweg',
    default: false,
    width: 1
  })
  dangerousWayToSchool: boolean

  // Improve: Decimal
  @Column('float', { name: 'ergebnis_mindestentfernung', precision: 12 })
  resultMinimumDistance: number

  @Column('tinyint', {
    name: 'ergebnis_notwendigkeit',
    default: false,
    width: 1
  })
  needed: boolean | null

  constructor (init?: Partial<NecessityReview>) {
    Object.assign(this, init)
  }
}
