import {  Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { Document, DocumentTemplate } from '.'
import type { SqlQuery } from '../../utils'

// Document
@Entity('dok_tabellen')
export class Table {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  // Improve: Not nullable
  @ManyToOne(type => DocumentTemplate, document => document.tables, { 
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
    nullable: true 
  })
  @JoinColumn({ name: 'dokid' })
  document: Document

  // Improve: Better datatype (varchar), not nullable
  @Column('mediumtext', { name: 'name', nullable: true })
  name: string | null

  @Column('mediumtext', { name: 'beschreibung', nullable: true })
  description: string | null

  // Improve: Not nullable
  @Column('tinyint', { name: 'register', nullable: true, width: 1 })
  register: boolean | null

  // Improve: Not nullable
  @Column('tinytext', { name: 'referenz', nullable: true })
  reference: string | null

  // Improve: Not nullable
  @Column('mediumtext', { name: 'ssql', nullable: true })
  selectSql: SqlQuery | null

  // Kind of a sql hook
  @Column('longtext', { name: 'sql_execute', nullable: true })
  sqlExecute: string | null

  constructor (init?: Partial<Table>) {
    Object.assign(this, init)
  }
}
