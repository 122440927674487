import { IsEnum, IsNumber, IsObject, IsOptional, IsString, ValidateNested } from 'class-validator'
import { DMS } from './dms.enum'

class Proxy {
    @IsString()
    host: string

    @IsNumber()
    port: number
}

class DMSUrl {
    @IsOptional()
    @IsString()
    login?: string

    @IsString()
    upload: string
}

class DMSCredentials {
    @IsOptional()
    @IsString()
    username?: string

    @IsOptional()
    @IsString()
    password?: string

    @IsOptional()
    @IsString()
    token?: string
}

export class DmsParams {
    @IsOptional() @IsEnum(DMS) dms?: DMS
    @IsOptional() @IsString() dms_xml?: string
    @IsOptional() @ValidateNested() dms_url?: DMSUrl
    @IsOptional() @ValidateNested() dms_proxy?: Proxy
    @IsOptional() @ValidateNested() dms_credentials?: DMSCredentials
    @IsOptional() @IsObject() dms_json?: Record<string, any>
}
