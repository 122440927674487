import {  Column, Entity, Index, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import { DocumentContent, DocumentReplacement, DocumentStyle, Placeholder, Stack, Table } from '.'
import { DocumentTemplateVisibility } from '../types'

@Index(['name', 'level'], {unique: true})
@Entity('dok')
export class DocumentTemplate {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @Column('varchar', { name: 'name', nullable: false, length: 255 })
  name: string

  @Column('mediumtext', { name: 'beschreibung', nullable: true })
  description: string | null

  @Column('varchar', { name: 'level', nullable: true, length: 100 })
  level: string | null

  @Column('int', { name: 'disallow_edit', nullable: false, default: 0 })
  disallowEdit: number | null

  // @Column({ name: 'visibility', default: DocumentTemplateVisibility.VISIBLE, type: 'enum', enum: DocumentTemplateVisibility })
  @Column({name: 'visibility', type: 'tinyint', width: 1, default: DocumentTemplateVisibility.VISIBLE})
  visibility: DocumentTemplateVisibility

  @Column({ type: 'boolean', nullable: true, width: 1, default: false})
  showInMyvia: boolean

  @OneToMany(type => DocumentContent, documentContent => documentContent.document, {nullable: false})
  content: DocumentContent[]

  @OneToMany(type => DocumentStyle, documentStyle => documentStyle.document)
  styles: DocumentStyle[]

  @OneToMany(type => DocumentReplacement, replacement => replacement.document)
  replacements: DocumentReplacement[]

  @OneToMany(type => Placeholder, placeholder => placeholder.document)
  placeholders: Placeholder[]

  @OneToMany(type => Stack, stack => stack.document)
  stacks: Stack[]

  @OneToMany(type => Table, table => table.document )
  tables: Table[]

  constructor (init?: Partial<DocumentTemplate>) {
    Object.assign(this, init)
  }
}

