let colorKeys = [
    '--color-primary',
    '--color-primary-hover',
    '--color-primary-light',
    '--color-primary-lighter',
    '--color-primary-lightest',
    '--color-dark'
]
let defaultTheme = {}, customTheme = {}
let defaultThemeLoaded = false, customThemeLoaded = false
let listeners = []

// First call loads default theme, second call loads custom theme
export function loadThemes() {
    let defaultStyle = getComputedStyle(document.documentElement)
    for (const key of colorKeys) {
        let value = defaultStyle.getPropertyValue(key).toUpperCase()
        if (!defaultThemeLoaded)
            defaultTheme[key] = value.slice(1)
        else
            customTheme[key] = value.slice(1)
    }

    if (defaultThemeLoaded) {
        customThemeLoaded = true
        listeners.forEach(icon => processIcon(icon))
    }
    defaultThemeLoaded = true
}

export function svgLoader(icon) {
    processIcon(icon)
    return { destroy() { icon.removeEventListener('load', loadListener) } }
}

function loadListener(event) {
    processIcon(event.target)
}

function processIcon(icon) {
    if (!customThemeLoaded) {
        listeners.push(icon)
    } else {
        icon.removeEventListener('load', loadListener)
        colorKeys.forEach(key => {
            icon.src = icon.src?.replaceAll(defaultTheme[key], customTheme[key])
        })
        icon.onload = () => { icon.addEventListener('load', loadListener); icon.onload = null }
    }
}