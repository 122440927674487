import {  Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import { UserRole } from '.'
import type { Actions } from '../types/Actions'
import type { Modules } from '../../utils'

@Index('id', ['id'], { unique: true })
@Entity('user_permissions')
export class Permission {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @ManyToOne(type => UserRole, role => role.permissions, { 
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'rolle_id' })
  role: UserRole

  // @Column({ name: 'modul', type: 'enum', enum: Modules, nullable: false })
  @Column({ name: 'modul', type: 'varchar', length: 100, nullable: false })
  module: Modules

  // @Column({ name: 'action', type: 'enum', enum: Actions, nullable: false })
  @Column({ name: 'action', type: 'varchar', length: 100, nullable: false })
  action: Actions

  // there are entries for (user x module), even if denied
  @Column('tinyint', { name: 'value', nullable: false, width: 1 })
  value: boolean

  constructor (init?: Partial<Permission>) {
    Object.assign(this, init)
  }
}
