import {  Column, Entity, PrimaryGeneratedColumn } from 'typeorm'

@Entity('api_get')
export class ApiGet {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @Column('text', { name: 'fn_name', nullable: true })
  fnName: string | null

  @Column('mediumtext', { name: 'Ssql', nullable: true })
  selectsql: string | null

  @Column('mediumtext', { name: 'parameter', nullable: true })
  parameter: string | null

  @Column('tinyint', { name: 'active', nullable: true, width: 1 })
  active: boolean | null

  @Column('text', { name: 'api', comment: 'set, get, setjson' })
  api: string

  @Column('mediumtext', { name: 'pattern', nullable: true})
  pattern: string

  constructor (init?: Partial<ApiGet>) {
    Object.assign(this, init)
  }
}
