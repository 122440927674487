import {  Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import { LotMeta, Tender, TenderBid } from '.'
import { System } from '../../system'

@Entity('lose')
export class Lot {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  @ManyToOne(type => Tender, tender => tender.lots, { 
    nullable: false,
 onUpdate: 'CASCADE',
onDelete: 'CASCADE' 
  })
  @JoinColumn({ name: 'vergabeid' })
  tender: Tender

  @OneToMany(type => LotMeta, lotMeta => lotMeta.lot)
  information: LotMeta[]

  @Column('varchar', { name: 'losnr', length: 100 })
  number: string

  @Column('varchar', { name: 'losname', length: 100 })
  name: string

  @Column('text', { name: 'beschreibung' })
  description: string

  @Column('decimal', { name: 'kosten_tag', precision: 10, scale: 0 })
  pricePerDay: number

  @Column('decimal', { name: 'kosten_jahr', precision: 10, scale: 0 })
  pricePerYear: number

  @Column('tinyint', { name: 'fahrt_hin', width: 1 })
  tripTo: boolean
  @Column('tinyint', { name: 'fahrt_rück', width: 1 })
  tripReturn: boolean

  @Column('tinyint', { name: 'begleitperson', width: 1 })
  includesCompanion: boolean

  @Column('tinyint', { name: 'ergebnis_wertung', width: 1, nullable: false, default: false })
  result: boolean
  @Column('text', { name: 'ergebnis_wertung_begründung' })
  resultReason: string

  @ManyToOne(type => TenderBid,{
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({name: 'zuschlag_angebot_id'})
  winningBid: TenderBid

  /**
   * Id in table `System`. Number of seats needed to fulfill this lot (e.g. 4 sitting seats + 4 for wheelchairs)
   */
  @ManyToOne(type => System, {
    nullable: true,
    onUpdate: 'CASCADE',
    onDelete: 'RESTRICT'
  })
  @JoinColumn({ name: 'kapazitäts_set' })
  capacitySet: System

  constructor (init?: Partial<Lot>) {
    Object.assign(this, init)
  }
}
