import { BaseUserID } from "@stadtlandnetz/core"
import { loading, loadSubmittedRequests, loadStudents } from "../stores/api.stores"
import { api } from "../services/api.service"
import { TransportRequestFormDTO } from "../data/convert"
import { error } from "../stores/data.stores"

export async function saveRequest(dto: TransportRequestFormDTO, userId: BaseUserID, draftId?: number): Promise<boolean> {
    let success = false
    try {
        loading.set('blocking')
        success = await api.transportRequests.saveFromDTO(dto, userId, draftId)
    } catch (e) {
        console.error(e)
        error.set(e)
    } finally {
        if (success) {
            await Promise.all([
                loadSubmittedRequests(),
                loadStudents()
            ])
        }
        loading.set(undefined)
        return success
    }
}