import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'

// Build base configuration of dayjs will all plugins that we will probably need everywhere
dayjs.extend(isoWeek)

export {
    dayjs
}

export const mysqlDateFormatString = 'YYYY-MM-DD'

export type Weekday = "monday" | "tuesday" | "wednesday" | "thursday" | "friday"

export function lessThanMinutesAgo(timestamp: Date, minutes: number): boolean {
    return +new Date() - +new Date(timestamp) <= minutes * 60 * 1000
}
