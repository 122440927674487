import type { GenericRouteType } from '../../transport';
import {
    Column,
    Entity,
    JoinColumn,
    OneToOne,
    PrimaryGeneratedColumn
} from 'typeorm'
import { TransportRequest } from './TransportRequest.entity';
import type { RequestNearestSchool } from '../../evaluation-schema';

@Entity('tbl_antraege_evaluation_logs')
export class TransportRequestEvaluationLogs {

    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @OneToOne(type => TransportRequest, request => request.evaluationLogs, {
        nullable: false,
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE'
    })
    @JoinColumn({ name: 'antragid' })
    request: TransportRequest

    @Column({ type: 'datetime', nullable: true})
    createdAt: Date

    // This is the status of the request when it is saved from myVia
    @Column('text', { nullable: true })
    resultNeed: string

    @Column('boolean', { nullable: true })
    resultReasonableness: boolean

    @Column('boolean', { nullable: true })
    isNearestSchool: boolean | null

    @Column('text', { nullable: true })
    distanceToChosenSchool: number | null

    @Column('json', { nullable: true })
    nearestSchools: RequestNearestSchool[] | null

    @Column('boolean', { nullable: true })
    minimumDistanceFulfilled: boolean | null

    @Column({ type: 'datetime', nullable: true})
    schoolAffiliationDate: Date

    @Column('json', { nullable: true })
    routeToSchool: GenericRouteType | null

    constructor(init?: Partial<TransportRequestEvaluationLogs>) {
        Object.assign(this, init)
    }
}
