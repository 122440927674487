import type { SimpleErrors, DataClass } from './types'
import type { Form } from "./form";
import { validateSync } from 'class-validator'

export function validateForm(form: Form, dto: Object, validationGroups?: string[]) {
    const rawErrors = validateSync(dto, { groups: validationGroups })
    const simpleErrors = convertErrors(rawErrors)

    // Only return errors for fields which are actually part of the form
    const fieldIds = form.fields.filter(field => !field.hidden).map(field => field.id)
    return fieldIds
        .reduce((obj, key) => ({
            ...obj,
            [key]: simpleErrors[key]
        }), {})
}

export function gatherErrors(errors: any[]) {
    let list = []
    for (const error of errors) {
        if (error.children && error.children.length > 0)
            list = [...list, ...gatherErrors(error.children)]
        else
            list.push([error.property, Object.values(error.constraints)])
    }
    return list
}

/**
 * Converts the errors returned by `class-validator` to a simpler format
 * @param errors Object returned by the `validate` and `validateSync` functions of `class-validator`
 */
export function convertErrors(errors: any[]): SimpleErrors {
    // @ts-ignore
    return Object.fromEntries(gatherErrors(errors))
}

/**
 * Validate the form values using the explicitly defined validators in the form config.
 * @param data Current form values
 * @param config
 */
// function validateUsingFunctions (data: object, config: ValidationInput): SimpleErrors {
//   const errors = {}
//   for (const [key, value] of Object.entries(data)) {
//     errors[key] = config[key].validators.map(validationFunction => validationFunction(value))
//   }
//   return errors
// }

/**
 * Validate the form values using `class-validator`.
 * @param data Current form values
 * @param DTO A class decorated with `class-validator`
 */
function validateUsingDecorators(data: object, DTO: DataClass): SimpleErrors {
    const dto = new DTO(data)
    return null
    // return convertErrors(validateSync(dto))
}
