import { apiBaseUrl, getHttpOptions } from '../stores/api.stores'
import { request } from './api-resource.base'
import { Document } from '@stadtlandnetz/core'

export class DocumentsResource {

    async getTransportRequestDecisionDocuments(transportRequestId: number):
        Promise<Partial<Document>[]> {
        return request(`documents/requests/${transportRequestId}`, {
            ...getHttpOptions('GET')
        })
    }

    async downloadRequestDecisionDocument(documentId: string): Promise<Response> {
        return fetch(new URL(`documents/requests/download/${documentId}`, apiBaseUrl).toString(), {
            ...getHttpOptions('GET'),
        })
    }
}