import { ApiProperty } from "@nestjs/swagger"
import type { meters } from "../utils"
import type { RequestNearestSchool } from "../evaluation-schema"

export class ClosestSchoolsResultDto {
    @ApiProperty()
    closestSchoolId: number

    @ApiProperty()
    distanceToClosestSchool: meters

    @ApiProperty()
    closestSchools: RequestNearestSchool[]
}