export type milliseconds = number
export type seconds = number
export type minutes = number
export type hours = number

export type meters = number
export type kilometer = number


export function displayAsKilometer(x: meters): string {
    return (x / 1000).toFixed(2).replace('.', ',') + ' km'
}

export function displayAsMeters(distance: string): number {
    const inputString = distance.replace(',', '.').slice(0, -3)
    return parseFloat(inputString) * 1000
}