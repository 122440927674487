import {Column, CreateDateColumn, Entity, ManyToOne, OneToMany, PrimaryGeneratedColumn} from "typeorm";
import {BaseUser} from "../../users/entities";
import {File} from "../../files/entities/File.entity";
import {Conversation} from "./Conversation.entity";
import {ApiProperty} from "@nestjs/swagger";
import {UserMessageInteraction} from "./UserMessageInteraction.entity";

@Entity()
export class Message {
    @PrimaryGeneratedColumn()
    @ApiProperty({ type: Number })
    //
    id: number

    @Column({ type: 'text', nullable: false })
    @ApiProperty({ type: String })
    content: string

    @CreateDateColumn({ type: 'datetime', nullable: false})
    @ApiProperty({ type: Date})
    sentAt: Date

    @ManyToOne(type => BaseUser, user => user.messages)
    @ApiProperty({ type: () => BaseUser})
    sentBy: BaseUser

    @Column({type: 'boolean', default: false, nullable: false})
    @ApiProperty({ type: Boolean})
    draft: boolean

    @ManyToOne(type => Conversation, conversation => conversation.messages)
    @ApiProperty({ type: () => Conversation })
    conversation?: Conversation

    @OneToMany(type => File, file => file.message, { nullable: true })
    @ApiProperty({ type: () => File})
    attachments: File

    @OneToMany(type => UserMessageInteraction, interaction => interaction.message)
    userInteractions: UserMessageInteraction[]

    constructor(init: Partial<Message>) {
        Object.assign(this, init)
    }
}