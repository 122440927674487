export enum State {
  BADEN_WUERTTEMBERG = 'BW',
  BAVARIA = 'BY',
  BERLIN = 'BE',
  BRANDENBURG = 'BB',
  BREMEN = 'HB',
  HAMBURG = 'HH',
  HESSE = 'HE',
  LOWER_SAXONY = 'NI',
  MECKLENBURG_WESTERN_POMERANIA = 'MV',
  NATIONAL = 'NATIONAL',
  NORTHRINE_WESTPHALIA = 'NW',
  RHINELAND_PALATINATE = 'RP',
  SAARLAND = 'SL',
  SAXONY = 'SN',
  SAXONY_ANHALT = 'ST',
  SCHLESWIG_HOLSTEIN = 'SH',
  THURINGIA = 'TH'
}