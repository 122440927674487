import {  Column, Entity, JoinColumn, ManyToOne, OneToOne, PrimaryGeneratedColumn } from 'typeorm'
import { Shipment } from '.'
import { TripRequirement } from '../../routing'
import { MoveType } from '../types/moveType'
import { Student } from '../../students/entities'

@Entity('touren_fahrten_schueler')
export class WaypointStudent {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @ManyToOne(type => Shipment, waypoint => waypoint.students, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'wegpunktid' })
  waypoint: Shipment

  @ManyToOne(type => TripRequirement, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'schülerFahrtid' })
  requirement: TripRequirement

  // @Column({ type: 'enum', nullable: false, name: 'ein_ausstieg', enum: MoveType, default: MoveType.ENTRY })
  @Column({ name: 'ein_ausstieg', type: 'tinyint', width: 1, nullable: false, default: MoveType.ENTRY })
  type: MoveType

  @Column({ type: 'boolean', name: 'needs_wheelchair', nullable: false, default: false, width: 1 })
  needsWheelchair: boolean

  @ManyToOne(type => Student, student => student.waypoints, {
    nullable: false,
    onUpdate: 'CASCADE',
    onDelete: 'CASCADE'
  })
  @JoinColumn({ name: 'schuelerid' })
  student: Student

  constructor (init?: Partial<WaypointStudent>) {
    Object.assign(this, init)
  }
}
