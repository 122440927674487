import {Column, Entity, ManyToOne} from "typeorm";
import {BaseUser} from "../../users/entities";
import {File} from "../../files/entities";

@Entity()
export class UserFileInteraction {
    @ManyToOne(
        type => BaseUser,
        user => user.fileInteractions,
        {nullable: false, primary: true}
    )
    user: BaseUser

    @ManyToOne(
        type => File,
        file => file.userInteractions,
        {nullable: false, primary: true}
    )
    file: File

    @Column({ type: 'timestamp', nullable: true })
    firstSeen?: Date | null

    @Column({ type: 'timestamp', nullable: true })
    firstOpened?: Date | null

    @Column({ type: 'timestamp', nullable: true })
    lastSeen?: Date | null

    @Column({ type: 'timestamp', nullable: true })
    lastOpened?: Date | null
}