import { Column, Entity, JoinColumn, ManyToOne, OneToMany, OneToOne, PrimaryGeneratedColumn } from 'typeorm'
import { Shipment, Tour, TourFinances, TourTripsEndTracking } from '.'
import { literalFloatTransformer, ValidTimespanGerman, VariableFields } from '../../utils'
import { TransportCompanyMember, CompanionAddressType, TransportCompanyVehicle } from '../../companies'
import type { Direction, Description } from '../types'

/**
 * Actual 'physical' trips
 *
 * German: Umlauf
 */
@Entity('touren_fahrten')
export class TourTrips {
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @ManyToOne(type => Tour, {
        nullable: false,
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE'
    })
    @JoinColumn({ name: 'tourid' })
    tour: Tour

    // @Column({ name: 'richtung', type: 'enum', enum: Direction })
    @Column({ name: 'richtung', type: 'tinyint', width: 1, nullable: false })
    direction: Direction

    @Column('text', { name: 'bemerkungen', nullable: true })
    notes: string | null

    @Column(type => ValidTimespanGerman)
    valid: ValidTimespanGerman

    @Column({ name: 'mo', type: 'boolean', width: 1, nullable: true })
    mo: boolean | null

    @Column({ name: 'di', type: 'boolean', width: 1, nullable: true })
    di: boolean | null

    @Column({ name: 'mi', type: 'boolean', width: 1, nullable: true })
    mi: boolean | null

    @Column({ name: 'do', type: 'boolean', width: 1, nullable: true })
    do: boolean | null

    @Column({ name: 'fr', type: 'boolean', width: 1, nullable: true })
    fr: boolean | null

    @Column({ name: 'sa', type: 'boolean', width: 1, nullable: true })
    sa: boolean | null

    @Column({ name: 'so', type: 'boolean', width: 1, nullable: true })
    so: boolean | null

    @Column('time', { name: 'fix_zeit', nullable: false })
    fixZeit: string

    @Column({ name: 'start_zeit', type: 'time', nullable: false, default: '00:00:00' })
    startTime: string

    @Column({ name: 'ziel_zeit', type: 'time', nullable: false, default: '00:00:00' })
    endTime: string

    @Column('int', {
        name: 'fix_art',
        comment: '0 = fixe endzeit (hin 1 = fixe startzeit (rück)',
        nullable: false
    })
    fixArt: number

    @Column('text', {
        name: 'lat_start',
        comment: 'Geopunkt, wo Routing starten sol',
        nullable: false,
        transformer: literalFloatTransformer
    })
    latStart: number

    @Column('text', {
        name: 'lng_start',
        comment: 'Geopunkt, wo Routing starten soll',
        nullable: false,
        transformer: literalFloatTransformer
    })
    lngStart: number

    @Column('text', {
        name: 'art_start',
        comment: '0= Unternehmen, 1= Schule 2= Adresse',
        nullable: false
    })
    artStart: string

    @Column('text', { name: 'vehicle', nullable: false })
    vehicle: string

    @Column('tinyint', { name: 'optimieren', width: 1, nullable: false })
    optimise: boolean

    // this is the description of the start
    @Column('text', { name: 'desc_start', nullable: false })
    descStart: Description

    @Column('int', { name: 'route_color', nullable: false })
    routeColor: number

    @Column('int', { name: 'kapacity', default: () => "'0'" })
    capacity: number

    @Column('int', {
        name: 'fahrplan_mode',
        nullable: true,
        comment: '0=schüler manuell 1=scüler automatisch 2=fahrplan manuell'
    })
    fahrplanMode: number | null

    @Column('int', {
        name: 'strecken_mode',
        nullable: true,
        comment: '0=Last und Leer km 1=nur Last km'
    })
    streckenMode: number | null

    @Column('double', { name: 'km_leer', nullable: false })
    kmLeer: number

    @Column('double', { name: 'km_last', nullable: false })
    kmLast: number

    @Column('double', { name: 'km_ges', nullable: false })
    totalDistance: number

    @Column({ type: 'double', name: 'km_ziel_direkt', nullable: true })
    kmZielDirekt: number

    @Column('text', { name: 'fahrtname', nullable: false })
    name: string

    @Column('tinyint', { name: 'use_routing', width: 1, nullable: false })
    useRouting: boolean

    @Column('text', { name: 'desc_ende', nullable: false })
    descEnde: Description

    @Column('text', { name: 'lat_ende', nullable: false, transformer: literalFloatTransformer })
    latEnde: number

    @Column('text', { name: 'lng_ende', nullable: false, transformer: literalFloatTransformer })
    lngEnde: number

    @Column('text', { name: 'art_ende', nullable: false })
    artEnde: string

    @Column('tinyint', { name: 'opt_switch_return_hst', nullable: false })
    optSwitchReturnHst: number

    @OneToMany(type => Shipment, waypoint => waypoint.tourTrip)
    waypoints: Shipment[]

    @OneToMany(type => TourFinances, finances => finances.trip)
    finances: TourFinances[]

    @ManyToOne(type => TransportCompanyMember, { nullable: true })
    @JoinColumn({ name: 'bp_id' })
    companion: TransportCompanyMember | null

    @Column({ type: 'boolean', name: 'has_bp', nullable: false })
    hasCompanion: boolean

    @Column({ type: 'varchar', name: 'bp_addres', length: 255, nullable: false })
    companionAddress: string

    @Column({ name: 'bp_art', type: 'int', width: 11, nullable: false })
    companionAddressType: CompanionAddressType

    @Column({ name: 'bp_lat', type: 'varchar', length: 255, nullable: false, transformer: literalFloatTransformer })
    companionLatitude: number

    @Column({ name: 'bp_lng', type: 'varchar', length: 255, nullable: false, transformer: literalFloatTransformer })
    companionLongitude: number

    @Column({ name: 'bp_lat_exit', type: 'varchar', length: 255, nullable: false, transformer: literalFloatTransformer })
    companionLatitudeExit: number

    @Column({ name: 'bp_lng_exit', type: 'varchar', length: 255, nullable: false, transformer: literalFloatTransformer })
    companionLongitudeExit: number

    @Column({ type: 'varchar', name: 'bp_addres_exit', length: 255, nullable: false })
    companionAddressExit: string

    @Column({ name: 'bp_art_exit', type: 'int', width: 11, nullable: false })
    companionAddressTypeExit: CompanionAddressType

    @Column(type => VariableFields)
    variableFields: VariableFields

    @Column({ type: 'boolean', name: 'has_fahrer', nullable: false })
    hasDriver: boolean

    @ManyToOne(type => TransportCompanyMember, { nullable: true })
    @JoinColumn({ name: 'fahrer_id' })
    driver: TransportCompanyMember | null

    @Column({ type: 'boolean', name: 'has_kfz', nullable: false })
    hasVehicle: boolean

    @OneToOne(() => TransportCompanyVehicle, vehicle => vehicle.tourTrip, {
        nullable: true
    })
    @JoinColumn({ name: 'kfz_id' })
    usedVehicle: TransportCompanyVehicle

    @Column({ name: 'speed_factor', type: 'double', nullable: true, default: 1 })
    speedFactor: number

    @Column({ type: 'boolean', name: 'need_recalculation', nullable: false, default: false })
    needRecalculation: boolean

    @ManyToOne(type => TourTrips, {
        nullable: true,
        onUpdate: 'CASCADE',
        onDelete: 'RESTRICT'
    })
    @JoinColumn({ name: 'parentFahrtId' })
    parentTourTrip: TourTrips

    @OneToMany(type => TourTripsEndTracking, endTracking => endTracking.tourTrip)
    endTrackings: TourTripsEndTracking[]

    constructor(init?: Partial<TourTrips>) {
        Object.assign(this, init)
    }
}
