import { FormClass, FormField, InputType } from '@stadtlandnetz/forms'
import { IsBoolean, IsNotEmpty, IsNumber } from 'class-validator'

@FormClass({ defaultGroupName: 'Order Date', interfereLabels: true })
export class OrderDateDto {
    @FormField({ placeholder: '01.01.2020', type: InputType.DATE })
    @IsNotEmpty()
    orderDate?: Date | null

    @FormField({ type: InputType.SELECT, title: 'selectedDate' })
    @IsNotEmpty()
    selectedDate?: Date | null

    constructor(init: OrderDateDto) {
        Object.assign(this, init)
    }
}

export class OrderDateOptions {
    @IsBoolean()
    onlyFirstDaysOfMonth: boolean

    @IsNumber()
    daysToWait: number

    @IsNumber()
    monthsToEnd: number
}
