import { IsEnum, IsString } from "class-validator";

export enum VarFieldConditionOperator {
    EQUAL = 0, // =
    DISTINCT = 1, // ≠
    INCLUDES = 2, // String operator
    LT = 3, // <
    GT = 4 // >  
}

export enum VarFieldVisibility {
    FALSE = 0,
    TRUE = 1
}

export class VariableFieldConditions {
    @IsString()
    fieldname: string // The field name that we will compare its value with

    @IsEnum(VarFieldConditionOperator)
    operator: VarFieldConditionOperator

    @IsString()
    value: string // The value to compare with over operator

    @IsEnum(VarFieldVisibility)
    visibility: VarFieldVisibility
}