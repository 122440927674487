export * from './calendar'
export * from './companies'
export * from './documents'
export * from './evaluation-schema'
export * from './files'
export * from './messages'
export * from './print-server'
export * from './public-transport'
export * from './reports'
export * from './routing'
export * from './schools'
export * from './students'
export * from './system'
export * from './tenders'
export * from './tours'
export * from './transport'
export * from './transport-request'
export * from './users'
export * from './utils'
export * from './via-endpoints'
export * from './variable-fields'
export * from './news'

import * as CalendarEntities from './calendar/entities'
import * as CompanyEntities from './companies/entities'
import * as DocumentEntities from './documents/entities'
import * as EvaluationSchemaEntities from './evaluation-schema/entities'
import * as FileEntities from './files/entities'
import * as MessageEntities from './messages/entities'
import * as PublicTransportEntities from './public-transport/entities'
import * as ReportEntities from './reports/entities'
import * as RoutingEntities from './routing/entities'
import * as SchoolEntities from './schools/entities'
import * as StudentEntities from './students/entities'
import * as SystemEntities from './system/entities'
import * as TenderEntities from './tenders/entities'
import * as TourEntities from './tours/entities'
import * as TransportEntities from './transport/entities'
import * as TransportRequestEntities from './transport-request/entities'
import * as UserEntities from './users/entities'
import * as NewsEntities from './news/entities'

export const ALL_ENTITIES = [
    ...Object.values(CalendarEntities),
    ...Object.values(CompanyEntities),
    ...Object.values(DocumentEntities),
    ...Object.values(EvaluationSchemaEntities),
    ...Object.values(FileEntities),
    ...Object.values(MessageEntities),
    ...Object.values(PublicTransportEntities),
    ...Object.values(ReportEntities),
    ...Object.values(RoutingEntities),
    ...Object.values(SchoolEntities),
    ...Object.values(StudentEntities),
    ...Object.values(SystemEntities),
    ...Object.values(TenderEntities),
    ...Object.values(TourEntities),
    ...Object.values(TransportEntities),
    ...Object.values(TransportRequestEntities),
    ...Object.values(UserEntities),
    ...Object.values(NewsEntities)
]
