import {
    AddressByLetterVerification,
    BankAccount,
    EmailVerification,
    Guardian,
    LoginResponse,
    Student,
    TransportRequestStatus,
    VerificationState
} from '@stadtlandnetz/core'
import { plainToClass } from 'class-transformer'
import { Writable, writable } from 'svelte/store'
import { parseJwt } from '../helpers'
import { api } from '../services/api.service'
import {
    additionalAttributes,
    addressStatuses,
    appDataReady,
    bankAccount,
    baseUserId,
    bundID,
    conversations,
    guardian,
    guardians,
    isSelfApplicant,
    jwt,
    mailStatus,
    schoolTypes,
    schoolYears,
    sentTransportRequests,
    students,
    transportRequestStateMapping,
    verificationComplete
} from './data.stores'

export const apiBaseUrl: URL = new URL('/api/', window.location.origin)
export const token: Writable<string> = writable(null)
export const token_exp = writable(null)
export const loading: Writable<'blocking' | 'background' | undefined> = writable(undefined)
export const readyToCheck: Writable<boolean> = writable(false)
export const checking: Writable<boolean> = writable(false)
export const oneTimeToken = writable(null)
export const activeFooterLinkContent = writable(null)
export const footerLinkBreadcrumb = writable(null)

let tokenVar: string | undefined = undefined
let interval

token.subscribe((value) => (tokenVar = value))

token_exp.subscribe((time: string) => {
    if (time === null) {
        interval = undefined
        return
    }
    if (interval) clearInterval(interval)
    interval = setInterval(async () => {
        try {
            const data = await api.auth.refresh()
            setJwt(data)
        } catch (e) {
            console.error('An error occurred while fetching the jwt token')
        }
    }, toMilliseconds(time))
})

function toMilliseconds(time: string): number {
    let mills: number
    if (time) {
        const number = parseInt(time.substring(0, time.length - 1))
        if (time.endsWith('h')) {
            mills = number * 60 * 60 * 1000
        } else if (time.endsWith('m')) {
            mills = number * 60 * 1000
        } else if (time.endsWith('s')) {
            mills = number * 1000
        }
    }
    return mills
}

export function getHttpOptions(method: string): RequestInit {
    return {
        method,
        headers: {
            'Content-Type': 'application/json',
            ...(tokenVar ? { Authorization: `Bearer ${tokenVar}` } : {})
        },
        credentials: 'include'
    }
}

export function setJwt(data: LoginResponse): void {
    if (data) {
        if (data.access_token) {
            token.set(data.access_token)
            jwt.set(parseJwt(data.access_token))
        }
        data.expiration && token_exp.set(data.expiration)
        data.baseUserId && baseUserId.set(data.baseUserId)
        data.guardian && guardian.set(data.guardian)
        data.isSelfApplicant && isSelfApplicant.set(data.isSelfApplicant)
        data.bankAccount && bankAccount.set(data.bankAccount)
        data.bundID && bundID.set(data.bundID)
    }
}

export function clearData(): void {
    token.set(null)
    jwt.set(null)
    token_exp.set(null)
    baseUserId.set(null)
    guardian.set(null)
    isSelfApplicant.set(null)
    conversations.set(null)
    schoolTypes.set(null)
    verificationComplete.set(null)
    mailStatus.set(null)
    addressStatuses.set(null)
    bankAccount.set(new BankAccount({}))
    bundID.set(null)
}

async function loadSchoolTypes(): Promise<void> {
    const response = await api.schoolTypes.getMany(undefined, [['filter', ['type||$eq||Schulart']]])
    const types = []
    const checked = []
    if (response) {
        response.forEach((type) => {
            if (!checked.includes(type.system.id)) {
                types.push({ title: type.system.value, value: type.system.id })
            }
            checked.push(type.system.id)
        })
    }
    schoolTypes.set(types)
}

async function loadSchoolYears(): Promise<void> {
    const years = await api.accordingEntities.getFittingSchoolYears()
    schoolYears.set(years)
}

async function loadRequestStateMapping(): Promise<void> {
    const response = await api.transportRequestStateMapping.getMany()
    if (!response) return
    const groupedMappings: Record<string, TransportRequestStatus[]> = response.reduce(
        (obj, mapping) => ((obj[mapping.status] = [...(obj[mapping.status] || []), mapping]), obj),
        {}
    )
    transportRequestStateMapping.set(groupedMappings)
}

async function loadConversations(): Promise<void> {
    const response = await api.conversations.getConversationForUser()
    conversations.set(response)
}

async function loadAdditionalAttributes(): Promise<void> {
    const response = await api.transportRequests.getAdditionalAttributes()
    additionalAttributes.set(response)
}

export async function loadVerificationStatus(): Promise<void> {
    const [emailVerifications, addressVerifications] = await Promise.all([
        api.verifyMail.status(),
        api.verifyAddress.status()
    ])

    if (!emailVerifications || emailVerifications.length == 0)
        throw new Error('No email verification found for current user')
    if (!addressVerifications || addressVerifications.length == 0)
        throw new Error('No address verification found for current user')
    mailStatus.set(emailVerifications[0])
    addressStatuses.set(addressVerifications)

    // The user needs at least one verified email and one verified address be verified
    const isVerified = (verification: EmailVerification | AddressByLetterVerification) =>
        verification.state == VerificationState.VERIFIED
    verificationComplete.set(emailVerifications.some(isVerified) && addressVerifications.some(isVerified))
}

export async function loadStudents(): Promise<void> {
    const response: Student[] = await api.users.getStudents()
    students.set(response)
}

export async function loadGuardians(): Promise<void> {
    const response: Guardian[] = await api.users.getGuardians()
    guardians.set(plainToClass(Guardian, response))
}

export async function loadSubmittedRequests(): Promise<void> {
    sentTransportRequests.set(await api.transportRequests.getOwn())
}

export async function getAppData(): Promise<void> {
    loading.set('blocking')
    if (tokenVar) {
        try {
            await Promise.all([
                loadSchoolTypes(),
                // loadConversations(),
                loadVerificationStatus(),
                loadAdditionalAttributes(),
                loadStudents(),
                loadGuardians(),
                loadSubmittedRequests()
            ])
        } catch (e) {
            console.warn('Error while loading initial data: ', e)
        }
    }
    try {
        await Promise.all([loadRequestStateMapping(), loadSchoolYears()])
    } catch (e) {
        console.warn('Error while loading initial data: ', e)
    }
    appDataReady.set(true)
    loading.set(undefined)
}
