import {  Column, Entity, PrimaryGeneratedColumn } from 'typeorm'

export type SystemID = number

// Improve: Deprecate and remove. Split up into multiple specific tables
@Entity('system')
export class System {
  // Improve: Use composite key instead
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' , unsigned: true})
  id: number

  // TODO: Replace by an enum
  @Column('varchar', { name: 'schluessel', nullable: false, length: 255 })
  key: string

  @Column('mediumtext', { name: 'wert', nullable: true })
  value: string | null

  @Column('longtext', { name: 'wert2', nullable: true })
  value2: string | null

  @Column('json', { name: 'wertJson', nullable: true })
  value3: object | null

  constructor (init?: Partial<System>) {
    Object.assign(this, init)
  }
}
