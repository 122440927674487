import { Column, Entity, Index, OneToMany, OneToOne, PrimaryGeneratedColumn } from 'typeorm'
import {
  Complain,
  Invoice,
  TransportCompanyDepot,
  TransportCompanyLine,
  TransportCompanyLogin,
  TransportCompanyMember,
  TransportCompanyVehicle,
} from '.'
import { Address, CoordinatesShort, VariableFields } from '../../utils'
import { PublicTransportRelation } from '../../public-transport'
import { TenderBid } from '../../tenders'
import { TransportCompanyAvailableType } from '../types'
import { Tour } from '../../tours'
import { NewsRights } from '../../news'


@Entity('unternehmen')
export class TransportCompany {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
  id: number

  @Index({ unique: true })
  @Column('varchar', { name: 'kurzform', nullable: false, length: 100 })
  short: string | null

  @Column('varchar', { name: 'langname', nullable: false, length: 255 })
  name: string | null

  @Column(type => Address)
  address: Address

  @Column(type => CoordinatesShort)
  coordinates: CoordinatesShort

  // Improve: Add validation
  @Column('varchar', { name: 'telefon1', nullable: true, length: 255 })
  phone1: string | null
  @Column('varchar', { name: 'telefon2', nullable: true, length: 255 })
  phone2: string | null
  @Column('varchar', { name: 'fax', nullable: true, length: 255 })
  fax: string | null
  @Column('varchar', { name: 'email', nullable: true, length: 255 })
  email: string | null
  @Column('varchar', { name: 'webseite', nullable: true, length: 255 })
  website: string | null

  // Improve: Implement as enumeration
  // note: this is at the moment a simple textfield in via 
  @Column('text', { name: 'rechtsform', nullable: true })
  legalForm: string | null

  @Column('text', { name: 'geschäftsführer', nullable: true })
  managingDirector: string | null

  @Column('varchar', { name: 'ansprechpartner', nullable: true, length: 255 })
  contact: string | null

  @Column('text', { name: 'bemerkungen', nullable: true })
  notes: string | null

  // tags at transport companies are deprecated because of variable fields
  /*
  @Column('text', { name: 'tags', nullable: true })
  tags: string | null
  */

  @Column(type => VariableFields)
  variableFields: VariableFields

  @Column('text', { name: 'id_extern' })
  externalId: string

  // this is a dirty corner-case for one customer
  // 0 = everywhere, 1 = only accounting
  // @Column({type: 'enum', enum: TransportCompanyAvailableType, name: 'available_at', nullable: false, default: TransportCompanyAvailableType.EVERYWHERE })
  @Column({ name: 'available_at', type: 'tinyint', width: 1, nullable: false, default: TransportCompanyAvailableType.EVERYWHERE })
  availableAt: TransportCompanyAvailableType

  @Column('tinyint', { name: 'fsv', width: 1 })
  individualTransport: boolean

  // @OneToOne(type => BaseUser, user => user.transportCompany)
  // user: BaseUser

  @Column('tinyint', { name: 'oepnv', width: 1 })
  publicTransport: boolean

  @OneToOne(type => TransportCompanyLogin, login => login.company)
  login: TransportCompanyLogin

  @OneToMany(type => Complain, complain => complain.company)
  complains: Complain[]

  @OneToMany(type => PublicTransportRelation, relation => relation.transportCompany)
  relations: PublicTransportRelation[]

  @OneToMany(type => TenderBid, bid => bid.company)
  bids: TenderBid[]

  @OneToMany(type => Invoice, invoice => invoice.company)
  invoices: Invoice[]

  @OneToMany(type => TransportCompanyLine, line => line.company)
  lines: TransportCompanyLine[]

  @OneToMany(type => TransportCompanyMember, member => member.company)
  members: TransportCompanyMember[]

  @OneToMany(type => Tour, tour => tour.company)
  tours: Tour[]

  @OneToMany(type => Tour, tour => tour.subCompany)
  toursAsSub: Tour[]

  @OneToMany(type => TransportCompanyDepot, depot => depot.company)
  depots: TransportCompanyDepot[]

  @OneToMany(type => TransportCompanyVehicle, vehicle => vehicle.company)
  vehicles: TransportCompanyVehicle[]

  @OneToMany(
    type => NewsRights,
    newsRights => newsRights.company)
  newsRights: NewsRights[]

  constructor(init?: Partial<TransportCompany>) {
    Object.assign(this, init)
  }
}
