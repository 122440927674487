import { Column } from 'typeorm'
import { dateTransformer } from "./transformers"
import { Type } from 'class-transformer'

export class ValidTimespan {
    @Column({ type: 'date', name: 'gueltig_ab', nullable: true, transformer: dateTransformer })
    @Type(() => Date)
    since: Date | null

    @Column({ type: 'date', name: 'gueltig_bis', nullable: true, transformer: dateTransformer })
    @Type(() => Date)
    until: Date | null

    contains(date: Date): boolean {
        const isAfterStart = this.since ? date.getTime() >= this.since.getTime() : true
        const isBeforeEnd = this.until ? date.getTime() <= this.until.getTime() : true
        return isAfterStart && isBeforeEnd
    }

    constructor(init: Partial<ValidTimespan>) { Object.assign(this, init) }
}

export class ValidTimespanRequired {
    @Column({ type: 'date', name: 'gueltig_ab', nullable: false })
    since: Date

    @Column({ type: 'date', name: 'gueltig_bis', nullable: false })
    until: Date

    constructor(init: ValidTimespanRequired) { Object.assign(this, init) }
}

export class ValidTimespanOptional {
  @Column({ type: 'date', name: 'gueltig_ab', nullable: false })
  since: Date

  @Column({ type: 'date', name: 'gueltig_bis', nullable: true })
  until: Date

  constructor(init: Partial<ValidTimespanOptional>) { Object.assign(this, init) }
}

export class ValidTimespanGerman {
    @Column('date',
        { name: 'gültig_ab', nullable: true })
    since: Date | null

    @Column('date',
        { name: 'gültig_bis', nullable: true })
    until: Date | null
}

export class ValidTimespanEnglish {
    @Column({ type: 'date', name: 'valid_from', nullable: false })
    since: Date

    @Column({ type: 'date', name: 'valid_to', nullable: false })
    until: Date

    constructor(init: ValidTimespanEnglish) { Object.assign(this, init) }
}